import React, { useState, useEffect } from "react";
import "./addnew.css";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  addSettingCompany,
  editCompany,
  getAllCountryList,
  getAllStateList,
} from "../../redux/Action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddCompany = ({ onClose, updatedData, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allCountryList, getAllStateData } = useSelector(
    (state) => state.main
  );

  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    dispatch(getAllCountryList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(getAllStateList(selectedCountry));
    }
    if (updatedData?.country_id) {
      dispatch(getAllStateList(updatedData?.country_id));
    }
  }, [selectedCountry, dispatch]);

  const presentcountryoptions = allCountryList?.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  const presentstateoptions = getAllStateData?.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  const fields = [
    {
      label: "Company Name",
      type: "text",
      name: "company_name",
      required: true,
    },
    { label: "Company Address", type: "text", name: "address", required: true },
    { label: "PF No.", type: "text", name: "pf_no", required: false },
    { label: "PAN No.", type: "text", name: "pan_no", required: false },
    { label: "TAN No.", type: "text", name: "tan_no", required: false },
    { label: "ESI No.", type: "text", name: "esi_no", required: false },
    { label: "LIN No.", type: "text", name: "lin_no", required: false },
    { label: "GST No.", type: "text", name: "gst_no", required: false },
    {
      label: "Registration Certification No.",
      type: "text",
      name: "registration_certification_no",
      required: false,
    },
    {
      label: "Company Logo",
      type: "file",
      name: "company_logo",
      required: false,
    },
    {
      label: "Status",
      type: "select",
      name: "status",
      required: true,
      options: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
    },
  ];

  const [formData, setFormData] = useState({
    company_name: updatedData?.company_name || "",
    address: updatedData?.address || "",
    pf_no: updatedData?.pf_no || "",
    pan_no: updatedData?.pan_no || "",
    tan_no: updatedData?.tan_no || "",
    esi_no: updatedData?.esi_no || "",
    lin_no: updatedData?.lin_no || "",
    gst_no: updatedData?.gst_no || "",
    registration_certification_no:
      updatedData?.registration_certification_no || "",
    company_logo: updatedData?.company_logo || "",
    status: updatedData?.status ?? null,
  });

  const [errors, setErrors] = useState({});

  const lengthRules = {
    pf_no: 26,
    pan_no: 10,
    tan_no: 10,
    esi_no: 17,
    gst_no: 15,
  };

  const validateField = (name, value) => {
    const newErrors = {};

    const pfRegex = /^[A-Z]{2}\/[A-Z]{3}\/\d{7}\/\d{3}\/\d{7}$/;
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
    const tanRegex = /^[A-Z]{4}\d{5}[A-Z]$/;
    const esiRegex = /^\d{17}$/;
    const gstRegex = /^[0-9]{2}[A-Z]{5}\d{4}[A-Z][A-Z0-9]{1}[Z][0-9A-Z]{1}$/;

    const requiredOnlyFields = ["company_name", "address"];

    if (!value) {
      if (requiredOnlyFields.includes(name)) {
        newErrors[name] = `${name.replace(/_/g, " ")} is required.`;
      }
    } else {
      switch (name) {
        case "pf_no":
          if (value.length !== lengthRules.pf_no) {
            newErrors[
              name
            ] = `PF No. must be exactly 22 characters long, including slashes.`;
          } else if (!pfRegex.test(value)) {
            newErrors[name] =
              "PF No. format should be (MH/BAN/1234567/000/1234235).";
          }
          break;

        case "pan_no":
          if (value.length !== lengthRules.pan_no) {
            newErrors[
              name
            ] = `PAN No. must be ${lengthRules.pan_no} characters long.`;
          } else if (!panRegex.test(value)) {
            newErrors[name] = "PAN No. format should be (ABCTY1234D).";
          }
          break;

        case "tan_no":
          if (value.length !== lengthRules.tan_no) {
            newErrors[
              name
            ] = `TAN No. must be ${lengthRules.tan_no} characters long.`;
          } else if (!tanRegex.test(value)) {
            newErrors[name] = "TAN No. format should be (RAJA99999B).";
          }
          break;

        case "esi_no":
          if (value.length !== lengthRules.esi_no) {
            newErrors[
              name
            ] = `ESI No. must be ${lengthRules.esi_no} digits long.`;
          } else if (!esiRegex.test(value)) {
            newErrors[name] = "ESI No. must be a 17-digit number.";
          }
          break;

        case "gst_no":
          if (value.length !== lengthRules.gst_no) {
            newErrors[
              name
            ] = `GST No. must be ${lengthRules.gst_no} characters long.`;
          } else if (!gstRegex.test(value)) {
            newErrors[name] = "GST No. format should be (22AAAAA0000A1Z5).";
          }
          break;

        default:
          break;
      }
    }

    return newErrors;
  };

  // const validateField = (name, value) => {
  //   const newErrors = {};
  //   const alphanumericRegex = /^[a-zA-Z0-9]*$/;

  //   if (!value) {
  //     newErrors[name] = `${name.replace(/_/g, " ")} is required.`;
  //   } else if (!alphanumericRegex.test(value)) {
  //     newErrors[name] = `${name.replace(
  //       /_/g,
  //       " "
  //     )} must contain only letters and numbers.`;
  //   }

  //   return newErrors;
  // };
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"];

      if (name === "company_logo" && !allowedFileTypes.includes(file.type)) {
        toast.error("File type must be .png, .jpg, or .jpeg.");
        e.target.value = "";
        return;
      }

      setFormData((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else {
      let updatedValue = value;

      if (name === "status") {
        updatedValue = value === "true";
      }

      setFormData((prev) => ({
        ...prev,
        [name]: updatedValue,
      }));

      const fieldErrors = validateField(name, updatedValue);
      setErrors((prev) => ({
        ...prev,
        [name]: fieldErrors[name] || null,
      }));
    }

    if (name === "country_id") {
      setSelectedCountry(value);
    }
  };

  console.log({ formData });

  const clickSubmitHandler = () => {
    const newErrors = {};

    // Validate fields and collect errors
    Object.keys(formData).forEach((fieldName) => {
      const fieldErrors = validateField(fieldName, formData[fieldName]);
      if (fieldErrors[fieldName]) {
        newErrors[fieldName] = fieldErrors[fieldName];
      }
    });

    setErrors(newErrors);

    // Return early if there are validation errors
    if (Object.keys(newErrors).length > 0) {
      return false;
    }

    // Convert formData to FormData object for sending files
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      // Check if the value is a file or a regular field
      if (formData[key] instanceof File || formData[key] instanceof Blob) {
        formDataToSend.append(key, formData[key]);
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    // Dispatch the appropriate action based on status
    if (status === "add") {
      dispatch(addSettingCompany(formDataToSend, navigate, onClose));
    } else if (status === "edit") {
      dispatch(editCompany(updatedData?.id, formDataToSend, onClose));
    }

    return true; // Return true to indicate submission was successful
  };

  return (
    <>
      <div className="addcompany">
        <div className="d-flex justify-content-between">
          <h2>{status === "add" ? "Add Company" : "Edit Company"}</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.name} htmlFor={field.name}>
              {field.label}
              {field.required && <span style={{ color: "red" }}>*</span>}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.name}>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className={`form-group ${
                    errors[field.name] ? "error-field" : ""
                  }`}
                  style={{
                    borderColor: errors[field.name] ? "red" : "",
                    marginBottom: errors[field.name] ? "0" : "16px", // Example: 16px if no error
                  }}
                >
                  <option value="">Select...</option>
                  {field.options?.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <input
                  className={`form-group ${
                    errors[field.name] ? "error-field" : ""
                  }`}
                  type="file"
                  name={field.name}
                  onChange={handleChange}
                  accept=".png, .jpg, .jpeg"
                  style={{
                    borderColor: errors[field.name] ? "red" : "",
                    marginBottom: errors[field.name] ? "0" : "0", // Example: 16px if no error
                  }}
                />
              ) : (
                <>
                  <input
                    className={`form-group ${
                      errors[field.name] ? "error-field" : ""
                    }`}
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    placeholder={
                      errors[field.name]
                        ? errors[field.name]
                        : `Enter ${field.label}`
                    }
                    style={{
                      borderColor: errors[field.name] ? "red" : "",
                      marginBottom: errors[field.name] ? "0" : "0", // Example: 16px if no error
                    }}
                    maxLength={lengthRules[field.name] || undefined}
                  />
                </>
              )}
              {errors[field.name] && (
                <span style={{ color: "red" }}>{errors[field.name]}</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddCompany;
