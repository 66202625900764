import React, { useState, useEffect } from "react";
import req_user from "../../../assets/img/req_user.svg";
import cross from "../../../assets/img/cross.png";
import watch_user from "../../../assets/img/watch_user.svg";
import right_mark from "../../../assets/img/right_mark.png";
import upper_mark from "../../../assets/img/upper_mark.png";
import "./request.css";
import ViewRequest from "./ViewRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLeavedata, putRejacedApprovedata } from "../../../redux/Action";
import moment from "moment";
import Pagination from "../../../Common/Pagination/Pagination";
import SpinnerLoader from "../../../components/common/spinnerloader/SpinnerLoader";
import greydrop from "../../../assets/img/greydrop.png";
import DocumentSvg from "../../../assets/DocumentSvg";

const Requests = () => {
  const userRole = localStorage.getItem("role");
  const pageLoading = useSelector((state) => state.main.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { getLeaveList, allAppRejLeaveList } = useSelector(
    (state) => state?.main
  );
  useEffect(() => {
    // Destructure counts from getLeaveList to use directly
    const totalLeaves = getLeaveList?.data?.totalLeaves || 0;
    const pendingLeaves = getLeaveList?.data?.pendingLeaves || 0;
    const overdueLeaves = getLeaveList?.data?.overdueLeaves || 0;
    const resolvedLeaves = getLeaveList?.data?.resolvedLeaves || 0;
    const escalatedLeaves = getLeaveList?.data?.escalatedLeaves || 0;
    const declinedLeaves = getLeaveList?.data?.declinedLeaves || 0;

    // Construct formatted data with these values
    const formattedAttendanceData = [
      {
        status: "Total Request",
        count: totalLeaves,
        imgSrc: <img src={req_user} alt="req_user" />,
        filter: false,
      },
      {
        status: "Pending",
        count: pendingLeaves,
        imgSrc: <img src={cross} alt="cross" />,
        filter: "pending",
      },
      {
        status: "Overdue",
        count: overdueLeaves,
        imgSrc: <img src={watch_user} alt="watch_user" />,
        filter: "overdue",
      },
      {
        status: "Total Declined",
        count: declinedLeaves,

        imgSrc: <img src={cross} alt="cross" />,
        filter: "rejected",
      },
      {
        status: "Resolved",
        count: resolvedLeaves,
        imgSrc: <img src={right_mark} alt="right_mark" />,
        filter: "approved",
      },
      {
        status: "Escalated",
        count: escalatedLeaves,
        imgSrc: <img src={upper_mark} alt="upper_mark" />,
        filter: "escalated",
        type: "admin",
      },
    ];

    setAttendanceData(formattedAttendanceData);
    setTableData(getLeaveList?.data?.leaves || []);
  }, [getLeaveList]);

  useEffect(() => {
    dispatch(getLeavedata(currentPage, limit));
  }, [dispatch, currentPage, limit, allAppRejLeaveList]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/requests/viewrequest/${data}`);
  };

  const ApproveDirect = (datas) => {
    if (datas?.status !== "Approved") {
      dispatch(putRejacedApprovedata("Approved", datas._id));
    }
  };

  const filterLeaveData = (filter) => {
    dispatch(getLeavedata(currentPage, limit, filter));
  };

  return (
    <div className="content-wrapper">
      {!onRequest && (
        <>
          <div className="flex-grow-1 pt-4 view-request">
            <div className="leave_wrap">
              {attendanceData
                .filter((record) => !record.type || record.type === userRole)
                .map((record, index) => (
                  <div
                    key={index}
                    className="cardmornings"
                    onClick={() => filterLeaveData(record.filter)}
                  >
                    <div className="totalnumber_card">
                      <div className="left_conetent">
                        <div className="view-iocn">{record.imgSrc}</div>
                      </div>
                      <div>
                        <h1>This Month</h1>
                        <div className="right_req">
                          <h4>
                            {record.status} : {record.count}
                          </h4>
                          {/* <div className="min_flex_req">
                          <h1>{record.count}</h1>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="container-fluid view-request">
            {pageLoading ? (
              <SpinnerLoader />
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      {[
                        { name: "Employee Name ", icon: null },
                        { name: "Leave Type", icon: null },
                        { name: "Requested Date", icon: null },
                        { name: "From", icon: null },
                        { name: "To", icon: null },
                        { name: "Duration", icon: null },
                        { name: "Reason", icon: null },
                        { name: "Status", icon: greydrop }, // Add the icon for "Status"
                        { name: "Documents", icon: greydrop }, // Add the icon for "Status"
                        { name: "Action", icon: null },
                      ].map((column, index) => (
                        <th key={index}>
                          {column.name}
                          {column.icon && (
                            <img
                              src={column.icon}
                              alt={`${column.name} dropdown icon`}
                              className="header-dropdown-icon"
                              onClick={() =>
                                console.log(
                                  `Dropdown clicked for ${column.name}`
                                )
                              }
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      tableData.map((items, index) => (
                        <tr key={index}>
                          <td>{items?.user?.name}</td>
                          <td>{items.type}</td>
                          <td>{`${moment(items.createdAt).format(
                            "DD-MM-YYYY"
                          )}`}</td>
                          <td>
                            {items?.startDate
                              ? `${moment(items.startDate).format(
                                  "DD-MM-YYYY"
                                )} ${
                                  items?.fromSession == "First Half"
                                    ? "-FH"
                                    : "-SH"
                                }`
                              : "-"}
                          </td>
                          <td>
                            {items?.endDate
                              ? ` ${moment(items.endDate).format(
                                  "DD-MM-YYYY"
                                )}  ${
                                  items?.toSession == "First Half"
                                    ? "-FH"
                                    : "-SH"
                                }`
                              : "-"}
                          </td>
                          <td>{items.duration}</td>
                          <td>{items.reason}</td>
                          <td>
                            <span
                              className={`badge badge-${items.status} ${
                                items.status ? "view-request" : ""
                              }`}
                            >
                              {items.status}
                            </span>
                          </td>
                          <td>
                            {items.image ? <DocumentSvg items={items} /> : "-"}
                          </td>
                          <td className="forbtn">
                            <button
                              className="btn-view2 badge-approved"
                              onClick={() => ApproveDirect(items)}
                            >
                              Approve
                            </button>
                            <button
                              className="btn-view"
                              onClick={() => OpenRequest(items._id)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Pagination
                  listData={getLeaveList?.meta}
                  setPageData={setCurrentPage}
                  tableData={tableData}
                />
              </div>
            )}
          </div>
        </>
      )}

      {onRequest && <ViewRequest />}
    </div>
  );
};

export default Requests;
