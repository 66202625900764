import React, { useState, useEffect, useRef } from "react";
import "./user.css";
import TableSection from "../../../components/common/customtable/TableSection";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getUsersAll } from "../../../redux/Action";
import white_filter from "../../../assets/img/white_filter.svg";
import downdrop from "../../../assets/img/downdrop.svg";

const User = () => {
  const dispatch = useDispatch();
  const selectRef = useRef(null); // Ref for the select element
  const selectRef2 = useRef(null); // Ref for the select element

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchId, setSearchId] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selected, setSelected] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { getAllUserList, deleteUser, allUserList } = useSelector(
    (state) => state?.main
  );
  console.log(selectedRole);

  useEffect(() => {
    dispatch(getUsers(currentPage, limit));
  }, [dispatch, currentPage, limit, deleteUser]);

  useEffect(() => {
    dispatch(getUsersAll());
  }, []);

  // Handle Find Employee button click
  const handleFindEmployee = () => {
    // const data = getAllUserList?.data || [];
    // const filtered = data.filter((user) => {
    //   console.log(user.designation == selectedRole);

    //   const matchesName =
    //     !searchName ||
    //     user.name?.toLowerCase().includes(searchName.toLowerCase());
    //   const matchesId = !searchId || user.emp_id?.toString().includes(searchId);
    //   const matchesRole =
    //     !selectedRole ||
    //     user.designation?.toLowerCase().toString() ===
    //       selectedRole.toLowerCase().toString();

    //   return matchesName && matchesId && matchesRole;
    // });
    // setFilteredData(filtered);

    dispatch(
      getUsers(currentPage, limit, selectedRole, searchName, searchId, selected)
    );
  };

  const handleReset = () => {
    setSearchName("");
    setSearchId("");
    setSelectedRole("");
    setSelected("");
    dispatch(getUsers(currentPage, limit));
  };

  return (
    <>
      <div className="table_wrapper">
        <div className="top_section">
          <div className="col-lg-12 col-md-12">
            <div className="right-item d-flex justify-content-start gap-3">
              <form className="d-flex classofnew" style={{ width: "100%" }}>
                {/* Employee Name Filter */}
                <div className="outer_border border-right-0 border-left-0">
                  <input
                    type="text"
                    placeholder="Employee Name"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </div>
                {/* Employee ID Filter */}
                <div className="outer_border border-right-0">
                  <input
                    type="text"
                    placeholder="Employee ID"
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                  />
                </div>
                {/* Employee Role Filter */}
                <div className="outer_border border-right-0">
                  <img
                    src={downdrop}
                    alt="Open dropdown"
                    onClick={() => selectRef.current?.click()}
                  />
                  <select
                    ref={selectRef}
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option hidden>Select Designation</option>

                    {allUserList?.data?.roles?.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="outer_border border-right-0">
                  <img
                    src={downdrop}
                    alt="Open dropdown"
                    onClick={() => selectRef2.current?.click()}
                  />
                  <select
                    ref={selectRef2}
                    value={selected}
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    <option hidden>Select Role</option>

                    <option value="user">User</option>
                    <option value="manager">Manager</option>
                    <option value="admin">Admin</option>
                    <option value="management">Management</option>
                  </select>
                </div>
              </form>
              {/* Find Employee Button */}
              <div className="btnfindname">
                <div className="btn_findEmployee">
                  <button
                    type="button"
                    onClick={handleFindEmployee}
                    className="btnsss"
                  >
                    Find Employee
                  </button>
                </div>
                <div className="btn_findEmployee">
                  <button
                    type="button"
                    onClick={handleReset}
                    className="btnsss2"
                  >
                    Reset{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Render Table with Filtered Data */}
      <div className="table_wrapper">
        <div className="table_content">
          <div className="row_wrap row">
            <div className="col-lg-12">
              <div className="card">
                <TableSection
                  tableData={
                    filteredData.length > 0
                      ? filteredData
                      : getAllUserList?.data
                  }
                  columns={[
                    "No.",
                    "Store",
                    "Employee ID",
                    "Employee name ",
                    "Role",
                    "Shift Time",
                    "Slot time",
                    "Status",
                  ]}
                  setCurrentPage={setCurrentPage}
                  getAllUserList={getAllUserList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
