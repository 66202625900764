import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import small_logo from "../../assets/img/small_logo.svg";
import clipboard from "../../assets/img/clipboard.png";
import monitor from "../../assets/img/monitor.png";
import work from "../../assets/img/work.png";
import problem from "../../assets/img/problem.png";
import page_info from "../../assets/img/page_info.svg";
import calendar from "../../assets/img/calendar.png";
import account_circle from "../../assets/img/account_circle.png";
import { AnimatePresence, motion } from "framer-motion";
import "./sidebar.css";
import SidebarMenu from "./SidebarMenu";

const Sidebar = ({ isOpen, setIsMenuOpen, isMobile, funtype }) => {
  const location = useLocation();
  const pathnames = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const btnLoading = useSelector((state) => state.main.btnLoading);
  const refresh_token = localStorage.getItem("refresh_token");
  const userRole = localStorage.getItem("role");

  const routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <img src={monitor} alt={monitor} />,
      type: "common",
    },
    {
      path: "/user",
      name: "Users",
      icon: <img src={account_circle} alt={account_circle} />,
      type: "management",
      subRoutes: [
        {
          pathName: "/user",
          name: "Employee ",
          type: "management",
        },
        {
          pathName: "/addemployee",
          name: "Add Employee",
          type: "management",
        },

        {
          pathName: "/addattendance",
          name: "Employee Attendance",
          type: "management",
        },
      ],
    },
    {
      path: "/user",
      name: "Users",
      icon: <img src={account_circle} alt={account_circle} />,
      type: "admin",
      new: false,
      subRoutes: [
        {
          pathName: "/user",
          name: "Employee ",
          type: "admin",
        },
        {
          pathName: "/addemployee",
          name: "Add Employee",
          type: "admin",
        },
        {
          pathName: "/addattendance",
          name: "Employee Attendance",
          type: "admin",
        },
      ],
    },
    {
      path: "/requests",
      name: "Request",
      icon: <img src={clipboard} alt={clipboard} />,
      type: "common",
      new: false,
      subRoutes: [
        {
          pathName: "/requests",
          name: "Leave",
          type: "common",
        },
        {
          pathName: "/grievance",
          name: "Grievance",
          type: "common",
        },
        {
          pathName: "/resignation",
          name: "Resignation",
          type: "common",
        },
        {
          pathName: "/posh",
          name: "POSH",
          type: "management",
        },
      ],
    },
    {
      path: "/viewrequest",
      name: "View Request",
      icon: <img src={monitor} alt={monitor} />,
    },
    {
      path: "/payroll",
      name: "Payroll",
      icon: <img src={calendar} alt={calendar} />,
      type: "common",
    },
    {
      path: "/job",
      name: "Jobs",
      icon: <img src={work} alt={work} />,
      type: "admin",
      new: true,
    },
    {
      path: "/job",
      name: "Jobs",
      icon: <img src={work} alt={work} />,
      type: "management",
      // new: true,
      subRoutes: [
        {
          pathName: "/job",
          name: "Job List ",
          type: "management",
        },
        {
          pathName: "/AddJob",
          name: "Add Job",
          type: "management",
        },
      ],
    },

    {
      path: "/viewreports",
      name: "Reports",
      icon: <img src={problem} alt={problem} />,
      type: "admin",
    },
    {
      path: "/report",
      name: "Reports",
      icon: <img src={problem} alt={problem} />,
      type: "management",
    },

    {
      path: "/settings",
      name: "Settings",
      icon: <img src={page_info} alt={page_info} />,
      type: "admin",
      new: true,
    },
    {
      path: "/company",
      name: "Settings",
      icon: <img src={page_info} alt={page_info} />,
      type: "management",
      // new: true,
      subRoutes: [
        {
          pathName: "/company",
          name: "Company",
          type: "management",
        },
        {
          pathName: "/stores",
          name: "Stores",
          type: "management",
        },
        {
          pathName: "/designation",
          name: "Designation",
          type: "management",
        },
        {
          pathName: "/roles",
          name: "Roles",
          type: "management",
        },
        {
          pathName: "/shifts",
          name: "Shifts",
          type: "management",
        },
      ],
    },
  ];
  // Create@098
  const sidebarAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 10,
      },
    },
    show: {
      width: "100%",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 10,
      },
    },
  };
  const handleSubmenuClick = (subRoute) => {
    setActiveSubmenu(subRoute.pathName);
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const handleNavLinkClick = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };
  const filterRoutesByRole = (routes, role) => {
    return routes.filter((route) => {
      if (route.type === "common" || route.type === role) {
        if (route.subRoutes) {
          route.subRoutes = route.subRoutes.filter(
            (subRoute) => subRoute.type === "common" || subRoute.type === role
          );
        }
        return true;
      }
      return false;
    });
  };

  const filteredRoutes = filterRoutesByRole(routes, userRole);

  return (
    <>
      <AnimatePresence>
        <aside id="layout-menu">
          <div
            className={`app-brand demo ${
              isMobile && "justify-content-sm-between "
            }`}
          >
            <span className="app-brand-text demo menu-text fw-bolder">
              <img src={small_logo} alt="Brand Logo" />
            </span>
            {isMobile && (
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={funtype}
                >
                  <path
                    d="M5.3 18.7C5.5 18.9 5.7 19 6 19C6.3 19 6.5 18.9 6.7 18.7L12 13.4L17.3 18.7C17.5 18.9 17.8 19 18 19C18.2 19 18.5 18.9 18.7 18.7C19.1 18.3 19.1 17.7 18.7 17.3L13.4 12L18.7 6.7C19.1 6.3 19.1 5.7 18.7 5.3C18.3 4.9 17.7 4.9 17.3 5.3L12 10.6L6.7 5.3C6.3 4.9 5.7 4.9 5.3 5.3C4.9 5.7 4.9 6.3 5.3 6.7L10.6 12L5.3 17.3C4.9 17.7 4.9 18.3 5.3 18.7Z"
                    fill="#000"
                  />
                </svg>
              </span>
            )}
          </div>
          <section className="routes">
            {/* Render filtered routes */}
            {filteredRoutes.map((route, index) => {
              const isSubmenuActive = route.subRoutes?.some(
                (subRoute) => subRoute.pathName === activeSubmenu
              );
              const isSettingsRoute = route.name === "Settings";
              if (route.new) {
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    style={{
                      borderTop: "1px solid #2c357d",
                      borderBottom: isSettingsRoute ? "1px solid #2c357d" : "",
                    }}
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={sidebarAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              }
              if (route.subRoutes) {
                return (
                  <>
                    <SidebarMenu
                      key={index}
                      route={route}
                      showAnimation={sidebarAnimation}
                      isOpen={isOpen}
                      onSubmenuClick={handleSubmenuClick}
                      isActive={isSubmenuActive}
                      filteredRoutes={filteredRoutes}
                      pathnames={pathnames}
                    />
                  </>
                );
              }

              // Render route without subRoutes
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                  style={{
                    borderTop: !isSubmenuActive ? "1px solid #2c357d" : "",
                    borderBottom: isSettingsRoute ? "1px solid #2c357d" : "",
                  }}
                  onClick={handleNavLinkClick}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={sidebarAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </aside>
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
