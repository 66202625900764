import React, { useEffect, useState } from "react";
import "./request.css";
import link from "../../../assets/img/link.png";
import profile from "../../../assets/img/profile.png";
import {
  addCommentforLeave,
  getLeaveDetailsdata,
  putRejacedApprovedata,
} from "../../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import RadialSeparators from "./RadialSeparators";

import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import { position } from "@chakra-ui/react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import SpinnerLoader from "../../../components/common/spinnerloader/SpinnerLoader";

const ViewRequest = () => {
  const [commentValue, setCommentValue] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { allDetailLeave } = useSelector((state) => state?.main);
  const pathname = location.pathname;

  const pageLoading = useSelector((state) => state.main.loading);

  const navigate = useNavigate();

  const pathParts = pathname.split("/");

  const userId = pathParts[pathParts.length - 1];
  const Attendance = {
    records: [
      {
        status: "Leave Type",
        reason: allDetailLeave?.data?.type,
      },
      {
        status: "Store Manager",
        reason: allDetailLeave?.data?.assignedTo,
      },
      {
        status: "Store Location",
        reason: allDetailLeave?.data?.storeAddress,
      },
      {
        status: "Shift Time",
        reason: allDetailLeave?.data?.shiftName,
      },
      {
        status: "Leaves utilized",
        totalLeaves: allDetailLeave?.data?.totalLeaves,
        remainedLeaves: allDetailLeave?.data?.remainedLeaves,
        consumedLeaves: allDetailLeave?.data?.consumedLeaves,
        lossofPayLeaves: allDetailLeave?.data?.lossofPayLeaves,
      },
    ],
  };

  useEffect(() => {
    dispatch(getLeaveDetailsdata(userId));
  }, [dispatch, userId]);

  const handleChange = (status) => {
    dispatch(putRejacedApprovedata(status, userId, navigate));
  };

  const commentChangeHandler = (e) => {
    setCommentValue({ ...commentValue, [e.target.name]: e.target.value });
  };

  const submitCommentHandler = () => {
    const data = {
      comment: commentValue?.comment,
    };

    dispatch(addCommentforLeave(data, userId, navigate, setCommentValue));
  };

  const liStyle = {
    color: "#2C357D",
  };
  const parastyle = {
    color: "#000",
  };

  function Example({ children, label }) {
    return (
      <div style={{ width: "50%" }}>
        <div style={{ display: "flex", width: "70%" }}>
          <div>{children}</div>
          {/* <div style={{ width: "30%" }}>
            <h3 className="h5">{label}</h3>
            <p>This component showcases a progress bar with separators.</p>
          </div> */}
        </div>
      </div>
    );
  }

  const valuesOfPercentage = (total, consumed) => {
    const fraction = parseInt(consumed) / parseInt(total);
    const percentage = (fraction * 100).toFixed(2);

    return percentage;
  };
  return (
    <>
      {pageLoading ? (
        <SpinnerLoader />
      ) : (
        <div className="content-wrapper">
          <div className="wrap">
            <div className="container-fluid flex-grow-1 pt-4">
              <div className="logo_texts">
                <div className="logo_icons">
                  {allDetailLeave?.data ? (
                    <img
                      src={allDetailLeave.data.profileImage}
                      alt="Profile"
                      width={100}
                      height={100}
                      style={{ objectFit: "cover", borderRadius: "50px" }}
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-start align-items-start ">
                  <div className="des_texts d-flex  justify-content-start gap-3 pr-4">
                    <h5> {allDetailLeave?.data?.adminName}</h5>

                    <p style={liStyle}>
                      {allDetailLeave?.data?.appliedDate
                        ? moment(allDetailLeave.data.appliedDate).format(
                            "DD MMMM YYYY : h:mm A"
                          )
                        : "No date available"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-start gap-2">
                    <span
                      style={liStyle}
                      className="d-flex lign-item-start justify-content-start gap-2"
                    >
                      to:{" "}
                      <p style={parastyle}>
                        {allDetailLeave?.data?.employee_email}
                      </p>{" "}
                    </span>
                    {allDetailLeave?.data?.adminEmail && (
                      <span
                        style={liStyle}
                        className="d-flex lign-item-start justify-content-start gap-2"
                      >
                        cc:{" "}
                        <p style={parastyle}>
                          {allDetailLeave?.data?.adminEmail}
                        </p>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex align-item-start justify-content-start gap-3">
                {Attendance?.records?.map((record, index) => (
                  <div
                    className={`${
                      record?.status === "Leaves utilized"
                        ? "cardmornings2"
                        : "cardmornings"
                    }`}
                  >
                    <div className="right_reqs">
                      <h4>{record?.status}</h4>
                      <div className="min_flex_reqs">
                        {record.status !== "Leaves utilized" && (
                          <h1>{record?.reason}</h1>
                        )}
                        {record.status === "Leaves utilized" && (
                          <div className="totalvaluesofleave">
                            <div className="totalvaluesofleaveONE">
                              <p>PL</p>{" "}
                              <Example label="Progressbar with Separators">
                                <CircularProgressbarWithChildren
                                  value={valuesOfPercentage(
                                    record.totalLeaves,
                                    record.consumedLeaves
                                  )}
                                  text={`${record.consumedLeaves}/${record.totalLeaves}`}
                                  strokeWidth={10}
                                  styles={buildStyles({
                                    strokeLinecap: "butt",
                                  })}
                                >
                                  <RadialSeparators
                                    count={parseInt(record.totalLeaves)}
                                    style={{
                                      background: "#fff",
                                      width: "2px",
                                      // This needs to be equal to props.strokeWidth
                                      height: `${10}%`,
                                    }}
                                  />
                                </CircularProgressbarWithChildren>
                              </Example>
                            </div>
                            <div className="totalvaluesofleaveONE">
                              <p>LOP</p>{" "}
                              <Example label="Progressbar with Separators">
                                <CircularProgressbarWithChildren
                                  value={valuesOfPercentage(
                                    15,
                                    record.lossofPayLeaves
                                  )}
                                  text={`${record.lossofPayLeaves}`}
                                  strokeWidth={10}
                                  styles={buildStyles({
                                    strokeLinecap: "butt",
                                  })}
                                >
                                  <RadialSeparators
                                    count={15}
                                    style={{
                                      background: "#fff",
                                      width: "2px",
                                      // This needs to be equal to props.strokeWidth
                                      height: `${10}%`,
                                    }}
                                  />
                                </CircularProgressbarWithChildren>
                              </Example>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="para_lorems">
                <div className="head_viewss">
                  <div className="application_pins">
                    <h6>Leave Application</h6>
                    <img src={link} alt="" />
                  </div>
                </div>
                {/* <div className="lorem_texts1">
              Type: <p>{allDetailLeave?.data?.type}</p>
            </div> */}
                <div className="lorem_texts1">
                  Reason: <p>{allDetailLeave?.data?.reason}</p>
                </div>
                {allDetailLeave?.data?.startDate && (
                  <div className="lorem_texts1">
                    Start Date:{" "}
                    <p>
                      {new Date(
                        allDetailLeave?.data?.startDate
                      ).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                )}

                {allDetailLeave?.data?.endDate && (
                  <div className="lorem_texts1">
                    End Date:{" "}
                    <p>
                      {new Date(
                        allDetailLeave?.data?.endDate
                      ).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                )}
                <div className="lorem_texts1">
                  Session (start): <p>{allDetailLeave?.data?.fromSession}</p>
                </div>
                <div className="lorem_texts1">
                  Session (end): <p>{allDetailLeave?.data?.toSession}</p>
                </div>
              </div>
              {/* {allDetailLeave?.data?.status == "Pending" && (
         
          )} */}
              {allDetailLeave?.data?.status !== "Approved" &&
                allDetailLeave?.data?.status !== "Rejected" &&
                allDetailLeave?.data?.status == "Pending" && (
                  <div className="btn_accept">
                    <div className="accept_1">
                      <button onClick={() => handleChange("Approved")}>
                        Accept
                      </button>
                    </div>
                    <div className="reject_1">
                      <button onClick={() => handleChange("Rejected")}>
                        Reject
                      </button>
                    </div>
                  </div>
                )}
              <div className="add_textarea">
                <div className="heading_add">
                  <h3>Add Comment</h3>
                </div>
                <textarea
                  placeholder="Describe yourself here..."
                  onChange={commentChangeHandler}
                  name="comment"
                ></textarea>
              </div>
              <div className="submit_buttons">
                <button onClick={() => submitCommentHandler()}>Submit</button>
              </div>
              <div className="para_loremss">
                <div className="head_views">
                  <div className="application_pins">
                    <h6>History of comments</h6>
                  </div>
                </div>
                <div className="lorem_textss2 border-bottom-0 pt-2 ">
                  {allDetailLeave?.data?.comments?.map(
                    (data) =>
                      data?.comment && (
                        <div
                          key={data.id}
                          className="d-flex justify-content-between align-items-start"
                        >
                          <p>{data?.comment}</p>{" "}
                          <p className="d-flex flex-column justify-content-start latPara">
                            <span>Name : {data?.commentedBy?.name}</span>{" "}
                            <span>
                              Designation: {data?.commentedBy?.designation}
                            </span>
                            <span className="helops">
                              Date & Time :{" "}
                              <span>
                                {moment(data?.createdAt).format("YYYY-MM-DD")}{" "}
                              </span>
                              <span className="ml-5">
                                {moment(data?.createdAt).format("hh:mm A")}
                              </span>
                            </span>
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>

              {/* <div className="canditate_selector">
          <Selector label="Blood Group" />
        </div> */}
              {/* <div className="submit_buttons">
            <button>Submit</button>
          </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewRequest;
