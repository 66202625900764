import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import req_user from "../../assets/img/req_user.svg";
import cross from "../../assets/img/cross.png";
import watch_user from "../../assets/img/watch_user.svg";
import right_mark from "../../assets/img/right_mark.png";
import upper_mark from "../../assets/img/upper_mark.png";
import greydrop from "../../assets/img/greydrop.png";
import "./grievance.css";
import ViewGrievance from "./ViewGrievance";
import { useDispatch, useSelector } from "react-redux";
import { getGrievancedata } from "../../redux/Action"; // Assuming action to fetch grievance data
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";
const Grievance = () => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { getGrievanceList } = useSelector((state) => state?.main);
  const pageLoading = useSelector((state) => state.main.loading);

  useEffect(() => {
    const totalTicket = getGrievanceList?.data?.totalGrievances || 0;
    const pendingTicket = getGrievanceList?.data?.pendingGrievances || 0;
    const OpenTicket = getGrievanceList?.data?.openGrievances || 0;
    const SolvedTicket = getGrievanceList?.data?.solvedGrievances || 0;
    const escalatedTicket = getGrievanceList?.data?.escalatedGrievances || 0;

    const fetchedAttendanceData = [
      {
        status: "Total Ticket Request",
        count: totalTicket,
        imgSrc: <img src={req_user} alt="req_user" />,
      },
      {
        status: "Solved Tickets",
        count: SolvedTicket,
        imgSrc: <img src={cross} alt="cross" />,
      },
      {
        status: "Open Tickets",
        count: OpenTicket,
        imgSrc: <img src={watch_user} alt="watch_user" />,
      },
      {
        status: "Pending",
        count: pendingTicket,
        imgSrc: <img src={right_mark} alt="right_mark" />,
      },
      {
        status: "Escalated",
        count: escalatedTicket,
        imgSrc: <img src={upper_mark} alt="upper_mark" />,
        type: "admin",
      },
    ];

    setAttendanceData(fetchedAttendanceData);
    setTableData(getGrievanceList?.data || []);
  }, []);

  useEffect(() => {
    dispatch(getGrievancedata(currentPage, limit));
  }, [dispatch, currentPage, limit]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/grievance/viewgrievance/${data}`);
  };

  return (
    <div className="content-wrapper">
      {/* Conditionally render content when onRequest is false */}
      {!onRequest && (
        <>
          <div className="flex-grow-1 pt-4 view-request">
            <div className="leave_wrap">
              {attendanceData
                .filter((record) => !record.type || record.type === userRole)
                .map((record, index) => (
                  <div key={index} className="cardmornings">
                    <div className="totalnumber_card">
                      <div className="left_conetent">
                        <div className="view-iocn">{record.imgSrc}</div>
                      </div>
                      <div className="right_req">
                        <h4>{record.status}</h4>
                        <div className="min_flex_req">
                          <h1>{record.count}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="container-fluid view-request">
            {/* Responsive Table */}
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      {[
                        { name: "Ticket Id", icon: null },
                        { name: "Employee Name", icon: null },
                        { name: "Store Name", icon: null },
                        { name: "Assigned to", icon: null },
                        { name: "Created Date", icon: null },
                        { name: "Description", icon: null },
                        { name: "Status", icon: greydrop }, // Add the icon for "Status"
                        { name: "Action", icon: null },
                      ].map((column, index) => (
                        <th key={index}>
                          {column.name}
                          {column.icon && (
                            <img
                              src={column.icon}
                              alt={`${column.name} dropdown icon`}
                              className="header-dropdown-icon"
                              onClick={() =>
                                console.log(
                                  `Dropdown clicked for ${column.name}`
                                )
                              }
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {getGrievanceList &&
                      getGrievanceList?.data?.grievances?.map(
                        (items, index) => (
                          <tr key={index}>
                            <td>{items.ticketNo || "-"}</td>
                            <td>{items.userId?.name || "-"}</td>
                            <td>{items.userId?.store_id?.name || "-"}</td>
                            <td>{items.userId?.assignedTo?.name || "-"}</td>
                            <td>
                              <td style={{ textAlign: "center" }}>
                                {moment(items.createdAt).format("DD MMM YYYY")}
                              </td>
                            </td>
                            <td>{items.description}</td>

                            <td>
                              <span
                                className={`badge badge-${
                                  items.status.toLowerCase() || "-"
                                }`}
                              >
                                {items.status}
                              </span>
                            </td>
                            <td>
                              <button
                                className="btn-view"
                                onClick={() => OpenRequest(items._id)}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              )}
              <Pagination
                listData={getGrievanceList?.meta}
                setPageData={setCurrentPage}
                tableData={tableData}
              />
            </div>
          </div>
        </>
      )}

      {onRequest && <ViewGrievance />}
    </div>
  );
};

export default Grievance;
