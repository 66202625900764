import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./sidebar.css";
import Vector from "../../assets/icons/Vector.png";

const SidebarMenu = ({
  route,
  showAnimation,
  isOpen,
  onSubmenuClick,
  filteredRoutes,
  pathnames,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const data = route.subRoutes.find((item) => item.pathName === pathnames);
    setCurrentPath(data);
  }, [pathnames]);

  // Toggle submenu when the parent menu item is clicked
  const toggleMenu = (data) => {
  
    
    if (!isMenuOpen) {
      navigate(`${data.path}`);
    }
    setIsMenuOpen(!isMenuOpen);
  };

  // Close submenu when the sidebar is collapsed
  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);

  const menuAnimation = {
    hidden: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.3 },
    },
    show: {
      opacity: 1,
      height: "auto",
      transition: { duration: 0.3 },
    },
  };

  // Check if the current route is for admin
  const isAdminRoute = filteredRoutes?.some(
    (filteredRoute) =>
      filteredRoute?.type === "common" &&
      filteredRoute?.pathName === route?.pathName
  );
  return (
    <div className="menu" style={{ borderTop: "1px solid #2c357d" }}>
      {/* Main menu item */}
      <div
        className={`menu_item ${currentPath ? `hello` : ``} ${
          isMenuOpen ? `hello2` : ``
        } `}
        onClick={() => toggleMenu(route)}
      >
        <div className="icon">{route.icon}</div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="link_text"
            >
              {route.name}
            </motion.div>
          )}
        </AnimatePresence>
        {isOpen &&
          route.subRoutes &&
          isAdminRoute && ( // Check if it's not an admin route
            <motion.div
              animate={isMenuOpen ? { rotate: 0 } : { rotate: -180 }}
              className="submenu_arrow"
            >
              <img src={Vector} alt="Dropdown Arrow" />
            </motion.div>
          )}
      </div>

      {/* Submenu items (Toggle visibility based on isMenuOpen) */}
      <AnimatePresence>
        {isMenuOpen && route.subRoutes && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="submenu_container"
          >
            {route.subRoutes.map((subRoute, i) => (
              <motion.div key={i} className="submenu_item">
                <NavLink
                  to={subRoute.pathName}
                  onClick={() => onSubmenuClick(subRoute)}
                  className={({ isActive }) =>
                    `submenu_link ${isActive ? "active" : ""}`
                  }
                >
                  {subRoute.name}
                </NavLink>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SidebarMenu;
