import React, { useState, useEffect } from "react";
import "./adddesgination.css"; // Assuming you have CSS to style the form
import { VscClose } from "react-icons/vsc";
import { addSettingResignation, updateDesignation } from "../../redux/Action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AddDesgination = ({ onClose, updatedData, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allResignationList } = useSelector((state) => state.main);
  const activeDesignations =
    allResignationList?.data?.filter((item) => item.status === true) || [];

  console.log({ activeDesignations });

  const fields = [
    { label: "Designation Name", type: "text", id: "name" },
    {
      label: "Reporting Designation",
      type: "select",
      id: "designation_id",
      options: activeDesignations?.map((item) => {
        return { name: item.name, value: item._id };
      }),
    },
    {
      label: "Status",
      type: "select",
      id: "status",
      options: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 },
      ],
    },
  ];

  const [formData, setFormData] = useState({
    name: updatedData?.name || "",
    designation_id: updatedData?.designation_id || "",
    status: updatedData?.status ?? null,
  });

  // Populate form data when editing
  useEffect(() => {
    if (status === "edit" && updatedData) {
      setFormData({
        name: updatedData?.name || "",
        designation_id: updatedData?.reposting_designation_id || "",
        status: updatedData?.status == true ? 1 : 0 ?? true,
      });
    }
  }, [status, updatedData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          payload.append(key, formData[key]);
        } else {
          payload.append(key, formData[key]);
        }
      }
    });

    if (status === "add") {
      dispatch(addSettingResignation(formData, navigate, onClose));
    } else if (status === "edit") {
      dispatch(updateDesignation(updatedData?._id, payload, onClose));
    }
  };

  return (
    <>
      <div className="adddesignation">
        <div className="d-flex justify-content-between">
          <h2>{status === "add" ? "Add Designation" : "Edit Designation"}</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id}>
              {field.type === "select" ? (
                <select
                  value={formData[field.id]}
                  onChange={handleChange}
                  className="form-group"
                  id={field.id}
                >
                  <option value="">Select {field.label}</option>
                  {field.options.map((option, index) => (
                    <>
                      {console.log(option)},
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    </>
                  ))}
                </select>
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>

      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>
          {status === "add" ? "Add Designation" : "Update Designation"}
        </button>
      </div>
    </>
  );
};

export default AddDesgination;
