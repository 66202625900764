import React, { useEffect, useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddStoreNew from "../AddStore/AddStoreNew";
import {
  getAllStores,
  getSettingStore,
  storedeleteData,
} from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";
import Pagination from "../../Common/Pagination/Pagination";

const Store = () => {
  const dispatch = useDispatch();

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [open, setOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [status, setstatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(openInviteMember, "openInviteMember");

  const { allStoreLists, deleteStorelist, addStoreUser, updateStore } =
    useSelector((state) => state.main);
  const pageLoading = useSelector((state) => state.main.loading);

  const handleClose = () => {
    setOpenInviteMember(false);
    setUpdatedData({});
  };

  useEffect(() => {
    dispatch(getSettingStore(currentPage));
  }, [currentPage, deleteStorelist, addStoreUser, updateStore]);

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(storedeleteData(selectedUserId?._id));
    onCloseModal();
  };
  const OnEditClickHandler = (data, key) => {
    setSelectedUserId(data?.id);
    setOpenInviteMember(true);
    setUpdatedData(data);
    setstatus(key);
  };

  const openAddCompany = (data) => {
    setOpenInviteMember(true);
    setstatus(data);
  };

  const fields = [
    { label: "Store Name", id: "name" },
    { label: "Company Name", id: "company_name" },
    { label: "Store Address", id: "address" },
    { label: "Status", id: "status" }, // Add this line
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Store</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => openAddCompany("add")}>Add Store</button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ borderBottom: "1px solid #2C357D2E" }}>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={{
                            borderLeft: "none",
                            textAlign:
                              field.id === "status" ? "center" : "left",
                          }}
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ width: "18%", borderRight: "none" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allStoreLists?.data.map((store, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td
                            key={field.id}
                            style={{
                              borderLeft: "none",
                              textAlign:
                                field.id === "status" ? "center" : "left",
                            }}
                          >
                            {field.id === "status" ? (
                              <span
                                style={{
                                  backgroundColor:
                                    store[field.id] === true
                                      ? "#a2eddb40"
                                      : store[field.id] === false
                                      ? "#ff323240"
                                      : "transparent",
                                  color:
                                    store[field.id] === true
                                      ? "#24e502"
                                      : store[field.id] === false
                                      ? "#ff3232"
                                      : "inherit",
                                  padding: "5px 15px",
                                  borderRadius: "8px",
                                  fontWeight: "300",
                                  display: "inline-block",
                                }}
                              >
                                {store[field.id] == true
                                  ? "Active"
                                  : store[field.id] == false
                                  ? "Inactive"
                                  : "-"}
                                {console.log(store[field.id], "monika")}
                              </span>
                            ) : (
                              store[field.id] || "-"
                            )}
                          </td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button
                              onClick={() => OnEditClickHandler(store, "edit")}
                            >
                              Edit
                            </button>
                            {/* Uncomment this if delete is needed */}
                            {/* <button onClick={() => onOpenModal(store)}>
                    Delete
                  </button> */}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {console.log(status)}
      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddStoreNew
            setOpenInviteMember={setOpenInviteMember}
            onClose={handleClose}
            status={status}
            updatedData={updatedData}
          />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
        <Pagination
          listData={allStoreLists?.meta}
          setPageData={setCurrentPage}
        />
      </>
    </>
  );
};

export default Store;
