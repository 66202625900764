export const SET_LOADING = "SET_LOADING";
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_BTN_LOADING = "SET_BTN_LOADING";
export const SET_USERLIST = "SET_USERLIST";
export const SET_ADD_USERDATA = "SET_ADD_USERDATA";
export const SET_DETELE_USER = "SET_DETELE_USER";
export const SET_FORGET_PASSWORD = "SET_FORGET_PASSWORD";
export const SET_VERIFICATION = "SET_VERIFICATION";
export const SET_NEWPASSWORD = "SET_NEWPASSWORD";
export const SET_LOCALSTRORAGE_LOGIN = "SET_LOCALSTRORAGE_LOGIN";
export const SET_REFRESHER_LOGIN = "SET_REFRESHER_LOGIN";
export const SET_NEWPASSWORDUSER = "SET_NEWPASSWORDUSER";
export const SET_LEAVEDATA = "SET_LEAVEDATA";
export const SET_GRIEVANCEDATA = "SET_GRIEVANCEDATA";
export const SET_VIEW_USERDATA = "SET_VIEW_USERDATA";
export const SET_UPDATE_USERDATA = "SET_UPDATE_USERDATA";
export const SET_ALLUSERLIST = "SET_ALLUSERLIST";
export const SET_LEAVEDATAREJAPP = "SET_LEAVEDATAREJAPP";
export const SET_LEAVEDELATAILSDATA = "SET_LEAVEDELATAILSDATA";
export const SET_RESIGNATIONDATA = "SET_RESIGNATIONDATA";
export const SET_GRIEVANCEDETAILSDATA = "SET_GRIEVANCEDETAILSDATA";
export const SET_GRIEVANCEREJAPP = "SET_GRIEVANCEREJAPP";
export const SET_RESIGNATIONDETAILSDATA = "SET_RESIGNATIONDETAILSDATA";
export const SET_RESIGNATIONREJAPP = "SET_RESIGNATIONREJAPP";
export const SET_POSHDATA = "SET_RESIGNATIONREJAPP";
export const SET_POSHDETAILSDATA = "SET_POSHDETAILSDATA";
export const SET_JOBLIST = "SET_JOBLIST";
export const SET_JOBDETAILS = "SET_JOBDETAILS";
export const SET_ADD_JOBDATA = "SET_ADD_JOBDATA";
export const SET_REPORTLIST = "SET_REPORTLIST";
export const SET_STORELIST = "SET_STORELIST";
export const SET_STORESINGLE = "SET_STORESINGLE";
export const SET_STORESINGLE_USER = "SET_STORESINGLE_USER";
export const SET_REPORTSINGLEUSER = "SET_REPORTSINGLEUSER";
export const SET_DASHBOARDLIST = "SET_DASHBOARDLIST";
export const SET_GRIEVANCECOMMEENT = "SET_GRIEVANCECOMMEENT";
export const SET_RESIGNATIONCOMMEENT = "SET_RESIGNATIONCOMMEENT";
export const SET_POSHCOMMEENT = "SET_POSHCOMMEENT";
export const SET_LEAVECOMMEENT = "SET_LEAVECOMMEENT";
export const SET_ALLCOMPANYLIST = "SET_ALLCOMPANYLIST";
export const SET_ADD_COMPANY = "SET_ADD_COMPANY";
export const SET_ALLSHIFTLIST = "SET_ALLSHIFTLIST";
export const SET_ADD_SHIFT = "SET_ADD_SHIFT";
export const SET_ALLSTORELIST = "SET_ALLSTORELIST";
export const SET_ADD_STORE = "SET_ADD_STORE";
export const SET_ALLROLELIST = "SET_ALLROLELIST";
export const SET_ADD_ROLE = "SET_ADD_ROLE";
export const SET_ALLRESIGNATIONLIST = "SET_ALLRESIGNATIONLIST";
export const SET_ADD_RESINATION = "SET_ADD_RESINATION";
export const SET_ADD_STORES = "SET_ADD_STORES";
export const SET_ALL_STORE_LIST_DATA = "SET_ALL_STORE_LIST_DATA";
export const SET_USER_ATTENDENCE_DATA = "SET_USER_ATTENDENCE_DATA";
export const SET_DETELE_JOB = "SET_DETELE_JOB";
export const SET_DETELE_COMPANY = "SET_DETELE_COMPANY";
export const SET_DETELE_STORE = "SET_DETELE_STORE";
export const SET_DETELE_DESIGNATION = "SET_DETELE_DESIGNATION";
export const SET_DETELE_ROLE = "SET_DETELE_ROLE";
export const SET_DETELE_SHIFT = "SET_DETELE_SHIFT";
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const SET_SITE_SETTING = "SET_SITE_SETTING";
export const SET_STATE_LIST = "SET_STATE_LIST";
export const SET_CITY_LIST = "SET_CITY_LIST";
export const SET_SITE_SETTING_UPDATE = "SET_SITE_SETTING_UPDATE";
export const SET_SEARCH_USER = "SET_SEARCH_USER";
export const SET_ADD_DOCUMENTUPLOAD = "SET_ADD_DOCUMENTUPLOAD";
export const SET_GET_DOCUMENTUPLOAD = "SET_GET_DOCUMENTUPLOAD";
export const SET_DESIGNATION_UPDATE = "SET_DESIGNATION_UPDATE";
export const SET_STATE_LIST_OPTION = "SET_STATE_LIST_OPTION";
export const SET_COMPANY_EDIT_DATA = "SET_COMPANY_EDIT_DATA";
export const SET_STORE_DATA = "SET_STORE_DATA";
export const SET_ROLE_DATA = "SET_ROLE_DATA";
export const SET_SHIFT_DATA = "SET_SHIFT_DATA";
export const SET_GET_PROFILE = "SET_GET_PROFILE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_ALLSTORELIST_BY_COMPANY = "SET_ALLSTORELIST_BY_COMPANY";
export const SET_CITY_LIST_EMER = "SET_CITY_LIST_EMER";
export const SET_STATE_LIST_EMER = "SET_STATE_LIST_EMER";
export const SET_UPDATE_JOB = "SET_UPDATE_JOB";
