import React, { useState, useEffect, useRef } from "react";
import "./selector.css";
import dropnewicon from "../../../assets/img/dropnewicon.svg";

const Selector = ({
  label,
  options,
  name,
  value,
  onChange,
  LabelFontSize,
  SelectorFontSize,
  labelColor = "#2C357D80",
  starColor = "red",
  showStar,
  error,
  width,
  borderColor,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleToggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (optionValue) => {
    if (!disabled && !optionValue.isDisabled) {
      onChange({ target: { name, value: optionValue.value } });
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const labelStyle = {
    fontSize: LabelFontSize,
    color: labelColor,
  };

  const selectStyle = {
    fontSize: SelectorFontSize,
    borderBottom: `1px solid ${borderColor}`,
    backgroundColor: disabled ? "" : "white",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  const handleClearSelection = (e) => {
    e.stopPropagation();
    if (!disabled) {
      onChange({ target: { name, value: "" } });
    }
  };

  return (
    <div
      className="selector_wrapper"
      style={{ width: width || "100%" }}
      ref={wrapperRef}
    >
      <label className="selector_label" style={labelStyle}>
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <div
        className={`custom_selector ${disabled ? "disabled" : ""}`}
        style={selectStyle}
        onClick={handleToggleDropdown}
      >
        {value
          ? options?.find((option) => option?.value === value)?.label
          : "Select an option"}

        <div>
          {value && (
            <svg
              width="25"
              height="30"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleClearSelection}
              style={{ cursor: disabled ? "not-allowed" : "pointer" }}
            >
              <rect
                width="60"
                height="60"
                rx="30"
                fill="#2C357D"
                fillOpacity="0.15"
              />
              <path
                d="M37.75 22.1377L21.25 38.6377"
                stroke="#2C357D"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.25 22.1377L37.75 38.6377"
                stroke="#2C357D"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          {!disabled && (
            <img
              src={dropnewicon}
              alt=""
              className={`dropdown_icon ${isOpen ? "open" : ""}`}
            />
          )}
        </div>
      </div>
      {isOpen && !disabled && (
        <div className="custom_dropdown">
          {options?.map((option) => (
            <div
              key={option.value}
              className={`custom_option ${
                option.isDisabled ? "disabled_option" : ""
              }`}
              onClick={() => handleOptionClick(option)}
              style={{
                cursor:
                  option.isDisabled || disabled ? "not-allowed" : "pointer",
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Selector;
