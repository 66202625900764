import React, { useState, useEffect } from "react";
import "./addshift.css";
import { VscClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addSettingShift, editShift } from "../../redux/Action";

const AddShift = ({ onClose, updatedData, status }) => {
  console.log(status);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userAdminId = localStorage.getItem("adminId");

  const fields = [
    { label: "Shift Name", type: "text", id: "name" },
    { label: "Shift Code", type: "text", id: "shiftCode" },
    {
      label: "Shift Start Time",
      sublabel: "(24-hour format)",
      type: "time",
      id: "start_time",
    },
    {
      label: "Shift End Time",
      sublabel: "(24-hour format)",
      type: "time",
      id: "end_time",
    },
    { label: "Grace In Time", type: "time", id: "graceInTime" },
    { label: "Grace Out Time", type: "time", id: "graceOutTime" },
    {
      label: "Status",
      type: "select",
      id: "status",
      options: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 0 },
      ],
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    shiftCode: "",
    start_time: "",
    end_time: "",
    graceInTime: "",
    graceOutTime: "",

    status: true,

    adminId: userAdminId,
  });

  // Helper function to split time into hour and minute
  const splitTime = (time) => {
    if (!time || !time.includes(":")) return { hour: "", minute: "" };
    const [hour, minute] = time.split(":");
    return { hour, minute };
  };

  // Populate fields when editing
  useEffect(() => {
    if (status === "edit" && updatedData) {
      setFormData({
        name: updatedData?.name || "",
        shiftCode: updatedData?.shiftCode || "",
        start_time: splitTime(updatedData?.start_time),
        end_time: splitTime(updatedData?.end_time),
        graceInTime: splitTime(updatedData?.graceInTime),
        graceOutTime: splitTime(updatedData?.graceOutTime),

        status: updatedData?.status == true ? 1 : 0 ?? true, // Set the status

        adminId: userAdminId,
      });
    }
  }, [status, updatedData, userAdminId]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id.includes("-hour") || id.includes("-minute")) {
      const [timeType, unit] = id.split("-");

      setFormData((prevData) => {
        const updatedData = { ...prevData };
        const timeObj = updatedData[timeType] || { hour: "", minute: "" };

        if (unit === "hour") {
          timeObj.hour = value;
        } else if (unit === "minute") {
          timeObj.minute = value;
        }

        updatedData[timeType] = timeObj;
        return updatedData;
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const formatTime = (timeObj) => {
    if (!timeObj) return "";
    const { hour, minute } = timeObj;
    return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    const formattedData = {
      ...formData,
      start_time: formatTime(formData.start_time),
      end_time: formatTime(formData.end_time),
      graceInTime: formatTime(formData.graceInTime),
      graceOutTime: formatTime(formData.graceOutTime),
    };

    Object.keys(formattedData).forEach((key) => {
      if (formattedData[key] !== null) {
        if (formattedData[key] instanceof File) {
          payload.append(key, formattedData[key]);
        } else {
          payload.append(key, formattedData[key]);
        }
      }
    });

    if (status === "add") {
      dispatch(addSettingShift(formattedData, navigate, onClose));
    } else if (status === "edit") {
      dispatch(editShift(updatedData?._id, payload, onClose));
    }
  };

  return (
    <>
      <div className="addshift">
        <div className="d-flex justify-content-between">
          <h2>{status === "add" ? "Add Shift" : "Edit Shift"}</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label
              key={field.id}
              htmlFor={field.id}
              style={{ display: "block" }}
            >
              {field.label}
              {field.sublabel && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#2c357d",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {field.sublabel}
                </span>
              )}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id}>
              {field.type === "text" ? (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              ) : field.type === "time" ? (
                <div className="d-flex">
                  <input
                    type="text"
                    id={`${field.id}-hour`}
                    value={formData[field.id]?.hour || ""}
                    onChange={handleChange}
                    className="form-control time-input"
                    placeholder="HH"
                  />
                  <div style={{ padding: "5px" }}>:</div>
                  <input
                    type="text"
                    id={`${field.id}-minute`}
                    value={formData[field.id]?.minute || ""}
                    onChange={handleChange}
                    className="form-control time-input"
                    placeholder="MM"
                  />
                </div>
              ) : field.type === "select" ? (
                <select
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">Select {field.label}</option>
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddShift;
