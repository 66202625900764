import React, { useEffect, useRef, useState } from "react";
import "./customselect.css";
import dropnewicon from "../../../assets/img/dropnewicon.svg";

const CustomSelector = ({
  label,
  options,
  name,
  value = [],
  onChange,
  LabelFontSize,
  SelectorFontSize,
  labelColor = "#2C357D80",
  starColor = "red",
  showStar,
  error,
  width,
  borderColor,
  multi = false,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  // const handleOptionClick = (optionValue) => {
  //   if (value.includes(optionValue)) return;
  //   const newValue = multi ? [...value, optionValue] : [optionValue];
  //   onChange({ target: { name, value: newValue } });
  // };

  const handleOptionClick = (optionValue) => {
    let newValue;
    if (value.includes(optionValue)) {
      // Unselect the option if it is already selected
      newValue = value.filter((val) => val !== optionValue);
    } else {
      // Select the option if it is not already selected
      newValue = multi ? [...value, optionValue] : [optionValue];
    }
    onChange({ target: { name, value: newValue } });
  };

  const labelStyle = {
    fontSize: LabelFontSize,
    color: labelColor,
  };

  const selectStyle = {
    fontSize: SelectorFontSize,
    borderBottom: `1px solid ${borderColor}`,
  };

  const selectedLabels =
    options
      ?.filter((option) => value?.includes(option.value))
      ?.map((option) => option.label)
      ?.join(", ") || "Select options";

  return (
    <div
      className="selector_wrapper"
      style={{ width: width || "100%" }}
      ref={wrapperRef}
    >
      <label className="selector_label" style={labelStyle}>
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <div
        className="custom_selector"
        style={selectStyle}
        onClick={() => handleToggleDropdown()}
      >
        {selectedLabels}
        <img
          src={dropnewicon}
          alt="dropdown icon"
          className={`dropdown_icon ${isOpen ? "open" : ""}`}
        />
      </div>
      {isOpen && (
        <div className="custom_dropdown">
          {options?.map((option) => (
            <div
              key={option.value}
              className="custom_option"
              onClick={() => handleOptionClick(option.value)}
              style={{ opacity: value.includes(option.value) ? 0.5 : 1 }} // Make selected options less opaque
            >
              <input
                type="checkbox"
                checked={value.includes(option.value)}
                onChange={() => handleOptionClick(option.value)}
                disabled={value.includes(option.value)} // Disable already selected options
              />
              <label>{option.label}</label>
            </div>
          ))}
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default CustomSelector;
