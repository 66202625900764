import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Buttonclose from "../../../assets/img/Buttonclose.png";
import Avatar from "../../../assets/img/Avatar.png";
import "./notification.css";
import moment from "moment";

const Notification = ({ onClose, open, getAllNotificationList }) => {
  function getTimeDifference(createdAt) {
    // Parse the given time
    const givenTime = moment(createdAt);

    // Get the current time
    const currentTime = moment();

    // Calculate the difference
    const duration = moment.duration(currentTime.diff(givenTime));

    // Format the difference into human-readable parts
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let difference = "";
    if (days > 0) difference += `${days} day${days > 1 ? "s" : ""}, `;
    if (hours > 0 || days > 0)
      difference += `${hours} hour${hours > 1 ? "s" : ""}, `;
    difference += `${minutes} minute${minutes > 1 ? "s" : ""}`;

    return difference;
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        center
        classNames={{
          modal: "notification_confirm_popup",
        }}
      >
        <div className="top_flex">
          <h2>Notification</h2>
          <img src={Buttonclose} alt="Close" onClick={onClose} />
        </div>

        <div
          className="border_top"
          style={{ borderBottom: "1px solid #86888a80" }}
        ></div>

        {getAllNotificationList?.data?.length > 0 ? (
          getAllNotificationList?.data?.slice(0, 5).map((data) => (
            <>
              <div className="massage_box">
                <div className="massage_image">
                  <img src={Avatar} alt="Avatar" />
                </div>
                <div className="massage_text">
                  <h2>{data?.key}</h2>
                  <div className="border_horizontal">
                    <p>{data?.message}</p>
                  </div>
                  <span className="time_data">
                    {getTimeDifference(data?.createdAt)} ago
                  </span>
                </div>
              </div>
              <div
                className="border_top"
                style={{ borderBottom: "1px solid #86888a80" }}
              ></div>
            </>
          ))
        ) : (
          <p>No new Notification</p>
        )}
      </Modal>
    </div>
  );
};

export default Notification;
