import React, { useState, useEffect } from "react";
import "./addrole.css"; // Assuming you have CSS to style the form
import { VscClose } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addSettingRole, editRole } from "../../redux/Action";

const AddRole = ({ onClose, updatedData, status }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fields = [
    { label: "Role Name", type: "text", id: "name" },
    { label: "Role Description", type: "text", id: "description" },
    {
      label: "Status",
      type: "select",
      id: "status",
      options: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
    },
  ];

  const [formData, setFormData] = useState({
    name: updatedData?.name || "",
    description: updatedData?.description || "",
    status: updatedData?.status ?? null, // Default to "Active"
  });

  useEffect(() => {
    if (status === "edit" && updatedData) {
      setFormData({
        name: updatedData?.name || "",
        description: updatedData?.description || "",
        status: updatedData?.status ?? true, // Set the status
      });
    }
  }, [status, updatedData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const clickSubmitHandler = () => {
    const payload = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (formData[key] instanceof File) {
          payload.append(key, formData[key]);
        } else {
          payload.append(key, formData[key]);
        }
      }
    });

    if (status === "add") {
      dispatch(addSettingRole(payload, navigate, onClose));
    } else if (status === "edit") {
      dispatch(editRole(updatedData?._id, payload, onClose));
    }
  };

  return (
    <>
      <div className="addrole">
        <div className="d-flex justify-content-between">
          <h2>{status === "add" ? "Add Role" : "Edit Role"}</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className="label_name">
          {fields.map((field) => (
            <label key={field.id} htmlFor={field.id}>
              {field.label}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.id}>
              {field.type === "select" ? (
                <select
                  value={formData[field.id]}
                  onChange={handleChange}
                  className="form-group"
                  id={field.id}
                >
                  <option value="">Select {field.label}</option>
                  {field.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="form-group"
                  type={field.type}
                  id={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>
          {status === "add" ? "Add Role" : "Update Role"}
        </button>
      </div>
    </>
  );
};

export default AddRole;
