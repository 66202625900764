import React from "react";
import "./inputfleid.css";

const InputField = ({
  label,
  inputType,
  inputPlaceholder,
  LabelFontSize,
  InputFontSize,
  name,
  value,
  onChange,
  error,
  width,
  borderColor = "#2c357d26", // Default border color
  labelColor = "#2c357d80", // Default label color
  starColor = "red",
  showStar,
  disabled,
  max,
  max2,
  newMax,
}) => {
  const inputStyle = {
    color: disabled ? "#2c357d" : "#2c357d",
    fontSize: InputFontSize,
    borderBottom: `1px solid ${borderColor}`,
    paddingLeft: "10px",
    paddingRight: "8px",
    backgroundColor: disabled ? "" : "white",
    cursor: disabled ? "not-allowed" : "text",
  };

  const labelStyle = {
    fontSize: LabelFontSize,
    color: labelColor,
  };

  const myDate = new Date().toISOString().split("T")[0];

  return (
    <div className="form" style={{ width: width || "100%" }}>
      <label className="inputLabel" htmlFor={name} style={labelStyle}>
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <input
        type={inputType}
        placeholder={inputPlaceholder}
        className="form_control mt-1"
        style={inputStyle} // Apply input styles dynamically
        name={name}
        value={value}
        onChange={onChange}
        id={name}
        disabled={disabled}
        maxLength={max ? 10 : max2 ? max2 : undefined} // Apply max value only for number inputs
        min={inputType === "number" ? 0 : undefined}
        max={newMax ? myDate : undefined}
      />

      {error && <div className="error">{error}</div>}

      <style>
        {`
        .inputclass::placeholder {
          color: #C9D0DD;
        }
      `}
      </style>
    </div>
  );
};

export default InputField;
