import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  getNotification,
  getProfile,
  getSearchUser,
  logout,
} from "../../redux/Action";
import Notification from "../common/Notification/Notification";
import menu from "../../assets/img/menu.svg";
import bellicon from "../../assets/img/bellicon.svg";
import profilePic from "../../assets/img/profilePic.png";
import container from "../../assets/img/container.png";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveSidebar from "../Sidebar/ResponsiveSidebar";

const Navbar = ({ funtype }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { allusersearch, allProfile, getAllNotificationList } = useSelector(
    (state) => state.main
  );
  const [query, setQuery] = useState("");
  const [greeting, setGreeting] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    const access_token = localStorage.getItem("refreshToken");
    const data = { refreshToken: access_token };
    dispatch(logout(data, navigate));
  };

  const handleSearch = debounce((name) => {
    if (name) {
      dispatch(getSearchUser(name));
    }
  }, 400);

  useEffect(() => {
    handleSearch(query);
    return handleSearch.cancel;
  }, [query]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getNotification());
  }, []);

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) setGreeting("Good Morning");
      else if (currentHour < 18) setGreeting("Good Afternoon");
      else setGreeting("Good Evening");
    };

    updateGreeting();
    const interval = setInterval(updateGreeting, 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleClick = (data) => {
    navigate(`/report/viewreports/viewreportuser/${data._id}`);
    setQuery("");
  };
  return (
    <>
      <nav className="navbar navbar-expand-xl align-items-center top-bar">
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          {/* <div className="menubar d-flex align-items-center justify-content-flex "> */}
          <div className="menubar d-flex align-items-center justify-content-flex ">
            <img src={menu} alt="" onClick={funtype} />
          </div>

          <div className="text_head">
            <h4>
              {greeting}, {allProfile?.user?.name}
            </h4>
          </div>
          <div className="navbar-nav align-items-center justify-content-end bg-navbar-theme ms-auto search-bar">
            <div className="nav-item d-flex align-items-center justify-content-end gap-2 w-100">
              <img src={container} alt="" />
              <input
                type="text"
                className="border-0 shadow-none p-0"
                placeholder=" Quick Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {query && (
              <div
                className="search-results border  p-2 position-absolute"
                ref={dropdownRef}
              >
                {allusersearch?.length > 0 ? (
                  allusersearch.map((user) => (
                    <div
                      key={user._id}
                      className="search-result-item p-1"
                      onClick={() => handleClick(user)}
                    >
                      {user.name}
                    </div>
                  ))
                ) : (
                  <div className="text-muted search-result2">
                    No results found
                  </div>
                )}
              </div>
            )}
          </div>
          <span
            className="line_right
          "
          ></span>
          <ul className="navbar-nav flex-row align-items-center profile-bar">
            <li className="nav-item lh-1 bg-navbar-theme dropdown-notifications navbar-dropdown dropdown">
              <span className="position-relative">
                <img src={bellicon} alt="" onClick={onOpenModal} />
                <span className="badge rounded-pill bg-danger badge-dot badge-notifications border"></span>
              </span>
            </li>
            <li
              className="nav-item dropdown d-flex align-items-center"
              ref={dropdownRef}
            >
              <a
                className="nav-link"
                href="#"
                role="button"
                data-toggle="dropdown"
                onClick={toggleDropdown}
              >
                <img
                  src={profilePic}
                  alt="user"
                  className="rounded-circle"
                  style={{ width: "40px" }}
                />
              </a>
              <div className="admin_text">
                <p>{allProfile?.user?.name}</p>
                <p>{allProfile?.user?.email}</p>
              </div>
              <ul className="dropdown-menu dropdown-menu-end rounded-4">
                <li
                  className="dropdown-item"
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <Notification
        open={open}
        onClose={onCloseModal}
        getAllNotificationList={getAllNotificationList}
      />
    </>
  );
};

export default Navbar;
