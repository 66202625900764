import React, { useEffect, useState } from "react";
import link from "../../assets/img/link.png";
import profile from "../../assets/img/profile.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentforPosh,
  getPoshdata,
  getPoshDetaildata,
} from "../../redux/Action";
import moment from "moment";

const ViewPosh = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { allPoshDetailList, allposhList } = useSelector(
    (state) => state?.main
  );
  const [commentValue, setCommentValue] = useState();
  const [status, setStatus] = React.useState("");

  const pathname = location.pathname;

  const pathParts = pathname.split("/");

  const userId = pathParts[pathParts.length - 1];
  const statusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    { value: "In Progress", label: "In Progress" },
    { value: "Rejected", label: "Rejected" },
  ];

  useEffect(() => {
    dispatch(getPoshDetaildata(userId));
  }, [userId]);

  useEffect(() => {
    dispatch(getPoshdata());
  }, []);

  const commentChangeHandler = (e) => {
    setCommentValue({ ...commentValue, [e.target.name]: e.target.value });
  };

  const submitCommentHandler = () => {
    const data = {
      comment: commentValue?.comment,
      status: status,
    };
    dispatch(addCommentforPosh(data, userId, navigate, setCommentValue));
  };

  const handleStatusChange = (selectedStatus) => {
    setStatus(selectedStatus);
  };
  console.log(status, "status");

  const liStyle = {
    color: "#2C357D",
  };
  const parastyle = {
    color: "#000",
  };

  const Attendance = {
    records: [
      {
        status: "Ticket Number",
        reason: allPoshDetailList?.data?.ticketNo,
      },
      {
        status: "Store Manager",
        reason: allPoshDetailList?.data?.adminName,
      },
      {
        status: "Manager",
        reason: allPoshDetailList?.data?.adminName,
      },
      {
        status: "Shift Time",
        reason: allPoshDetailList?.data?.shiftName,
      },
    ],
  };

  return (
    <div className="content-wrapper">
      <div className="wrap">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="logo_texts">
            <div className="logo_icons">
              {allPoshDetailList?.data ? (
                <img
                  src={allPoshDetailList.data.profileImage}
                  alt="Profile"
                  width={100}
                  height={100}
                  style={{ objectFit: "cover", borderRadius: "50px" }}
                />
              ) : (
                <p>No image available</p>
              )}{" "}
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start ">
              <div className="des_texts d-flex  justify-content-start gap-3 pr-4">
                <h5> {allPoshDetailList?.data?.adminName}</h5>

                <p style={liStyle}>
                  {allPoshDetailList?.data?.date
                    ? moment(allPoshDetailList.data.date).format(
                        "DD MMMM YYYY : h:mm A"
                      )
                    : "No date available"}
                </p>
              </div>
              <div className="d-flex justify-content-start gap-2">
                <span
                  style={liStyle}
                  className="d-flex lign-item-start justify-content-start gap-2"
                >
                  to:{" "}
                  <p style={parastyle}>
                    {allPoshDetailList?.data?.employee_email}
                  </p>{" "}
                </span>
                {allPoshDetailList?.data?.adminEmail && (
                  <span
                    style={liStyle}
                    className="d-flex lign-item-start justify-content-start gap-2"
                  >
                    cc:{" "}
                    <p style={parastyle}>
                      {allPoshDetailList?.data?.adminEmail}
                    </p>
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex align-item-center justify-content-center gap-3 mobileSecView">
            {Attendance?.records?.map((record, index) => (
              <div className="cardmornings">
                <div className="right_reqs">
                  <h4>{record?.status}</h4>
                  <div className="min_flex_reqs">
                    <h1>{record?.reason}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="para_lorems">
            <div className="head_viewss">
              <div className="application_pins">
                <h6>Posh Application</h6>
                <img src={link} alt="" />
              </div>
            </div>
            <div className="lorem_texts">
              <p>{allPoshDetailList?.data?.description}</p>
            </div>
          </div>
          <div className="statusdrop">
            <h6>Status</h6>
            {/* {console.log(allposhList, "allposhList")}{" "} */}
            <select
              className="status-selector"
              onChange={(e) => handleStatusChange(e.target.value)}
              defaultValue={allposhList?.data?.poshes?.[0]?.status || ""}
            >
              <option hidden>Select Status</option>
              {statusOptions.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="btn_accept">
            <div className="accept_1">
              <button>Accept</button>
            </div>
            <div className="reject_1">
              <button>Reject</button>
            </div>
          </div> */}
          <div className="add_textarea">
            <div className="heading_add">
              <h3>Add Comment</h3>
            </div>
            <textarea
              placeholder="Describe yourself here..."
              onChange={commentChangeHandler}
              name="comment"
            ></textarea>
          </div>
          <div className="submit_buttons">
            <button onClick={() => submitCommentHandler()}>Submit</button>
          </div>
          <div className="para_loremss">
            <div className="head_views">
              <div className="application_pins">
                <h6>History of comments</h6>
              </div>
            </div>
            <div className="lorem_textss border-bottom-0 pt-2 ">
              {allPoshDetailList?.data?.comments?.map((data) => (
                <p>{data}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPosh;
