import React, { useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import "./rightmodal.css";

const RightCustomModal = ({
  children,
  isOpen,
  onClose,
  width,
  shouldCloseOnOutsideClick,
}) => {
  const modalRef = useRef(null); // Ref for the modal DOM element
  const modalInstanceRef = useRef(null); // Ref for the modal instance

  useEffect(() => {
    // Ensure the modal DOM element is mounted before initializing
    if (modalRef.current) {
      if (!modalInstanceRef.current) {
        modalInstanceRef.current = new Modal(modalRef.current, {
          backdrop: shouldCloseOnOutsideClick ? true : "static", // This ensures the modal closes when clicking outside
        });

        modalRef.current.addEventListener("hidden.bs.modal", () => {
          modalInstanceRef.current?.dispose();
          modalInstanceRef.current = null;
        });
      }

      // Show or hide modal based on `isOpen`
      if (isOpen && modalInstanceRef.current) {
        modalInstanceRef.current.show();
      } else if (!isOpen && modalInstanceRef.current) {
        modalInstanceRef.current.hide();
      }
    }

    // Event listener to close modal on Esc key press
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    // Event listener to close modal when clicking outside the modal content area
    const handleOutsideClick = (e) => {
      if (
        shouldCloseOnOutsideClick &&
        e.target === modalRef.current // Click on backdrop area, not modal content
      ) {
        onClose();
      }
    };

    // Add keydown event listener for Esc key
    document.addEventListener("keydown", handleEscKey);

    // Add click event listener for outside click
    modalRef.current?.addEventListener("click", handleOutsideClick);

    // Clean up event listeners and modal instance
    return () => {
      document.removeEventListener("keydown", handleEscKey);
      modalRef.current?.removeEventListener("click", handleOutsideClick);

      if (modalInstanceRef.current) {
        modalInstanceRef.current.dispose();
        modalInstanceRef.current = null;
      }
    };
  }, [isOpen, shouldCloseOnOutsideClick, onClose]);

  return (
    <div className="modal w-100" tabIndex="-1" ref={modalRef}>
      <div className="modal-dialog">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default RightCustomModal;
