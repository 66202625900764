import React, { useEffect, useRef } from "react";
import Select, { components } from "react-select"; // Import react-select
import "./selector.css"; // Import your CSS
import dropnewicon from "../../../assets/img/dropnewicon.svg";

const Selector = ({
  label,
  options,
  name,
  value,
  onChange,
  LabelFontSize,
  SelectorFontSize,
  labelColor = "#2C357D80",
  starColor = "red",
  showStar,
  error,
  width,
  borderColor,
  disabled,
  half,
}) => {
  const wrapperRef = useRef(null);

  const handleClearSelection = (e) => {
    e.stopPropagation();
    onChange({ target: { name, value: "" } });
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      padding: "8px",
      fontSize: SelectorFontSize,
      borderBottom: `1px solid ${borderColor}`,
      cursor: disabled ? "not-allowed" : "pointer",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#2c357d",
      borderRadius: "0px", // Optional, remove border radius for sharper corners
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: SelectorFontSize,
      color: "#2C357D80", // Matching the placeholder style
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: SelectorFontSize,
      color: "#2C357D",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: disabled ? "#ccc" : "#2C357D",
      transition: "transform 0.3s", // Match your dropdown icon transition
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "none",
    }),

    option: (base, state) => ({
      ...base,
      padding: "8px",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#e3f2fd" : "white", // Hover color
      color: state.isFocused ? "#2c357d" : "#2c357d", // Text color on hover
    }),
  };

  const handleChange = (selectedOption) => {
    onChange({ target: { name, value: selectedOption } });
  };

  return (
    <div
      className="selector_wrapper"
      style={{ width: width || "100%" }}
      ref={wrapperRef}
    >
      <label
        className="selector_label"
        style={{ fontSize: LabelFontSize, color: labelColor }}
      >
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <div className="custom_selector" style={{ fontSize: SelectorFontSize }}>
        <Select
          name={name}
          value={options?.find((option) => option.value === value)}
          onChange={handleChange}
          options={options}
          isDisabled={disabled}
          styles={customStyles} // Apply custom styles to react-select
          placeholder="Select an option"
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                {value && (
                  <svg
                    width="25"
                    height="30"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleClearSelection}
                  >
                    <rect
                      width="60"
                      height="60"
                      rx="30"
                      fill="#2C357D"
                      fillOpacity="0.15"
                    />
                    <path
                      d="M37.75 22.1377L21.25 38.6377"
                      stroke="#2C357D"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.25 22.1377L37.75 38.6377"
                      stroke="#2C357D"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {!disabled && (
                  <img
                    src={dropnewicon}
                    alt="Dropdown Icon"
                    className="dropdown_icon"
                  />
                )}
              </components.DropdownIndicator>
            ),
          }}
        />
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Selector;
