import React, { useState, useEffect, useRef } from "react";
import "./multiselector.css";
import dropnewicon from "../../../assets/img/dropnewicon.svg";

const MultiSelector = ({
  label,
  options,
  name,
  value,
  onChange,
  LabelFontSize,
  SelectorFontSize,
  labelColor = "#2C357D80",
  starColor = "red",
  showStar,
  error,
  width,
  borderColor,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (optionValue) => {
    const selectedValues = Array.isArray(value) ? value : [];
    const newValue = selectedValues.includes(optionValue)
      ? selectedValues.filter((val) => val !== optionValue) // Uncheck
      : [...selectedValues, optionValue]; // Check

    onChange({ target: { name, value: newValue } });
  };

  const labelStyle = {
    fontSize: LabelFontSize,
    color: labelColor,
  };

  const selectStyle = {
    fontSize: SelectorFontSize,
    border: `1px solid ${borderColor}`,
  };

  const selectedLabels =
    options
      ?.filter((option) => Array.isArray(value) && value.includes(option.value))
      ?.map((option) => option.label)
      ?.join(", ") || "Select options";

  return (
    <div
      className="selector_wrappers"
      style={{ width: width || "100%" }}
      ref={wrapperRef}
    >
      <label className="selector_labels" style={labelStyle}>
        {label} {showStar && <span style={{ color: starColor }}> *</span>}
      </label>
      <div
        className="custom_selectors"
        style={selectStyle}
        onClick={handleToggleDropdown}
      >
        {selectedLabels}
        <img
          src={dropnewicon}
          alt="dropdown icon"
          className={`dropdown_icons ${isOpen ? "open" : ""}`}
        />
      </div>
      {isOpen && (
        <div className="custom_dropdowns">
          {options?.map((option) => (
            <div
              key={option.value}
              className="custom_options"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                justifyContent: "space-between ",
              }}
            >
              <input
                type="checkbox"
                checked={Array.isArray(value) && value.includes(option.value)}
                onChange={() => handleOptionClick(option.value)}
              />
              <label onClick={() => handleOptionClick(option.value)}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default MultiSelector;
