import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "./progressbarTimer.css";

const ProgressbarTimer = ({ userFullAttendenceData }) => {
  const [percentage, setPercentage] = useState(0);
  const [formattedTime, setFormattedTime] = useState("0h 0m");

  useEffect(() => {
    // Extract working hours
    const workingHours =
      parseFloat(
        userFullAttendenceData?.todayAttendance?.working_hour || "0"
      ) || 0;

    const totalWorkingHours =  parseFloat(
      userFullAttendenceData?.todayAttendance?.dailyTotalWorkingHours || "0"
    ) || 0; // Set total working hours for 100%
    const calculatedPercentage = (workingHours / totalWorkingHours) * 100;

    // Convert workingHours into hours and minutes
    const hours = Math.floor(workingHours);
    const minutes = Math.round((workingHours - hours) * 60);
    const formatted = `${hours}h ${minutes}m`;

    setFormattedTime(formatted);

    // Animate progress bar
    let progress = 0;
    const interval = setInterval(() => {
      if (progress < calculatedPercentage) {
        progress += 1;
        setPercentage(progress);
      } else {
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  }, [userFullAttendenceData]);

  return (
    <div className="progress-container">
      <div className="progress-bor">
        <div className="progress-circular">
          <CircularProgressbar
            value={percentage}
            text=""
            styles={buildStyles({
              pathColor: "#000080",
              trailColor: "transparent",
            })}
          />
        </div>
      </div>

      {/* Display formatted time */}
      <div className="progress-text">{formattedTime}</div>
    </div>
  );
};

export default ProgressbarTimer;
