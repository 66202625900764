import React, { useState, useEffect } from "react";
import "./pagination.css"; // Importing CSS

const Pagination = ({ listData, setPageData }) => {
  const [current_page, setCurrentPage] = useState(listData?.current_page || 1);
  useEffect(() => {
    setCurrentPage(listData?.current_page || 1);
  }, [listData]);

  const nextPage = () => {
    if (current_page < listData?.total_page) {
      setPageData(current_page + 1);
    }
  };

  const prevPage = () => {
    if (current_page > 1) {
      setPageData(current_page - 1);
    }
  };

  const changePages = (page) => {
    setPageData(page);
  };

  const renderPageNumbers = () => {
    const totalPages = listData?.total_page || 0;
    const pageNumbers = [];

    if (totalPages <= 5) {
      // Show all pages if total pages <= 5
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item ${current_page === i ? "active" : ""}`}
            onClick={() => changePages(i)}
          >
            {console.log(current_page, i, "jjj")}
            <span className="page-link">{i}</span>
          </li>
        );
      }
    } else {
      // Always show the first two pages
      pageNumbers.push(
        <li
          key={1}
          className={`page-item ${current_page === 1 ? "active" : ""}`}
          onClick={() => changePages(1)}
        >
          <span className="page-link">1</span>
        </li>
      );

      pageNumbers.push(
        <li
          key={2}
          className={`page-item ${current_page === 2 ? "active" : ""}`}
          onClick={() => changePages(2)}
        >
          <span className="page-link">2</span>
        </li>
      );

      // Show dots if current_page > 3
      if (current_page > 3) {
        pageNumbers.push(
          <li key="dots-prev" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      // Show sliding window of pages around the current page
      for (
        let i = Math.max(3, current_page - 1);
        i <= Math.min(totalPages - 2, current_page + 1);
        i++
      ) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item ${current_page === i ? "active" : ""}`}
            onClick={() => changePages(i)}
          >
            <span className="page-link">{i}</span>
          </li>
        );
      }

      // Show dots if current_page is not close to the end
      if (current_page < totalPages - 2) {
        pageNumbers.push(
          <li key="dots-next" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      // Always show the last two pages
      pageNumbers.push(
        <li
          key={totalPages - 1}
          className={`page-item ${
            current_page === totalPages - 1 ? "active" : ""
          }`}
          onClick={() => changePages(totalPages - 1)}
        >
          <span className="page-link">{totalPages - 1}</span>
        </li>
      );

      pageNumbers.push(
        <li
          key={totalPages}
          className={`page-item ${current_page === totalPages ? "active" : ""}`}
          onClick={() => changePages(totalPages)}
        >
          <span className="page-link">{totalPages}</span>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      {listData?.total_page > 1 && (
        <>
          <ul className="pagination_number">
            {/* Previous Button */}
            {current_page > 1 && (
              <li className="page-item" onClick={prevPage}>
                <span className="page-link" aria-hidden="true">
                  ‹
                </span>
              </li>
            )}
            {/* Render Page Numbers */}
            {renderPageNumbers()}
            {/* Next Button */}
            {current_page < listData?.total_page && (
              <li className="page-item" onClick={nextPage}>
                <span className="page-link" aria-label="Next »">
                  ›
                </span>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Pagination;
