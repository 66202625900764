import React, { useEffect, useState } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddRole from "../AddNewRole/AddRole"; // Import AddRole component
import { getSettingRole, roledeleteData } from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";
import Pagination from "../../Common/Pagination/Pagination";

const Role = () => {
  const dispatch = useDispatch();
  const { allRoleList, deleteRolelist, addRoleList, updateRole } = useSelector(
    (state) => state.main
  );
  const pageLoading = useSelector((state) => state.main.loading);

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [updatedData, setUpdatedData] = useState({});
  const [status, setstatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getSettingRole(currentPage));
  }, [deleteRolelist, addRoleList, updateRole, currentPage]);

  const fields = [
    { label: "Role Name", id: "name" },
    { label: "Role Description", id: "description" },
    { label: "Status", id: "status" }, // Add status field
  ];

  const handleClose = () => {
    setOpenInviteMember(false);
  };

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };

  const handleDelete = () => {
    dispatch(roledeleteData(selectedUserId?._id));
    onCloseModal();
  };

  const openAddCompany = (data) => {
    setOpenInviteMember(true);
    setstatus(data);
  };

  const OnEditClickHandler = (data, key) => {
    setSelectedUserId(data?._id);
    setOpenInviteMember(true);
    setUpdatedData(data);
    setstatus(key);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Role</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => openAddCompany("add")}>Add Role</button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ borderBottom: " 1px solid #2C357D2E" }}>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          // style={
                          //   field.id === "roleName"
                          //     ? { borderLeft: "none" }
                          //     : {  textAlign: "left" }
                          // }
                          style={{
                            borderLeft: "none",
                            textAlign:
                              field.id === "status" ? "center" : "left",
                          }}
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ width: "10%", borderRight: "none" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRoleList?.data.map((Role, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td
                            key={field.id}
                            style={{
                              textAlign:
                                field.id === "status" ? "center" : "left",
                            }}
                          >
                            {field.id === "status" ? (
                              <span
                                style={{
                                  backgroundColor:
                                    Role[field.id] === true
                                      ? "#a2eddb40"
                                      : Role[field.id] === false
                                      ? "#ff323240"
                                      : "transparent",
                                  color:
                                    Role[field.id] === true
                                      ? "#24e502"
                                      : Role[field.id] === false
                                      ? "#ff3232"
                                      : "inherit",
                                  padding: "5px 15px",
                                  borderRadius: "8px",
                                  fontWeight: "300",
                                  display: "inline-block",
                                }}
                              >
                                {Role[field.id] === true
                                  ? "Active"
                                  : Role[field.id] === false
                                  ? "Inactive"
                                  : "-"}
                              </span>
                            ) : (
                              Role[field.id] || "-"
                            )}
                          </td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button
                              onClick={() => OnEditClickHandler(Role, "edit")}
                            >
                              Edit
                            </button>
                            {/* Uncomment this if delete is needed */}
                            {/* <button onClick={() => onOpenModal(Role)}>
                              Delete
                            </button> */}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
      )}
      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddRole
            onClose={handleClose}
            updatedData={updatedData}
            status={status}
          />
        </RightCustomModal>
      )}
      <Pagination listData={allRoleList?.meta} setPageData={setCurrentPage} />
    </>
  );
};

export default Role;
