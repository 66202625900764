import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import "../../../Common/Form/form.css";
import InputField from "../../../components/common/inputfield/InputField.jsx";
import Selector from "../../../components/common/selects/Selector.jsx";
import Checkbox from "../../../components/common/checkbox/checkbox.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import camera from "../../../assets/img/camera.png";
import banknamejson from "../../../assets/bankname.json";
import InputLogo from "../../../components/common/customlogo/InputLogo.js";
import {
  addUsers,
  getReportSingleUserdata,
  getUsers,
  getUsersAll,
} from "../../../redux/Action/index.js";
import Rectangle from "../../../assets/img/Rectangle.png";
import { calcLength } from "framer-motion";
import CustomSelector from "../../../components/common/customselector/CustomSelector.jsx";
import moment from "moment";
import { toast } from "react-toastify";

const ViewUser = ({ addFormCompleted, formType, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { allReportSingleUser, allUserList } = useSelector(
    (state) => state?.main
  );

  const banknameoptions = banknamejson;

  useEffect(() => {
    dispatch(getUsersAll());
  }, []);

  const [selectedValue, setSelectedValue] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [updateForm, setUpdateForm] = useState();
  const [activeTab, setActiveTab] = useState("Employee Information");

  const pathname = location.pathname;

  const pathParts = pathname.split("/");

  const userId = pathParts[pathParts.length - 1];

  // const assignedToUsers = allReportSingleUser?.assignedToUsers || [];
  const [checkedItems, setCheckedItems] = useState({
    allowEpf: false,
    css: false,
    javascript: false,
  });
  // const userOptions = assignedToUsers.map((user) => ({
  //   label: user.name,
  //   value: user._id,
  // }));

  const findValueById = (id, array, key) => {
    const item = array.find((element) => element._id === id);
    console.log(item);

    return item ? item[key] : null;
  };
  const isMobile = localStorage.getItem("view");

  const tabs = [
    {
      name: "Employee Information",
      fields: [
        { label: "Employee Number", type: "tel", name: "employeenumber" },
        { label: "Title", type: "text", name: "title" },
        { label: "Employee Name", type: "text", name: "empl_name" },
        { label: "Employee Nick Name", type: "text", name: "nick_name" },
      ],
    },

    {
      name: "Joining Details",
      fields: [
        { label: "Joining Date", name: "joining_date", type: "date" },
        { label: "Position", name: "position", type: "text" },
        { label: "Department", name: "department", type: "text" },
      ],
    },
    {
      name: "Current Position",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Documents",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Employee Identity",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Education",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Address",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Emergency Contact",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },

    {
      name: "Bank Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "ESI Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "PF Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
  ];

  const convertTo12HourIST = (utcDateTime) => {
    if (utcDateTime) {
      const istDateTime = moment.utc(utcDateTime).tz("Asia/Kolkata");
      const formattedTime = istDateTime.format("h:mm A"); // 12-hour format with AM/PM
      return formattedTime ? formattedTime : "-";
    }
    return "-";
  };
  const documentItems = [
    {
      name: "aadharCard",
      label: "Aadhar Card",
      img: updateForm?.aadharCardDocument,
    },
    { name: "panCard", label: "Pan Card", img: updateForm?.panCardDocument },
    {
      name: "passBook",
      label: "Pass Book / Cancelled Cheque",
      img: updateForm?.passBookOrCancelledCheque,
    },
    {
      name: "relievingLetter",
      label: "Previous Org Relieving Letter",
      img: updateForm?.prevOrgRelivingLetter,
    },
    {
      name: "paySlip",
      label: "Previous Org Pay Slip",
      img: updateForm?.prevOrgPayslip,
    },
  ];

  useEffect(() => {
    dispatch(getReportSingleUserdata(userId));
  }, [userId]);
  useEffect(() => {
    if (userId && allReportSingleUser) {
      if (allReportSingleUser._id === userId) {
        setUpdateForm(allReportSingleUser);
      } else {
        setUpdateForm({});
      }
    } else {
      setUpdateForm({});
    }
  }, [userId, allReportSingleUser]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [id]: checked,
    }));
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleNext = () => {
    const currentIndex = tabs?.findIndex((tab) => tab.name === activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1].name);
    }
  };

  const assignedToUsers = allUserList?.data?.assignedToUsers || [];
  const referredUsers = allUserList?.data?.referredUsers || [];
  const storeList = allUserList?.data?.stores || [];
  const companiesList = allUserList?.data?.companies || [];
  const roleList = allUserList?.data?.roles || [];
  const ShiftList = allUserList?.data?.shift || [];

  console.log({ updateForm });

  return (
    <>
      <div className="container mx-auto mt-4 mb-4 ">
        <Formik
          initialValues={{
            // email: "",
            employeenumber: updateForm?.emp_id || "",
            designation:
              findValueById(updateForm?.designation, roleList, "name") || "",
            marital: updateForm?.maritalStatus || "",
            roles: updateForm?.role || "",
            assignedto:
              findValueById(updateForm?.assignedTo, assignedToUsers, "name") ||
              "",
            nickName: updateForm?.nickName || "",
            gender: updateForm?.gender || "",
            nationality: updateForm?.nationality || "",
            residentialstatus: updateForm?.residentialStatus || "",
            religion: updateForm?.religion || "",
            attendanceScheme: updateForm?.attendanceScheme || "",
            reportingto:
              findValueById(updateForm?.reportingTo, referredUsers, "name") ||
              "",
            bloodgroup: updateForm?.bloodGroup || "",
            mobilenumber: updateForm?.phone || "",
            empl_name: updateForm?.name,
            nick_name: updateForm?.nickName || "",
            birthday: updateForm?.dob
              ? moment(updateForm.dob).format("YYYY/MM/DD")
              : "",
            marriage_date: updateForm?.marriageDate
              ? moment(updateForm.marriageDate).format("YYYY/MM/DD")
              : "",

            spouse_name: updateForm?.spouseName || "",
            presentcity: updateForm?.presentCity || "",
            presentstate: updateForm?.presentState || "",
            presentcountry: updateForm?.presentCountry || "",
            presentpincode: updateForm?.presentPincode || "",
            aadharnumber: updateForm?.aadharNumber || "",
            bankbranch: updateForm?.bankBranch || "",
            bankaccountnumber: updateForm?.accountNumber || "",
            iFSC: updateForm?.ifscCode || "",
            agency_name: updateForm?.agencyName || "",
            verificationcompleted: updateForm?.verifcationCompletedOn || "",
            pf_join: updateForm?.employeenumber || "",
            esinumber: updateForm?.esiNumber || "",
            uan: updateForm?.uanNumber || "",
            family_pf_number: updateForm?.familyPFNumber || "",
            documenttype: updateForm?.documentType || "",
            loginusername: updateForm?.loginUserName || "",
            employee_email: updateForm?.email || "",
            Place_birth: updateForm?.placeOfBirth || "",
            empl_father: updateForm?.fatherName || "",

            joining_on: updateForm?.joiningOn
              ? moment(updateForm.joiningOn).format("YYYY/MM/DD")
              : "",
            confirmation_date: updateForm?.confirmationDate
              ? moment(updateForm.confirmationDate).format("YYYY/MM/DD")
              : "",
            status: updateForm?.status || "",
            confirmed: updateForm?.confirmed || "",
            previous_experience: updateForm?.previousExperience || "",
            experience: updateForm?.currentCompanyExperience || "",
            notice_period: updateForm?.noticePeriod || "",
            probation_period: updateForm?.probationPeriod || "",
            totalExperience: updateForm?.totalExperience || "",
            referredby:
              findValueById(updateForm?.referredBy, referredUsers, "name") ||
              "",
            costcenter:
              findValueById(
                updateForm?.costCenter,
                companiesList,
                "company_name"
              ) || "",
            shift: updateForm?.shift?.[0]?.name || "",
            staff:
              findValueById(updateForm?.staff, referredUsers, "name") || "",
            storename:
              findValueById(updateForm?.storeName, storeList, "name") || "",
            location:
              findValueById(updateForm?.location, storeList, "address") || "",
            facerecognition: updateForm?.faceRecognition || "",
            presentaddress1: updateForm?.presentAddress1 || "",
            presentaddress2: updateForm?.presentAddress2 || "",
            presentaddress3: updateForm?.presentAddress3 || "",
            Phone1: updateForm?.presentPhone1 || "",
            phone2: updateForm?.presentPhone2 || "",
            presentext: updateForm?.presentExt || "",
            presentemail: updateForm?.presentEmail || "",
            presentmobile: updateForm?.presentMobile || "",
            presentfax: updateForm?.presentFax || "",
            permanentaddress1: updateForm?.permanentAddress1 || "",
            permanentaddress2: updateForm?.permanentAddress2 || "",
            permanentaddress3: updateForm?.permanentAddress3 || "",
            permanentcity: updateForm?.permanentCity || "",
            permanentstate: updateForm?.permanentState || "",
            permanentcountry: updateForm?.permanentCountry || "",
            permanentpincode: updateForm?.permanentPincode || "",
            permanentphone1: updateForm?.permanentPhone1 || "",
            parmanentphone2: updateForm?.permanentPhone2 || "",
            parmanentemail: updateForm?.permanentEmail || "",
            parmanentmobile: updateForm?.permanentMobile || "",
            parmanentfax: updateForm?.parmanentFax || "",
            parmanentext: updateForm?.permanentExt || "",
            vrificationstatus: updateForm?.verificationStatus || "",
            bankname: updateForm?.bankName || "",
            iban: updateForm?.iban_swift_bic_code || "",
            accounttype: updateForm?.accountType || "",
            paymenttype: updateForm?.paymentType || "",
            bank_records: updateForm?.nameAsPerBank || "",
            dd_Payable: updateForm?.ddPayableAt || "",
            pf_num: updateForm?.pfNumber || "",
            // company2: updateForm?.employeenumber || "",
            company1:
              findValueById(
                updateForm?.company,
                companiesList,
                "company_name"
              ) || "",
            nameasperaadhar: updateForm?.nameAsPerAadhar || "",
            aadhaarenrolment: updateForm?.aadharEnrolmentNo || "",
            permanentaccountnumber: updateForm?.permanentAccountNumber || "",
            nameasperpan: updateForm?.nameAsPerPan || "",
            qualification: updateForm?.eduQualification || "",
            institutename: updateForm?.eduInstituteName || "",
            grade: updateForm?.eduGrade || "",
            nameemergency: updateForm?.Nameemergency || "",
            relationship: updateForm?.emerRelation || "",
            emeraddress1: updateForm?.emeraddress1 || "",
            emeraddress2: updateForm?.emerAddress2 || "",
            emeraddress3: updateForm?.emerAddress3 || "",
            emercity: updateForm?.emerCity || "",
            emerpincode: updateForm?.emerPincode || "",
            emerstate: updateForm?.emerState || "",
            emerfax: updateForm?.emerFax || "",
            emercountry: updateForm?.emerCountry || "",
            emeremail: updateForm?.emerEmail || "",
            emermobile: updateForm?.emerMobile || "",
            emertext: updateForm?.emerExt || "",
            emerphone2: updateForm?.emerPhone2 || "",
            emerphone1: updateForm?.emerPhone1 || "",
            accountnumber: updateForm?.emerMobile || "",
            empolyeedocumenttype: updateForm?.pfDocumentType || "",
            empolyeebankname: updateForm?.empIdentBankName || "",
            nameinbankaccount: updateForm?.empIdenNameInBank || "",
            bankifsc: updateForm?.empIdentIfsc || null,
            image: updateForm?.profileImage || null,
            aadharCard: updateForm?.aadharCardDocument || null,
            panCard: updateForm?.panCardDocument || null,
            passBook: updateForm?.passBookOrCancelledCheque || null,
            relievingLetter: updateForm?.prevOrgRelivingLetter || null,
            paySlip: updateForm?.prevOrgPayslip || null,
          }}
          onSubmit={(values, { setSubmitting }) => {
            const userData = new FormData();
          }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,

            /* and other goodies */
          }) => {
            return (
              <>
                <div className="top_bottomEmployees">
                  <h4> Employee Detail </h4>
                </div>
                <div className="row d-flex  justify-content-start  pt-3  gap-3">
                  <div className="col-12">
                    <div
                      className="card"
                      style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
                    >
                      <div className="card-body ">
                        <div className="card_flex">
                          <div className="flex_img_text">
                            <div class="upload-section">
                              <div class="upload-container">
                                <input type="file" accept="image/*" />
                                <span class="icon">
                                  <img
                                    src={updateForm?.profileImage}
                                    alt="No image available"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="text_name  pt-3">
                              <h6>
                                {updateForm?.title &&
                                  updateForm.title.charAt(0).toUpperCase() +
                                    updateForm.title.slice(1)}{" "}
                                &nbsp;
                                {updateForm?.name}
                              </h6>
                              <p>{updateForm?.name}</p>
                              <p>{updateForm?.designationName?.name}</p>
                            </div>
                          </div>
                          <div className="border_dash_right"></div>
                          <div className="detail_wrap">
                            <div className="pad_wrap">
                              <p>Phone:</p>
                              <p>+91 {updateForm?.phone}</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Email:</p>
                              <p>{updateForm?.email}</p>
                            </div>
                            {/* <div className="pad_wrap">
                              <p>Employee Nick Name:</p>
                              <p> {updateForm?.nickName}</p>
                            </div> */}
                            <div className="pad_wrap">
                              <p>Gender:</p>
                              <p>{updateForm?.gender}</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Report to:</p>
                              <p>{updateForm?.reportToName?.name}</p>
                            </div>
                          </div>
                          <div className="border_dash_right"></div>
                          <div className="detail_wrap">
                            <div className="pad_wrap">
                              <p>Check In:</p>
                              <p>
                                {convertTo12HourIST(
                                  updateForm?.todayAttendance?.check_in_time
                                )}
                              </p>
                            </div>
                            <div className="pad_wrap">
                              <p>Check Out:</p>
                              <p>
                                {" "}
                                {convertTo12HourIST(
                                  updateForm?.todayAttendance?.check_out_time
                                )}
                              </p>
                            </div>
                            <div className="pad_wrap">
                              <p>Total Working Hours:</p>
                              <p>
                                {updateForm?.todayAttendance?.working_hour
                                  ? updateForm?.todayAttendance?.working_hour
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div
                      className="card"
                      style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
                    >
                      <div className="card-body">
                        <div className="d-flex justify-content-start  align-item-start">
                          {" "}
                          <div
                            className={`tab d-flex gap-3 ${
                              isMobile == "true"
                                ? "flex-column w-100 overflow-scroll justify-content-start"
                                : "flex"
                            } `}
                            style={{ height: "110px" }}
                          >
                            {tabs.map((tab) => (
                              <button
                                key={tab.name}
                                className={`tab_button ${
                                  activeTab === tab.name ? "active" : ""
                                }${isMobile && "p-0"}`}
                                onClick={() => handleTabClick(tab.name)}
                              >
                                {tab.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
                  {activeTab === "Employee Information" && (
                    <div className="info_btm">
                      <p>Employee Information</p>
                    </div>
                  )}
                  {activeTab === "Employee Information" && (
                    <div className="upload-section">
                      <div className="upload-container">
                        {values?.image ? (
                          <img
                            src={values.image}
                            alt="Profile"
                            width={100}
                            height={100}
                          />
                        ) : (
                          <p>No image available</p>
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === "Employee Information" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col " key={field.name}>
                              <InputField
                                label="Employee Number"
                                inputType="tel"
                                inputPlaceholder="Enter Employee Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="employeenumber"
                                value={values?.employeenumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {/* {errors.employeenumber &&
                                    touched.employeenumber && (
                                      <div className="error-message">
                                        {errors.employeenumber}
                                      </div>
                                    )} */}
                            </div>
                            <div className="row_col" key={field.name}>
                              <InputField
                                label="Title"
                                inputType="tel"
                                inputPlaceholder="Enter Title"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nickName"
                                value={values?.nickName}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col" key={field.name}>
                              <InputField
                                label=" Employee Name"
                                inputType="text"
                                inputPlaceholder="Enter Employee Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empl_name"
                                value={values?.empl_name}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col" key={field.name}>
                              <InputField
                                label="Employee Nick Name"
                                inputType="text"
                                inputPlaceholder="Enter Employee Nick Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nick_name"
                                value={values?.nick_name}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Gender"
                                inputType="text"
                                inputPlaceholder="Enter Gender"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="gender"
                                value={values?.gender}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Employee Login Username"
                                inputType="text"
                                inputPlaceholder="Enter Employee Login Username"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="loginusername"
                                value={values?.loginusername}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Mobile Number"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="mobilenumber"
                                value={values?.mobilenumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Employee Email"
                                inputType="email"
                                inputPlaceholder="Enter Employee Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="employee_email"
                                value={values?.employee_email}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Employee Information" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Date of Birth"
                                inputType="date"
                                inputPlaceholder="DD/MM/YYYY"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="birthday"
                                value={moment(
                                  values?.birthday,
                                  "YYYY/MM/DD"
                                ).format("YYYY-MM-DD")}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Blood Group"
                                inputType="text"
                                inputPlaceholder="Enter Blood Group"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bloodgroup"
                                value={values?.bloodgroup}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Father’s Name"
                                inputType="text"
                                inputPlaceholder="Enter Father’s Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empl_father"
                                value={values?.empl_father}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Marital Status"
                                inputType="text"
                                inputPlaceholder="Enter Marital Status"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="marital"
                                value={values?.marital}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            {values?.marital !== "single" && (
                              <>
                                <div className="row_col">
                                  <InputField
                                    label="Marriage Date"
                                    inputType="date"
                                    inputPlaceholder="DD/MM/YYYY"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="marriage_date"
                                    value={moment(
                                      values?.marriage_date,
                                      "YYYY/MM/DD"
                                    ).format("YYYY-MM-DD")}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>

                                <div className="row_col">
                                  <InputField
                                    label="Spouse Name"
                                    inputType="text"
                                    inputPlaceholder="Enter Spouse Name"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="spouse_name"
                                    value={values?.spouse_name}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>

                                <div className="row_col">
                                  <InputField
                                    label="Nationality"
                                    inputType="text"
                                    inputPlaceholder="Enter Nationality"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="nationality"
                                    value={values?.nationality}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                                <div className="row_col">
                                  <InputField
                                    label="Residential Status"
                                    inputType="text"
                                    inputPlaceholder="Enter Residential Status"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="residentialstatus"
                                    value={values?.residentialstatus}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="flex_input">
                            {values?.marital !== "married" ? (
                              <>
                                <div className="row_col custom-width-50">
                                  <InputField
                                    label="Place of Birth"
                                    inputType="text"
                                    inputPlaceholder="Enter Place of Birth"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="Place_birth"
                                    value={values?.Place_birth}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                                <div className="row_col  custom-width-50 ">
                                  <InputField
                                    label="Religion"
                                    inputType="text"
                                    inputPlaceholder="Enter Religion"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="religion"
                                    value={values?.religion}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                {/* <div className="row_col">
                                      <Selector
                                        label="Nationality"
                                        options={nationalityOption}
                                        name="nationality"
                                        value={values?.nationality}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "nationality",
                                            e.target.value?.value
                                          )
                                        }
                                        LabelFontSize="16px"
                                        SelectorFontSize="16"
                                      />
                                      {errors.nationality &&
                                        touched.nationality && (
                                          <div className="error-message">
                                            {errors.nationality}
                                          </div>
                                        )}
                                    </div>
                                    <div className="row_col">
                                      <Selector
                                        label="Residential Status"
                                        options={residentialstatusoptions}
                                        name="residentialstatus"
                                        value={values?.residentialstatus}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "residentialstatus",
                                            e.target.value?.value
                                          )
                                        }
                                        LabelFontSize="16px"
                                        SelectorFontSize="16"
                                      />
                                      {errors.residentialstatus &&
                                        touched.residentialstatus && (
                                          <div className="error-message">
                                            {errors.residentialstatus}
                                          </div>
                                        )}
                                    </div> */}
                                <div className="row_col custom-width-50 ">
                                  <InputField
                                    label="Place of Birth"
                                    inputType="text"
                                    inputPlaceholder="Enter Place of Birth"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="Place_birth"
                                    value={values?.Place_birth}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                                <div className="row_col  custom-width-50  ">
                                  <InputField
                                    label="Religion"
                                    inputType="text"
                                    inputPlaceholder="Enter Religion"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="religion"
                                    value={values?.religion}
                                    onChange={handleChange}
                                    disabled={true}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ))}
                  {activeTab === "Joining Details" && (
                    <div className="info_btm">
                      <p>Joining Details</p>
                    </div>
                  )}
                  {activeTab === "Joining Details" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Joining On"
                                inputType="date"
                                inputPlaceholder="Enter Joining On"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="joining_on"
                                value={moment(
                                  values?.joining_on,
                                  "YYYY/MM/DD"
                                ).format("YYYY-MM-DD")}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Confirmation Date"
                                inputType="date"
                                inputPlaceholder="Enter Confirmation Date"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmation_date"
                                // value={values?.confirmation_date}
                                value={moment(
                                  values?.confirmation_date,
                                  "YYYY/MM/DD"
                                ).format("YYYY-MM-DD")}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              {/* <Selector
                                label="Status"
                                // options={statusoptions}
                                name="status"
                                value={values?.status}
                                onChange={(e) =>
                                  setFieldValue("status", e.target.value?.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                // disabled={values?.status !== "status"}
                                disabled={true}
                              /> */}

                              <InputField
                                label="Status"
                                inputType="text"
                                inputPlaceholder="Confirmed"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="status"
                                value={values?.status}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Confirmed"
                                inputType="text"
                                inputPlaceholder="Confirmed"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmed"
                                value={moment(
                                  values?.confirmed,
                                  "YYYY/MM/DD"
                                ).format("DD-MM-YYYY")}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Probation Period"
                                inputType="text"
                                inputPlaceholder="Enter Probation Period"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="probation_period"
                                value={values?.probation_period}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Notice Period"
                                inputType="tel"
                                inputPlaceholder="Enter Notice Period"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="notice_period"
                                value={values?.notice_period}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Current Company Experience"
                                inputType="tel"
                                inputPlaceholder="Enter Current Company Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="experience"
                                value={values?.experience}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Previous Experience"
                                inputType="tel"
                                inputPlaceholder="Enter Previous Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="previous_experience"
                                value={values?.previous_experience}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Total Experience"
                                inputType="tel"
                                inputPlaceholder="Enter Total Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="totalExperience"
                                value={values?.totalExperience}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col  custom-width-50 ">
                              <InputField
                                label="Referred By"
                                inputType="text"
                                inputPlaceholder="Enter Referred By"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="referredBy"
                                value={values?.referredby}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col  custom-width-50 ">
                              <InputField
                                label="Assigned To"
                                inputType="text"
                                inputPlaceholder="Enter Assigned To"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="assignedto"
                                value={values?.assignedto}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Current Position" && (
                    <div className="info_btm">
                      <p>Current Position</p>
                    </div>
                  )}
                  {activeTab === "Current Position" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              {/* <Selector
                                label="Cost Center"
                                // options={companybyList}
                                name="costcenter"
                                value={values?.costcenter}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "costcenter",
                                    selectedOption?.target.value?.value
                                  );
                                  setFieldValue(
                                    "costcenter",
                                    selectedOption?.target.value?.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                disabled={true}
                              /> */}
                              <InputField
                                label="Cost Center"
                                inputType="text"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="costcenter"
                                value={values?.costcenter}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              {/* <Selector
                                label="Designation"
                                // options={rolebyList}
                                name="designation"
                                value={values?.designation}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "designation",
                                    selectedOption?.target.value?.value
                                  );
                                  setFieldValue(
                                    "designation",
                                    selectedOption?.target.value?.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                disabled={true}
                              /> */}
                              <InputField
                                label="Designation"
                                inputType="text"
                                inputPlaceholder="Designation"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="designation"
                                value={values?.designation}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              {/* <Selector
                                label="Location"
                                // options={companyUsers}
                                name="location"
                                value={values?.location}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "location",
                                    selectedOption?.target.value?.value
                                  );
                                  setFieldValue(
                                    "location",
                                    selectedOption?.target.value?.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                disabled={true}
                              /> */}
                              <InputField
                                label="Location"
                                inputType="text"
                                inputPlaceholder="Location"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="location"
                                value={values?.location}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Roles"
                                inputType="text"
                                inputPlaceholder="Roles"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="roles"
                                value={values?.roles}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Company"
                                inputType="text"
                                inputPlaceholder="Company"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="company1"
                                value={values?.company1}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Store Name"
                                inputType="text"
                                inputPlaceholder="Store Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="storename"
                                value={values?.storename}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Attendance Scheme"
                                inputType="text"
                                inputPlaceholder="Attendance Scheme"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="attendanceScheme"
                                value={values?.attendanceScheme}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Reporting To"
                                inputType="text"
                                inputPlaceholder="Reporting To"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="reportingto"
                                value={values?.reportingto}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              {/* <CustomSelector
                                label="Shift"
                                // options={shifbytList}
                                name="shift"
                                value={values?.shift}
                                onChange={(e) =>
                                  setFieldValue("shift", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16px"
                                width="100%"
                                multi={true} // Enable multiple selections
                                disabled={true}
                              /> */}
                              <InputField
                                label="Shift"
                                inputType="text"
                                inputPlaceholder="Shift"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="shift"
                                value={values?.shift}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Staff"
                                inputType="text"
                                inputPlaceholder="Staff"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="staff"
                                value={values?.staff}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Face Recognition*"
                                inputType="text"
                                inputPlaceholder="Face Recognition"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="facerecognition"
                                value={values?.facerecognition}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Documents" && (
                    <div className="info_btm">
                      <p>Documents</p>{" "}
                    </div>
                  )}
                  {activeTab === "Documents" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="upload_input">
                            {documentItems.map((doc) => (
                              <div key={doc.name} className="document-item">
                                <label className="doc-title">{doc.label}</label>
                                <InputLogo
                                  name={doc.name}
                                  value={values[doc.name]?.name || ""}
                                  onChange={(file) =>
                                    setFieldValue(doc.name, file)
                                  }
                                  error={errors[doc.name]}
                                  touched={touched[doc.name]}
                                  accept=".jpeg, .jpg, .png, .pdf, .doc, .docx"
                                  docsData={doc}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      ))}
                  {activeTab === "Employee Identity" && (
                    <div className="info_btm">
                      <p>Employee Identity</p>
                    </div>
                  )}
                  {activeTab === "Employee Identity" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Aadhar Number"
                                inputType="tel"
                                inputPlaceholder="Enter Aadhar Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="aadharnumber"
                                value={values?.aadharnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Name As Per Aadhar"
                                inputType="tel"
                                inputPlaceholder="Enter Name As Per Aadhar"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperaadhar"
                                value={values?.nameasperaadhar}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            {/* <div className="row_col">
                              <InputField
                                label="Aadhaar Enrolment No"
                                inputType="tel"
                                inputPlaceholder="Enter Aadhaar Enrolment No"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="aadhaarenrolment"
                                value={values?.aadhaarenrolment}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div> */}
                            <div className="row_col">
                              <InputField
                                label="Permanent Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Permanent Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaccountnumber"
                                value={values?.permanentaccountnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Name As Per PAN"
                                inputType="text"
                                inputPlaceholder="Enter Name As Per PAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperpan"
                                value={values?.nameasperpan}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Document Type"
                                inputType="text"
                                inputPlaceholder="Document Type"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empolyeedocumenttype"
                                value={values?.empolyeedocumenttype}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Name"
                                inputType="text"
                                inputPlaceholder="Bank Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empolyeebankname"
                                value={values?.empolyeebankname}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="accountnumber"
                                value={values?.accountnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="IFSC"
                                inputType="text"
                                inputPlaceholder="Enter IFSC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="iFSC"
                                value={values?.iFSC}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="Name In Bank Account"
                                inputType="text"
                                inputPlaceholder="Enter Name In Bank Account"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameinbankaccount"
                                value={values?.nameinbankaccount}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Education" && (
                    <div className="info_btm">
                      <p>Education</p>
                    </div>
                  )}{" "}
                  {activeTab === "Education" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Qualification"
                                inputType="text"
                                inputPlaceholder="Enter Qualification"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="qualification"
                                value={values?.qualification}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>

                            <div className="row_col custom-width-50">
                              <InputField
                                label="Institute Name"
                                inputType="text"
                                inputPlaceholder="Enter Institute Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="institutename"
                                value={values?.institutename}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Grade"
                                inputType="text"
                                inputPlaceholder="Enter Grade"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="grade"
                                value={values?.grade}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Address" && (
                    <div className="info_btm">
                      <p>Present Address</p>
                    </div>
                  )}{" "}
                  {activeTab === "Address" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress1"
                                value={values?.presentaddress1}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress2"
                                value={values?.presentaddress2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress3"
                                value={values?.presentaddress3}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Country"
                                inputType="text"
                                inputPlaceholder="Enter Country"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentcountry"
                                value={values?.presentcountry}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="State"
                                inputType="text"
                                inputPlaceholder="Enter State"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentstate"
                                value={values?.presentstate}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>

                            <div className="row_col">
                              <InputField
                                label="City"
                                inputType="text"
                                inputPlaceholder="Enter City"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentcity"
                                value={values?.presentcity}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>

                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentpincode"
                                value={values?.presentpincode}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="Phone1"
                                value={values?.Phone1}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="phone2"
                                value={values?.phone2}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="text"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentext"
                                value={values?.presentext}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Fax"
                                inputType="text"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentfax"
                                value={values?.presentfax}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentmobile"
                                value={values?.presentmobile}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="text"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentemail"
                                value={values?.presentemail}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Emergency Contact" && (
                    <div className="info_btm">
                      <p>Emergency Contact</p>
                    </div>
                  )}
                  {activeTab === "Emergency Contact" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Name"
                                inputType="text"
                                inputPlaceholder="Enter Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameemergency"
                                value={values?.nameemergency}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Relationship"
                                inputType="text"
                                inputPlaceholder="Enter Relationship"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="relationship"
                                value={values?.relationship}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeraddress1"
                                value={values?.emeraddress1}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeraddress2"
                                value={values?.emeraddress2}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeraddress3"
                                value={values?.emeraddress3}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="City"
                                inputType="text"
                                inputPlaceholder="Enter City"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emercity"
                                value={values?.emercity}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="State"
                                inputType="text"
                                inputPlaceholder="Enter State"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerstate"
                                value={values?.emerstate}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Country"
                                inputType="text"
                                inputPlaceholder="Enter Country"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentcountry"
                                value={values?.permanentcountry}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerpincode"
                                value={values?.emerpincode}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerphone1"
                                value={values?.emerphone1}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerphone2"
                                value={values?.emerphone2}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="tel"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emertext"
                                value={values?.emertext}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Fax"
                                inputType="tel"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerfax"
                                value={values?.emerfax}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emermobile"
                                value={values?.emermobile}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="email"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeremail"
                                value={values?.emeremail}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Emergency Contact" && (
                    <div className="info_btm">
                      <p>Background Check</p>
                    </div>
                  )}
                  {activeTab === "Emergency Contact" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Verification Status"
                                inputType="text"
                                inputPlaceholder="Enter Verification Status"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="vrificationstatus"
                                value={values?.vrificationstatus}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Verification Completed On"
                                inputType="date"
                                inputPlaceholder="Enter Verification Completed On"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="verificationcompleted"
                                value={values?.verificationcompleted}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            {/* <div className="row_col custom-width-50">
                              <InputField
                                label="Agency Name"
                                inputType="text"
                                inputPlaceholder="Enter Agency Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="agency_name"
                                value={values?.agency_name}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div> */}
                          </div>
                        </>
                      ))}
                  {activeTab === "Bank Account" && (
                    <div className="info_btm">
                      <p>Bank Account</p>
                    </div>
                  )}
                  {activeTab === "Bank Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Bank Name"
                                inputType="text"
                                inputPlaceholder="Enter Bank Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankname"
                                value={values?.bankname}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Branch"
                                inputType="text"
                                inputPlaceholder="Enter Title"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankbranch"
                                value={values?.bankbranch}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Account Number"
                                inputType="tel"
                                inputPlaceholder="Enter Bank Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankaccountnumber"
                                value={values?.bankaccountnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="IFSC"
                                inputType="text"
                                inputPlaceholder="Enter IFSC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankifsc"
                                value={values?.bankifsc}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="IBAN/ Swift Code / BIC"
                                inputType="text"
                                inputPlaceholder="Enter IBAN/ Swift Code / BIC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="iban"
                                value={values?.iban}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="Account Type"
                                inputType="text"
                                inputPlaceholder="Enter Account Type"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="accounttype"
                                value={values?.accounttype}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            {/* <div className="row_col">
                              <InputField
                                label="Payment Type"
                                inputType="text"
                                inputPlaceholder="Enter Payment Type"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="paymenttype"
                                value={values?.paymenttype}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div> */}
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="DD Payable At"
                                inputType="text"
                                inputPlaceholder="Enter DD Payable At"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="dd_Payable"
                                value={values?.dd_Payable}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Name as Per Bank Records"
                                inputType="text"
                                inputPlaceholder="Enter Name as Per Bank Records"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bank_records"
                                value={values?.bank_records}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "ESI Account" && (
                    <div className="info_btm">
                      <p>ESI Account</p>
                    </div>
                  )}
                  {activeTab === "ESI Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input ">
                            <div className="row_col custom-width-50">
                              <Checkbox
                                id="underESI"
                                label="Employee Is Covered Under PF"
                                checked={checkedItems?.underESI}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="ESI Number"
                                inputType="tel"
                                inputPlaceholder="Enter ESI Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="esinumber"
                                value={values?.esinumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "PF Account" && (
                    <div className="info_btm">
                      <p>PF Account</p>
                    </div>
                  )}
                  {activeTab === "PF Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <Checkbox
                                id="underpf"
                                label="Employee Is Covered Under PF"
                                checked={checkedItems?.underpf}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="UAN"
                                inputType="tel"
                                inputPlaceholder="Enter UAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="uan"
                                value={values?.uan}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="PF Number"
                                inputType="tel"
                                inputPlaceholder="Enter PF Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="pf_num"
                                value={values?.pf_num}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="PF Join Date"
                                inputType="date"
                                inputPlaceholder="Enter PF Join Date"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="pf_join"
                                value={values?.pf_join}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Family PF Number"
                                inputType="tel"
                                inputPlaceholder="Enter PF Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="family_pf_number"
                                value={values?.family_pf_number}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                            <div className="row_col">
                              <Checkbox
                                id="memberofeps"
                                label="Is Existing Member Of EPS"
                                checked={checkedItems?.memberofeps}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <Checkbox
                                id="allowEpf"
                                label="Allow EPF Excess Contribution"
                                checked={checkedItems?.allowEpf}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Document Type"
                                inputType="text"
                                inputPlaceholder="Document Type"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="documenttype"
                                value={values?.documenttype}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  <div className="flex_btn_next">
                    {activeTab !== tabs[0].name && (
                      <div className="btn_pre">
                        <button
                          type="button"
                          onClick={() =>
                            setActiveTab(
                              tabs[
                                tabs.findIndex(
                                  (tab) => tab.name === activeTab
                                ) - 1
                              ].name
                            )
                          }
                        >
                          Previous
                        </button>
                      </div>
                    )}

                    {/* {activeTab !== tabs[tabs.length - 1].name && (
                          <div className="btn_left">
                            <button
                              type="button"
                              onClick={() =>
                                handleNext(values, errors, touched, setFieldTouched)
                              }
                            >
                              Next
                            </button>
                          </div>
                        )} */}

                    {activeTab !== tabs[tabs.length - 1].name && (
                      <div className="btn_left">
                        <button
                          type="button"
                          onClick={() => handleNext(values, errors, touched)}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
export default ViewUser;
