import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/common/inputfield/InputField";
import Selector from "../../components/common/selects/Selector";
import { Field, Formik } from "formik";
import InputLogo from "../../components/common/customlogo/InputLogo";
import { getSiteSettings, updateSiteSettings } from "../../redux/Action";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [checkedItems, setCheckedItems] = useState({
    allowEpf: false,
    css: false,
    javascript: false,
  });
  const [error, setError] = useState("");

  const { siteSettingDetails } = useSelector((state) => state?.main);
  const pageLoading = useSelector((state) => state.main.loading);

  useEffect(() => {
    dispatch(getSiteSettings());
  }, []);

  useEffect(() => {
    if (siteSettingDetails) {
      setSelectedValue(siteSettingDetails);
    }
  }, [siteSettingDetails]);

  const options = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [id]: checked,
    }));
  };
  return (
    <div>
      <div className="container mx-auto mt-4 mb-4 ">
        <Formik
          initialValues={{
            admin_email: selectedValue?.admin_email || "",
            admin_name: selectedValue?.admin_name || "",
            android_version: selectedValue?.android_version || "",
            ios_version: selectedValue?.ios_version || "",
            about_app: selectedValue?.about_app || "",
            logo_name: selectedValue?.logo_name || "",
            maintenance_mode: selectedValue?.maintenance_mode || "",
          }}
          enableReinitialize={true}
          validate={(values, files, name) => {
            const errors = {};
            if (!values.admin_email) {
              errors.admin_email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.admin_email
              )
            ) {
              errors.admin_email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const userData = new FormData();
            userData.append("admin_name", values?.admin_name);
            userData.append("admin_email", values?.admin_email);
            userData.append("android_version", values?.android_version);
            userData.append("ios_version", values?.ios_version);
            userData.append("about_app", values?.about_app);
            userData.append("logo_name", values?.logo_name);
            userData.append("maintenance_mode", values?.maintenance_mode);
            userData.append("file", values?.file);

            dispatch(updateSiteSettings(userData));

            // setFormData({});
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,

            /* and other goodies */
          }) => (
            <div className="container mx-auto mt-4 mb-4 ">
              <form onSubmit={handleSubmit}>
                <>
                  <div className="flex_input">
                    <InputField
                      label="Admin Name"
                      inputType="text"
                      inputPlaceholder="Enter Admin Name"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="admin_name"
                      value={values.admin_name}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                      showStar={true}
                    />
                    {errors.admin_name &&
                      touched.admin_name &&
                      errors.admin_name}
                    <InputField
                      label="Admin E-mail"
                      inputType="text"
                      inputPlaceholder="Enter Admin E-mail"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="admin_email"
                      value={values.admin_email}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                      showStar={true}
                    />
                    {errors.admin_email &&
                      touched.admin_email &&
                      errors.admin_email}
                  </div>
                  <div className="flex_input">
                    <InputField
                      label="Logo Name"
                      inputType="text"
                      inputPlaceholder="Enter Logo Name"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="logo_name"
                      value={values.logo_name}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                      width={"49%"}
                    />
                    {errors.logo_name && touched.logo_name && errors.logo_name}
                    {/* <InputField
                      label="Logo (Only jpeg, png, jpg files allowed)"
                      inputType="text"
                      inputPlaceholder="Enter Logo"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="logo"
                      value={values.logo}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    /> */}
                    <div style={{ width: "49%" }}>
                      <InputLogo
                        showIcon={false}
                        buttonPadding="8px 30px"
                        name="file"
                        value={values.file}
                        onChange={handleChange}
                      />{" "}
                    </div>
                    {errors.logo && touched.logo && errors.logo}
                  </div>
                  <div className="flex_input">
                    <InputField
                      label="App Version(android) "
                      inputType="text"
                      inputPlaceholder="Enter App Version(android) "
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="android_version"
                      value={values.android_version}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                      showStar={true}
                    />
                    {errors.android_version &&
                      touched.android_version &&
                      errors.android_version}
                    <InputField
                      label="App Version (ios)"
                      inputType="text"
                      inputPlaceholder="Enter App Version (ios)"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="ios_version"
                      value={values.ios_version}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    />
                    {errors.ios_version &&
                      touched.ios_version &&
                      errors.ios_version}
                  </div>
                  <div className="flex_input">
                    <Selector
                      label="Maintenance Mode"
                      options={options}
                      name="maintenance_mode"
                      value={values.maintenance_mode}
                      onChange={(e) =>
                        setFieldValue(
                          "maintenance_mode",
                          e.target?.value?.value
                        )
                      }
                      LabelFontSize="16px"
                      SelectorFontSize="16px"
                      labelColor="#2c357d"
                      error={errors.maintenance_mode}
                      showStar={true}
                    />
                    {errors.maintenance_mode &&
                      touched.maintenance_mode &&
                      errors.maintenance_mode}
                    <InputField
                      label="About App"
                      inputType="text"
                      inputPlaceholder="Enter About App"
                      InputFontSize="16px"
                      LabelFontSize="16px"
                      name="about_app"
                      value={values.about_app}
                      onChange={handleChange}
                      borderColor="#2c357d"
                      labelColor="#2c357d"
                    />
                    {errors.about_app && touched.about_app && errors.about_app}
                  </div>
                </>
                {pageLoading ? (
                  <SpinnerLoader />
                ) : (
                  <div className="btn_left" style={{ paddingTop: "50px" }}>
                    <button type="submit">Save</button>
                  </div>
                )}
              </form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
