import React, { useState, useEffect } from "react";
import RightCustomModal from "../../Common/RightModal";
import AddDesgination from "../AddNewDesgination/AddDesgination";
import {
  designationdeleteData,
  getSettingResignation,
  updateDesignation,
} from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";
import Pagination from "../../Common/Pagination/Pagination";

const Designation = () => {
  const dispatch = useDispatch();

  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  // const [selectedReporting, setSelectedReporting] = useState({}); // Track selected reporting designations
  const [updatedData, setUpdatedData] = useState({});
  const [status, setstatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    allResignationList,
    deleteDesignationlist,
    allResignationList2,
    addDesignation,
  } = useSelector((state) => state.main);
  const pageLoading = useSelector((state) => state.main.loading);

  const handleClose = () => {
    setOpenInviteMember(false);
    setUpdatedData({});
  };

  useEffect(() => {
    dispatch(getSettingResignation(currentPage));
  }, [currentPage, deleteDesignationlist, allResignationList2, addDesignation]);

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };

  const handleDelete = () => {
    dispatch(designationdeleteData(selectedUserId?._id));
    onCloseModal();
  };
  // const handleSave = (data) => {
  //   const updatedData = {
  //     updatedId: data._id,
  //   };
  //   dispatch(updateDesignation(data, updatedData));
  // };

  // const handleReportingChange = (id, value) => {
  //   setSelectedReporting((prev) => ({
  //     ...prev,
  //     [id]: value,
  //   }));
  // };

  const fields = [
    { label: "Designation Name", id: "name" },
    { label: "Reporting Designation", id: "reposting_designation_name" },
    { label: "Status", id: "status" },
  ];

  // const reportingOptions = allResignationList?.data?.map(
  //   (item) => item?.reposting_designation_name
  // );
  const OnAddClickHandler = (key) => {
    setOpenInviteMember(true);
    setstatus(key);
  };
  const OnEditClickHandler = (data, key) => {
    setSelectedUserId(data?._id);
    setOpenInviteMember(true);
    setUpdatedData(data);
    setstatus(key);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Designation</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => OnAddClickHandler("add")}>
                Add Designation
              </button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {fields.map((field) => (
                        <th
                          key={field.id}
                          style={{
                            textAlign:
                              field.id === "reposting_designation_name"
                                ? "center"
                                : "left",
                            width:
                              field.id === "name"
                                ? "30%"
                                : field.id === "reposting_designation_name"
                                ? "20%"
                                : "auto", // Default for other fields if needed
                            borderLeft: "none",
                            textAlign:
                              field.id === "status"
                                ? "center"
                                : field.id === "reposting_designation_name"
                                ? "center"
                                : "left",
                          }}
                        >
                          {field.label}
                        </th>
                      ))}
                      <th
                        style={{
                          textAlign: "center",
                          width: "30%",
                          borderRight: "none",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  {/* <tbody>
                    {allResignationList?.data?.map((designation, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #2C357D2E" }}
                      >
                        {fields.map((field) => (
                          <td
                            key={field.id}
                            style={{
                              textAlign:
                                field.id === "reposting_designation_name"
                                  ? "center"
                                  : "left",
                              width:
                                field.id === "name"
                                  ? "30%"
                                  : field.id === "reposting_designation_name"
                                  ? "50%"
                                  : "auto",
                            }}
                          >
                            {field.id === "reposting_designation_name" ? (
                              <select
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid #2c357d",
                                  outline: "none",
                                  background: "transparent",
                                  textAlign: "center",
                                }}
                                value={
                                  selectedReporting[designation._id] ||
                                  designation[field.id]
                                }
                                onChange={(e) =>
                                  handleReportingChange(
                                    designation._id,
                                    e.target.value
                                  )
                                }
                              >
                                {reportingOptions.map((option, i) => (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              designation[field.id]
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            textAlign: "center",
                            width: "20%",
                          }}
                        >
                          <span className="d-flex align-items-center justify-content-center gap-3">
                            <button onClick={() => handleSave(designation)}>
                              Save
                            </button>
                            <button onClick={() => onOpenModal(designation)}>
                              Delete
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody> */}
                  <tbody>
                    {allResignationList?.data?.map((designation, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #2C357D2E" }}
                      >
                        {fields.map((field) => (
                          <td
                            style={{
                              textAlign:
                                field.id === "reposting_designation_name"
                                  ? "center"
                                  : "left",
                              width:
                                field.id === "name"
                                  ? "30%"
                                  : field.id === "reposting_designation_name"
                                  ? "50%"
                                  : "auto",
                              width: "30%",
                              textAlign:
                                field.id === "status"
                                  ? "center"
                                  : field.id === "reposting_designation_name"
                                  ? "center"
                                  : "left",
                            }}
                          >
                            {field.id === "status" ? (
                              <span
                                style={{
                                  backgroundColor:
                                    designation[field.id] === true
                                      ? "#a2eddb40"
                                      : designation[field.id] === false
                                      ? "#ff323240"
                                      : "transparent",
                                  color:
                                    designation[field.id] === true
                                      ? "#24e502"
                                      : designation[field.id] === false
                                      ? "#ff3232"
                                      : "inherit",
                                  padding: "5px 15px",
                                  borderRadius: "8px",
                                  fontWeight: "300",
                                  display: "inline-block",
                                }}
                              >
                                {designation[field.id] === true
                                  ? "Active"
                                  : designation[field.id] === false
                                  ? "Inactive"
                                  : "-"}
                              </span>
                            ) : (
                              designation[field.id] || "-" // Fallback to "-" if value is empty
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            textAlign: "center",
                            width: "30%",
                          }}
                        >
                          <span className="d-flex align-items-center justify-content-center gap-3">
                            <button
                              onClick={() =>
                                OnEditClickHandler(designation, "edit")
                              }
                            >
                              Edit
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddDesgination
            onClose={handleClose}
            updatedData={updatedData}
            status={status}
          />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
      <Pagination
        listData={allResignationList?.meta}
        setPageData={setCurrentPage}
      />
    </>
  );
};

export default Designation;
