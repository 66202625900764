import LoginPage from "./Common/LoginPage.jsx";
import ForgotPassword from "./Common/ForgotPassword.jsx";
import Verification from "./Common/Verification.jsx";
import NewPassword from "./Common/NewPassword.jsx";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute.js";
import Dashboard from "./Admin/pages/Dashboard/Dashboard.jsx";
import AddEmployee from "./Admin/pages/AddEmployee/AddEmployee.jsx";
import Profile from "./Admin/pages/Profile/Profile.jsx";
import Payrolls from "./Admin/pages/Payroll/Payrolls.jsx";
import Requests from "./Admin/pages/Request/Requests.jsx";
import ViewRequest from "./Admin/pages/Request/ViewRequest.jsx";
import Jobs from "./Admin/pages/Job/Jobs.jsx";
import ViewJob from "./Admin/pages/Job/ViewJob.jsx";
import Settings from "./Common/Settings/Settings.jsx";
import User from "./Admin/pages/Users/User.jsx";
import Form from "./Common/Form/Form.jsx";
import { Container } from "@chakra-ui/react";
import Reports from "./Common/Reports/Reports.jsx";
import Store from "./Management/Stores/Store.jsx";
import Designation from "./Management/Designation/Designation.jsx";
import Role from "./Management/Roles/Role.jsx";
import Shift from "./Management/Shift/Shift.jsx";
import Company from "./Management/Company/Company.jsx";
import AddJob from "./Admin/pages/Job/AddJob.jsx";
import ViewUser from "./Admin/pages/Users/ViewUser.jsx";
import EditUser from "./Admin/pages/Users/EditUser.jsx";
import ViewReports from "./Common/Reports/ViewReports.jsx";
import TableExcel from "./TableExcel/TableExcel.jsx";
import Resignation from "./Common/AddResignation/Resignation.jsx";
import Grievance from "./Common/AddGrievance/Grievance.jsx";
import ViewGrievance from "./Common/AddGrievance/ViewGrievance.jsx";
import ViewResignation from "./Common/AddResignation/ViewResignation.jsx";
import Attendance from "./Common/Addattendance/Attendance.jsx";
import AttendanceOverview from "./Common/Addattendance/AttendanceOverview.jsx";
import Posh from "./Common/AddPosh/Posh.jsx";
import ViewPosh from "./Common/AddPosh/ViewPosh.jsx";
import VIewReportUser from "./Common/Reports/VIewReportUser.jsx";
import ProgressbarTimer from "./components/common/progressbar/ProgressbarTimer.jsx";
import EditJob from "./Admin/pages/Job/EditJob.jsx";

function App() {
  const access_token = localStorage.getItem("token");
  const location = useLocation();

  return (
    <>
      <Routes>
        <>
          <Route element={<PrivateRoute />}>
            {location.pathname === "/" && (
              <Route path="/" element={<Dashboard />} />
            )}
            {location.pathname === "/dashboard" && (
              <Route path="/dashboard" element={<Dashboard />} />
            )}
            <Route path="/addemployee" element={<AddEmployee />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/payroll" element={<Payrolls />} />
            <Route path="/report" element={<Reports />} />
            <Route path="/viewreports" element={<ViewReports />} />
            <Route path="/report/viewreports/:id" element={<ViewReports />} />
            <Route path="/requests/viewrequest/:id" element={<ViewRequest />} />
            <Route path="/resignation" element={<Resignation />} />
            <Route
              path="/resignation/viewresignation/:id"
              element={<ViewResignation />}
            />
            <Route path="/grievance" element={<Grievance />} />
            <Route
              path="/grievance/viewgrievance/:id"
              element={<ViewGrievance />}
            />
            <Route path="/posh" element={<Posh />} />
            <Route path="/posh/viewposh/:id" element={<ViewPosh />} />

            <Route path="/job" element={<Jobs />} />
            <Route path="/job/viewjob/:id" element={<ViewJob />} />
            <Route path="/AddJob" element={<AddJob />} />
            <Route path="/job/editJob/:id" element={<EditJob />} />
            <Route path="/user/viewuser/:id" element={<ViewUser />} />
            <Route
              path="/report/viewreports/viewreportuser/:id"
              element={<VIewReportUser />}
            />
            <Route path="/user/edituser/:id" element={<EditUser />} />
            <Route path="/AddJob" element={<AddJob />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/stores" element={<Store />} />
            <Route path="/designation" element={<Designation />} />
            <Route path="/company" element={<Company />} />
            <Route path="/roles" element={<Role />} />
            <Route path="/shifts" element={<Shift />} />
            <Route path="/user" element={<User />} />
            <Route path="/addattendance" element={<Attendance />} />
            <Route
              path="/attendanceoverview/:id"
              element={<AttendanceOverview />}
            />
            <Route path="/form" element={<Form />} />
          </Route>
        </>
        <Route path="/login" index element={<LoginPage />} />

        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="verification" element={<Verification />} />
        <Route path="newpassword" element={<NewPassword />} />
        <Route path="tableexcel" element={<TableExcel />} />

        {/* <Route path="/login" element={<LoginPage />} /> */}
      </Routes>
    </>
  );
}

export default App;
