import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import "../../../Common/Form/form.css";
import InputField from "../../../components/common/inputfield/InputField.jsx";
import Selector from "../../../components/common/selects/Selector.jsx";
import Checkbox from "../../../components/common/checkbox/checkbox.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputLogo from "../../../components/common/customlogo/InputLogo.js";
import styles from "../../../Common/loginPage.module.scss";
import eye from "../../../assets/img/eye.png";
import banknamejson from "../../../assets/bankname.json";
import eye_off from "../../../assets/img/eye_off.png";
import Selector2 from "../../../components/common/selects/NewSelector.jsx";
import {
  addUsers,
  getAllCityList,
  getAllCityListEmer,
  getAllCountryList,
  getAllStateList,
  getAllStateListEmer,
  getAllStateListFor,
  getReportSingleUserdata,
  getStoreByCompany,
  getUsersAll,
  updateUserData,
} from "../../../redux/Action/index.js";
import camera from "../../../assets/img/camera.png";
import Rectangle from "../../../assets/img/Rectangle.png";
import moment from "moment-timezone";
import CustomSelector from "../../../components/common/customselector/CustomSelector.jsx";
import { toast } from "react-toastify";

const EditUser = ({ addFormCompleted, formType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedValue, setSelectedValue] = useState("");
  const [checkedItems, setCheckedItems] = useState({
    memberofeps: false,
    underpf: false,
    underESI: false,
    userActive: true,
    allowEpf: false,
  });
  const userRole = localStorage.getItem("role");
  const [activeTab, setActiveTab] = useState("Employee Information");
  const [updateForm, setUpdateForm] = useState();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [newErrors, setNewErrors] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);
  const {
    getAllUserList,
    allCountryList,
    getAllStateData,
    getAllCityData,
    allUserList,
    allReportSingleUser,
    getAllStateListData,
    getAllCityDataEmer,
    getAllStateDataEmer,
    storeListByCompany,
  } = useSelector((state) => state?.main);
  const pathname = location.pathname;

  const isMobile = localStorage.getItem("view");

  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePassword2, setTogglePassword2] = useState(false);

  const toggleVisibility = (e) => {
    setTogglePassword(!togglePassword);
  };
  const toggleVisibility2 = (e) => {
    setTogglePassword2(!togglePassword2);
  };

  const pathParts = pathname.split("/");
  const userId = pathParts[pathParts.length - 1];
  const assignedToUsers = allUserList?.data?.assignedToUsers || [];
  const referredUsers = allUserList?.data?.referredUsers || [];
  const storeList = allUserList?.data?.stores || [];
  const companiesList = allUserList?.data?.companies || [];
  const storeList2 = storeListByCompany?.data || [];
  const adminList = allUserList?.data?.admins || [];
  const roleList = allUserList?.data?.roles || [];
  const ShiftList = allUserList?.data?.shift || [];

  const presentcountryoptions = allCountryList?.map((user) => ({
    label: user.name,
    value: user._id,
  }));
  const presentstateoptions = getAllStateData?.map((user) => ({
    label: user.name,
    value: user._id,
  }));
  const presentcityoptions = getAllCityData?.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  const presentstateoptionsEmer = getAllStateDataEmer?.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  const presentcityoptionsEmer = getAllCityDataEmer?.map((user) => ({
    label: user.name,
    value: user._id,
  }));
  const userOptions = assignedToUsers.map((user) => ({
    label: user.name,
    value: user._id,
    isDisabled: user.userActive == true ? false : true,
  }));

  console.log(userOptions, "userOptions");

  const allAdminsList = adminList?.map((user) => ({
    label: user.name,
    value: user._id,
  }));

  const experience = Array.from({ length: 20 }, (_, index) => ({
    label: index + 1,
    value: index + 1,
  }));
  const referredByUsers = referredUsers
    .filter((data) => data._id !== userId)
    .map((user) => ({
      label: user.name,
      value: user._id,
      isDisabled: user.userActive == true ? false : true,
    }));

  // const storeUsers = storeList.map((user) => ({
  //   label: user.name,
  //   value: user._id,
  // }));

  const storeUsers = storeList2.map((user) => ({
    label: user.name,
    value: user._id,
    isDisabled: user.status == true ? false : true,
  }));
  const companyUsers = storeList.map((user) => ({
    value: user._id,
    label: user.address,
  }));
  const companybyList = companiesList.map((user) => ({
    value: user._id,
    label: user.company_name,
    isDisabled: user.status == true ? false : true,
  }));

  const rolebyList = roleList.map((user) => ({
    label: user.name,
    value: user._id,
    isDisabled: user.status == true ? false : true,
  }));
  const shifbytList = ShiftList.map((user) => ({
    label: user.name,
    value: user._id,
    isDisabled: user.status == true ? false : true,
  }));

  const convertTo12HourIST = (utcDateTime) => {
    if (utcDateTime) {
      const istDateTime = moment.utc(utcDateTime).tz("Asia/Kolkata");
      const formattedTime = istDateTime.format("h:mm A"); // 12-hour format with AM/PM
      return formattedTime ? formattedTime : "-";
    }
    return "-";
  };

  useEffect(() => {
    dispatch(getAllCountryList());
    dispatch(getAllStateListFor());
  }, []);

  useEffect(() => {
    dispatch(getUsersAll());
  }, []);

  useEffect(() => {
    if (updateForm) {
      if (updateForm?.emerCountry) {
        dispatch(getAllStateListEmer(updateForm?.emerCountry));
      }

      if (updateForm?.emerState) {
        dispatch(getAllCityListEmer(updateForm?.emerState));
      }
      if (updateForm?.presentCountry) {
        dispatch(getAllStateList(updateForm?.presentCountry));
      }
      if (updateForm?.presentState) {
        dispatch(getAllCityList(updateForm?.presentState));
      }
      if (updateForm?.company) {
        dispatch(getStoreByCompany(updateForm?.company));
      }

      if (userRole === "management") {
        if (updateForm?.adminId) {
          dispatch(getUsersAll(false, 1, updateForm?.adminId));
        }
      }
    }
  }, [updateForm]);

  const tabs = [
    {
      name: "Employee Information",
      fields: [
        { label: "Employee Number", type: "tel", name: "emp_id" },
        { label: "Title", type: "text", name: "title" },
        { label: "Employee Name", type: "text", name: "empl_name" },
        { label: "Employee Nick Name", type: "text", name: "nick_name" },
      ],
    },

    {
      name: "Joining Details",
      fields: [
        { label: "Joining Date", name: "joining_date", type: "date" },
        { label: "Position", name: "position", type: "text" },
        { label: "Department", name: "department", type: "text" },
      ],
    },
    {
      name: "Current Position",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Documents",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Employee Identity",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Education",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Address",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Emergency Contact",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "Bank Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "ESI Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
    {
      name: "PF Account",
      fields: [
        { label: "Manager", name: "manager", type: "text" },
        { label: "Contract Type", name: "contract_type", type: "text" },
      ],
    },
  ];
  const options = [
    { value: "A-", label: "A-" },
    { value: "B-", label: "B-" },
    { value: "A+", label: "A+" },
    { value: "B+", label: "B+" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB-", label: "AB-" },
    { value: "AB+", label: "AB+" },
  ];
  const statusoptions = [
    { value: "Probation", label: "Probation" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Resigned", label: "Resigned" },
    { value: "Notice Period", label: "Notice Period" },
  ];
  const rolesbyusers = [
    { value: "user", label: "User" },
    { value: "manager", label: "Manager" },
  ];

  const facerecognitionoptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];
  const titleoptions = [
    { value: "mr", label: "Mr." },
    { value: "mrs", label: "Mrs." },
    { value: "miss", label: "Miss" },
    { value: "ms", label: "Ms." },
    { value: "dr", label: "Dr." },
    { value: "prof", label: "Prof." },
  ];
  const genderoptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  const maritalStatus = [
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
    { value: "seperated", label: "Seperated" },
    { value: "widowed", label: "Widowed" },
    { value: "widower", label: "Widower" },
  ];
  const nationalityOption = [
    { value: "india", label: "India" },
    { value: "uk", label: "United Kingdom" },
    { value: "usa", label: "United States" },
    { value: "canada", label: "Canada" },
  ];
  const residentialstatusoptions = [
    { value: "rented", label: "Rented" },
    { value: "owned", label: "Owned" },
    { value: "resident india", label: "Resident India" },
  ];
  const placeofoptions = getAllStateListData?.map((user) => ({
    label: user.name,
    value: user.name,
  }));
  const religionoptions = [
    { value: "Hinduism ", label: "Hinduism " },
    { value: "Islam", label: "Islam" },
    { value: "Christianity", label: "Christianity " },
    { value: "Sikhism ", label: "Sikhism " },
    { value: "Buddhism ", label: "Buddhism " },
    { value: "Jainism ", label: "Jainism " },
    { value: "others", label: "Others" },
  ];
  const referredbyoptions = [
    { value: "friend", label: "Friend" },
    { value: "family", label: "Family" },
    { value: "colleague", label: "Colleague" },
  ];
  const assignedtooptions = [
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];
  const costcenteroptions = [
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];
  const designationoptions = [
    { value: "marketing", label: "Marketing" },
    { value: "sales", label: "Sales" },
  ];
  const locationoptions = [
    { value: "office", label: "Office" },
    { value: "home", label: "Home" },
  ];
  // const optionscompany = [
  //   { value: "india", label: "India" },
  //   { value: "landon", label: "Landon" },
  // ];
  const storenameoptions = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const documentType = [
    { value: "passport", label: "Passport" },
    { value: "driving_license", label: "Driving License" },
  ];

  const reportingtooptions = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const shiftoptions = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "night", label: "Night" },
  ];
  const attendanceSchemeoptions = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "night", label: "Night" },
  ];

  const staffoptions = [
    { value: "india", label: "India" },
    { value: "uk", label: "United Kingdom" },
    { value: "usa", label: "United States" },
    { value: "japan", label: "Japan" },
  ];
  // const presentcityoptions = [
  //   { value: "mumbai", label: "Mumbai" },
  //   { value: "delhi", label: "Delhi" },
  //   { value: "london", label: "London" },
  // ];
  const qualificationoptions = [
    { value: "high_school", label: "High School" },
    { value: "diploma", label: "Diploma" },
    { value: "associate_degree", label: "Associate Degree" },
    { value: "bachelors", label: "Bachelor's Degree" },
    { value: "masters", label: "Master's Degree" },
    { value: "phd", label: "Doctorate (PhD)" },
    { value: "postdoctoral", label: "Postdoctoral" },
    { value: "certificate", label: "Certificate" },
    { value: "vocational", label: "Vocational Training" },
    { value: "mba", label: "MBA" },
    { value: "engineering", label: "Engineering" },
    { value: "medicine", label: "Medicine" },
    { value: "law", label: "Law Degree" },
    { value: "chartered_accountant", label: "Chartered Accountant" },
    { value: "architecture", label: "Architecture" },
    { value: "it_certification", label: "IT Certification" },
    { value: "other", label: "Other" },
  ];
  // const presentstateoptions = [
  //   { value: "maharashtra", label: "Maharashtra" },
  //   { value: "delhi", label: "Delhi" },
  //   { value: "greater_london", label: "Greater London" },
  // ];
  const permanentcityoptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "delhi", label: "Delhi" },
  ];
  const permanentstateoptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
  ];
  const permanentcountryoptions = [
    { value: "india", label: "India" },
    { value: "landon", label: "Landon" },
  ];
  const vrificationstatusoptions = [
    { value: "verified", label: "Verified" },
    { value: "pending", label: "Pending" },
  ];
  const relationshipoptions = [
    { value: "parent", label: "Parent" },
    { value: "sibling", label: "Sibling" },
    { value: "husband", label: "Husband" },
    { value: "sibling", label: "Sibling" },
    { value: "friend", label: "Friend" },
    { value: "wife", label: "Wife" },
    { value: "relative", label: "Relative" },
    { value: "other", label: "Other" },
  ];

  // const banknameoptions = [
  //   { value: "hdfc", label: "HDFC" },
  //   { value: "icici", label: "ICICI" },
  //   { value: "sbi", label: "State Bank of India" },
  // ];

  const banknameoptions = banknamejson;

  const accounttypeoptions = [
    { value: "savings", label: "Savings" },
    { value: "current", label: "Current" },
    { value: "fixed", label: "Fixed" },
  ];
  const paymenttypeoptions = [
    { value: "credit_card", label: "Credit Card" },
    { value: "debit_card", label: "Debit Card" },
    { value: "net_banking", label: "Net Banking" },
  ];
  const agency_nameoptions = [
    { value: "agency1", label: "Agency 1" },
    { value: "agency2", label: "Agency 2" },
  ];
  const documenttypeoptions = [
    { value: "identity_proof", label: "Identity Proof" },
    { value: "address_proof", label: "Address Proof" },
  ];

  const documentItems = [
    {
      name: "aadharCard",
      label: "Aadhar Card",
      img: updateForm?.aadharCardDocument,
    },
    { name: "panCard", label: "Pan Card", img: updateForm?.panCardDocument },
    {
      name: "passBook",
      label: "Pass Book / Cancelled Cheque",
      img: updateForm?.passBookOrCancelledCheque,
    },
    {
      name: "relievingLetter",
      label: "Previous Org Relieving Letter",
      img: updateForm?.prevOrgRelivingLetter,
    },
    {
      name: "paySlip",
      label: "Previous Org Pay Slip",
      img: updateForm?.prevOrgPayslip,
    },
  ];

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [id]: checked,
    }));
  };

  {
    console.log(updateForm, "manisha");
  }
  const handleTabClick = (values, setFieldTouched, tabName) => {
    setActiveTab(tabName);

    setTimeout(() => {
      const activeTabErrors = validate(values, tabName);
      if (Object.keys(activeTabErrors).length > 0) {
        Object.keys(activeTabErrors).forEach((field) =>
          setFieldTouched(field, true)
        );

        // // Merge the old errors with the current tab's errors
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   [tabName]: activeTabErrors, // Keep errors specific to the current tab
        // }));
        // return;
      } else {
        if (tabName == "Address") {
          setFieldTouched("Address", false);
        } else if (tabName == "Emergency Contact") {
          setFieldTouched("Emergency Contact", false);
        } else if (tabName == "Employee Information") {
          setFieldTouched("Employee Information", false);
        } else if (tabName == "Employee Identity") {
          setFieldTouched("Employee Identity", false);
        } else if (tabName == "PF Account") {
          setFieldTouched("PF Account", false);
        }
      }
      // setErrors((prevErrors) => {
      //   const newErrors2 = { ...prevErrors };
      //   delete newErrors2[tabName]; // Remove errors for the current tab if validated
      //   return newErrors2;
      // });
    }, 200);
  };
  const handleNext = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1].name);
    }
  };

  const handlePrevious = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1].name);
    }
  };
  useEffect(() => {
    dispatch(getReportSingleUserdata(userId));
  }, [userId]);

  useEffect(() => {
    if (userId && allReportSingleUser) {
      if (allReportSingleUser._id === userId) {
        setUpdateForm(allReportSingleUser);
        setCheckedItems({
          memberofeps:
            typeof allReportSingleUser?.isExistingMemberOfEPS !== "boolean"
              ? false
              : allReportSingleUser?.isExistingMemberOfEPS,
          underpf:
            typeof allReportSingleUser?.employeeCoveredUnderPf !== "boolean"
              ? false
              : allReportSingleUser?.employeeCoveredUnderPf,
          underESI:
            typeof allReportSingleUser?.employeeCoveredUnderEsi !== "boolean"
              ? false
              : allReportSingleUser?.employeeCoveredUnderEsi,
          userActive:
            typeof allReportSingleUser?.userActive !== "boolean"
              ? false
              : allReportSingleUser?.userActive,
          allowEpf:
            typeof allReportSingleUser?.allowEpfExcessContribution !== "boolean"
              ? false
              : allReportSingleUser?.allowEpfExcessContribution,
        });
      } else {
        setUpdateForm({});
      }
    } else {
      setUpdateForm({});
    }
  }, [userId, allReportSingleUser]);

  const validate = (values, activeTab) => {
    const errors = {};

    switch (activeTab) {
      case "Employee Information":
        if (!values.title) errors.title = "Title is required";

        if (!values.empl_name) {
          errors.empl_name = "Employee name is required";
        } else if (!/^[A-Za-z\s]+$/.test(values.empl_name)) {
          errors.empl_name =
            "Employee name should only contain alphabetic characters";
        }
        // if (!values.nick_name) errors.nick_name = "Nick Name is required";
        if (!values.gender) errors.gender = "Gender is required";

        if (!values.mobilenumber) {
          errors.mobilenumber = "Mobile Number is required";
        } else if (!/^\d{10}$/.test(values.mobilenumber)) {
          errors.mobilenumber = "Mobile Number must be only digits";
        }
        // if (!values.employee_email) {
        //   errors.employee_email = "Email is required";
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        //     values.employee_email
        //   )
        // ) {
        //   errors.employee_email = "Invalid email address";
        // }
        // if (!values.employee_email) errors.employee_email = "Email is required";
        if (!values.birthday) errors.birthday = "Date of Birth is required";
        if (!values.bloodgroup) errors.bloodgroup = "Blood Group is required";
        if (!values.empl_father)
          errors.empl_father = "Father's Name is required";
        if (!values.nationality) errors.nationality = "Nationality is required";
        if (!values.residentialstatus)
          errors.residentialstatus = "Residential Status is required";
        if (!values.Place_birth)
          errors.Place_birth = "Place of Birth is required";
        if (!values.religion) errors.religion = "Religion is required";
        if (!values.marital) {
          errors.marital = "Marital status is required";
        }
        break;

      case "Joining Details":
        // if (!values.joining_on) errors.joining_on = "Joining Date is required";
        // if (!values.confirmation_date)
        //   errors.confirmation_date = "Confirmation Date is required";
        // if (!values.notice_period)
        //   errors.notice_period = "Notice Period is required";
        // if (!values.experience)
        //   errors.experience = "Current Company Experience is required";
        // if (!values.previous_experience)
        //   errors.previous_experience = "Previous Experience is required";
        if (userRole !== "management" && !values.assignedto)
          errors.assignedto = "Please assign manager";
        break;

      case "Current Position":
        // if (!values.costcenter) errors.costcenter = "Cost Center is required";
        if (!values.designation) errors.designation = "Designation is required";
        // if (!values.location) errors.location = "Location is required";
        if (!values.roles) errors.roles = "Roles are required";

        if (
          userRole === "management" &&
          values?.roles === "user" &&
          !values.assignedto
        )
          errors.assignedto = "Please assign manager";
        if (!values.company1) errors.company1 = "Company is required";
        if (!values.storename) errors.storename = "Store Name is required";
        if (!values.attendanceScheme)
          errors.attendanceScheme = "Attendance Scheme is required";
        if (!values.reportingto)
          errors.reportingto = "Reporting To is required";
        if (!values.shift) errors.shift = "Shift is required";
        break;

      case "Documents":
        // if (!values.aadharCard)
        //   errors.aadharCard = "Aadhar Card document is required";
        // if (!values.panCard) errors.panCard = "PAN Card document is required";
        // if (!values.passBook)
        //   errors.passBook = "Passbook or Cancelled Cheque is required";
        break;

      case "Employee Identity":
        // if (!values.aadharnumber)
        //   errors.aadharnumber = "Aadhar Number is required";
        // if (!values.nameasperaadhar)
        // errors.nameasperaadhar = "Name As Per Aadhar is required";
        // if (!values.aadhaarenrolment) {
        //   errors.aadhaarenrolment = "Aadhar Enrolment number is required.";
        // } else if (values.aadhaarenrolment.length !== 28) {
        //   errors.aadhaarenrolment =
        //     "Aadhar Enrolment number must be exactly 28 digits.";
        // }
        // if (!values.permanentaccountnumber)
        //   errors.permanentaccountnumber = "PAN Number is required";
        // if (!values.nameasperpan)
        //   errors.nameasperpan = "Name As Per PAN is required";

        if (values.aadhaarenrolment) {
          const aadhaarEnrolment = String(values.aadhaarenrolment).trim(); // Ensure it's treated as a string

          if (!/^\d{28}$/.test(aadhaarEnrolment)) {
            // If the Aadhar Enrolment number is invalid (not exactly 28 digits), show the error
            errors.aadhaarenrolment =
              "Aadhar Enrolment number must be exactly 28 digits.";
          } else {
            // If the Aadhar Enrolment number is valid, no error
            delete errors.aadhaarenrolment; // Clear the error
          }
        }

        break;

      // case "Education":
      // if (!values.qualification)
      //   errors.qualification = "Qualification is required";
      // if (!values.institutename)
      //   errors.institutename = "Institute Name is required";
      // if (!values.grade) errors.grade = "Grade is required";
      // break;

      case "Address":
        //   if (!values.presentaddress1)
        //     errors.presentaddress1 = "Address 1 is required";
        //   if (!values.presentcountry)
        //     errors.presentcountry = "Country is required";
        //   if (!values.presentstate) errors.presentstate = "State is required";
        //   if (!values.presentcity) errors.presentcity = "City is required";
        //   if (!values.presentpincode)
        //     errors.presentpincode = "Pincode is required";
        if (values.presentmobile.trim() === "") {
          // If the mobile number is empty, remove any error for the field
          delete errors.presentmobile; // No error
        } else if (!/^\d{10}$/.test(values.presentmobile.trim())) {
          // If the mobile number is invalid (not exactly 10 digits), show the error
          errors.presentmobile = "Mobile Number must be only 10 digits";
        } else {
          // If the mobile number is valid (exactly 10 digits), no error
          delete errors.presentmobile; // Clear the error
        }

        break;

      case "Emergency Contact":
        //   if (!values.nameemergency) errors.nameemergency = "Name is required";
        //   if (!values.relationship)
        //     errors.relationship = "Relationship is required";
        //   if (!values.emeraddress1) errors.emeraddress1 = "Address 1 is required";
        //   if (!values.emercountry) errors.emercountry = "Country is required";
        //   if (!values.emerstate) errors.emerstate = "State is required";
        //   if (!values.emercity) errors.emercity = "City is required";
        //   if (!values.emerpincode) errors.emerpincode = "Pincode is required";

        if (values.emermobile.trim() === "") {
          // If the mobile number is empty, remove any error for the field
          delete errors.emermobile; // No error
        } else if (!/^\d{10}$/.test(values.emermobile.trim())) {
          // If the mobile number is invalid (not exactly 10 digits), show the error
          errors.emermobile = "Mobile Number must be only 10 digits";
        } else {
          // If the mobile number is valid (exactly 10 digits), no error
          delete errors.emermobile; // Clear the error
        }
        break;

      // case "Bank Account":
      //   // if (!values.bankname) errors.bankname = "Bank Name is required";
      //   // if (!values.bankbranch) errors.bankbranch = "Bank Branch is required";
      //   if (!values.bankaccountnumber)
      //     errors.bankaccountnumber = "Account Number is required";
      //   // if (!values.iFSC) errors.iFSC = "IFSC is required";
      //   if (!values.iban) errors.iban = "IBAN/Swift Code/BIC is required";
      //   if (!values.accounttype)
      //     errors.accounttype = "Account Type is required";
      //   break;

      // case "ESI Account":
      //   if (!values.esinumber) errors.esinumber = "ESI Number is required";
      //   break;

      case "PF Account":
        //   if (!values.uan) errors.uan = "UAN is required";
        if (values.uan.trim() === "") {
          delete errors.uan;
        } else if (!/^\d{12}$/.test(values.uan.trim())) {
          errors.uan = "UAN Number must be only 12 digits";
        } else {
          delete errors.uan;
        }
        //   if (!values.pf_join) errors.pf_join = "PF Join Date is required";
        if (values.pf_num.trim() === "") {
          delete errors.pf_num; // No error for empty input
        } else if (values.pf_num.trim().length !== 26) {
          errors.pf_num = "PF Number must be exactly 22 characters long.";
        } else if (
          !/^[A-Z]{2}\/[A-Z]{3}\/\d{7}\/\d{3}\/\d{7}$/.test(
            values.pf_num.trim()
          )
        ) {
          errors.pf_num =
            "PF Number format should be (MH/BAN/1234567/000/1234235).";
        } else {
          delete errors.pf_num; // Valid PF Number
        }

        if (values.password && !values.confirmPassword)
          errors.confirmPassword = "Confirm Password is required";
        if (values.confirmPassword != values.password)
          errors.confirmPassword =
            "Password and confirm password are both diffrent";
        if (values.family_pf_number.trim() === "") {
          delete errors.family_pf_number;
        } else if (values.family_pf_number.trim().length !== 26) {
          errors.family_pf_number =
            "PF Number must be exactly 22 characters long.";
        } else if (
          !/^[A-Z]{2}\/[A-Z]{3}\/\d{7}\/\d{3}\/\d{7}$/.test(
            values.family_pf_number.trim()
          )
        ) {
          errors.family_pf_number =
            "PF Number format should be (MH/BAN/1234567/000/1234235).";
        } else {
          delete errors.family_pf_number; // Valid PF Number
        }
        break;

      default:
        break;
    }

    return errors;
  };

  // useEffect(() => {
  //   const initialErrors = {};

  //   tabs.forEach((tab) => {
  //     const activeTabErrors = validate(values, tab.name);

  //     if (Object.keys(activeTabErrors).length > 0) {
  //       initialErrors[tab.name] = activeTabErrors;
  //     }
  //   });

  //   setErrors(initialErrors);
  // }, [values, tabs, setErrors]);

  return (
    <>
      <div className="container mx-auto mt-4 mb-4 ">
        <Formik
          initialValues={{
            // email: "",

            emp_id: updateForm?.emp_id || "",
            designation: updateForm?.designation || "",
            marital: updateForm?.maritalStatus || "",
            roles: updateForm?.role || "",
            assignedto: updateForm?.assignedTo || "",
            title: updateForm?.title || "",
            gender: updateForm?.gender || "",
            nationality: updateForm?.nationality || "",
            residentialstatus: updateForm?.residentialStatus || "",
            religion: updateForm?.religion || "",
            attendanceScheme: updateForm?.attendanceScheme || "",
            reportingto: updateForm?.reportingTo || "",
            bloodgroup: updateForm?.bloodGroup || "",
            mobilenumber: updateForm?.phone || "",
            empl_name: updateForm?.name,
            nick_name: updateForm?.nickName || "",
            birthday: updateForm?.dob
              ? moment(updateForm.dob).format("YYYY/MM/DD")
              : "",
            marriage_date: updateForm?.marriageDate
              ? moment(updateForm.marriageDate).format("YYYY/MM/DD")
              : "",

            spouse_name: updateForm?.spouseName || "",
            previous_experience_type:
              updateForm?.previous_experience_type || "",

            presentcity: updateForm?.presentCity || "",
            presentstate: updateForm?.presentState || "",
            presentcountry: updateForm?.presentCountry || "",
            presentpincode: updateForm?.presentPincode || "",
            aadharnumber: updateForm?.aadharNumber || "",
            bankbranch: updateForm?.bankBranch || "",
            bankaccountnumber: updateForm?.accountNumber || "",
            iFSC: updateForm?.ifscCode || "",
            agency_name: updateForm?.agencyName || "",
            verificationcompleted: updateForm?.verifcationCompletedOn
              ? moment(updateForm.verifcationCompletedOn).format("YYYY-MM-DD")
              : "",
            pf_join: updateForm?.pfJoinDate
              ? moment(updateForm.pfJoinDate).format("YYYY-MM-DD")
              : "",
            esinumber: updateForm?.esiNumber || "",
            uan: updateForm?.uanNumber || "",
            family_pf_number: updateForm?.familyPFNumber || "",
            documenttype: updateForm?.documentType || "",
            loginusername: updateForm?.loginUserName || "",
            employee_email: updateForm?.email || "",
            Place_birth: updateForm?.placeOfBirth || "",
            empl_father: updateForm?.fatherName || "",
            joining_on: updateForm?.joiningOn
              ? moment(updateForm.joiningOn).format("YYYY-MM-DD")
              : "",
            confirmation_date: updateForm?.confirmationDate
              ? moment(updateForm.confirmationDate).format("YYYY-MM-DD")
              : "",
            status: updateForm?.status || "",
            confirmed: moment(updateForm?.confirmed).format("YYYY-MM-DD") || "",
            previous_experience: updateForm?.previousExperience || "",
            experience: updateForm?.currentCompanyExperience || "",
            notice_period: updateForm?.noticePeriod || 0,
            probation_period:
              moment(updateForm?.probationPeriod).format("YYYY-MM-DD") || "",
            totalExperience: updateForm?.totalExperience || "",
            referredBy: updateForm?.referredBy || "",
            costcenter: updateForm?.costCenter || "",
            shift: updateForm?.shift || "",
            staff: updateForm?.staff || "",
            storename: updateForm?.storeName || "",
            location: updateForm?.location || "",
            facerecognition: updateForm?.faceRecognition || "",
            presentaddress1: updateForm?.presentAddress1 || "",
            presentaddress2: updateForm?.presentAddress2 || "",
            presentaddress3: updateForm?.presentAddress3 || "",
            Phone1: updateForm?.presentPhone1 || "",
            phone2: updateForm?.presentPhone2 || "",
            presentext: updateForm?.presentExt || "",
            presentemail: updateForm?.presentEmail || "",
            presentmobile: updateForm?.presentMobile || "",
            presentfax: updateForm?.presentFax || "",
            permanentaddress1: updateForm?.permanentAddress1 || "",
            permanentaddress2: updateForm?.permanentAddress2 || "",
            permanentaddress3: updateForm?.permanentAddress3 || "",
            permanentcity: updateForm?.permanentCity || "",
            permanentstate: updateForm?.permanentState || "",
            permanentcountry: updateForm?.permanentCountry || "",
            permanentpincode: updateForm?.permanentPincode || "",
            permanentphone1: updateForm?.permanentPhone1 || "",
            parmanentphone2: updateForm?.permanentPhone2 || "",
            parmanentemail: updateForm?.permanentEmail || "",
            parmanentmobile: updateForm?.permanentMobile || "",
            parmanentfax: updateForm?.parmanentFax || "",
            parmanentext: updateForm?.permanentExt || "",
            vrificationstatus: updateForm?.verificationStatus || "",
            bankname: updateForm?.bankName || "",
            iban: updateForm?.iban_swift_bic_code || "",
            accounttype: updateForm?.accountType || "",
            paymenttype: updateForm?.paymentType || "",
            bank_records: updateForm?.nameAsPerBank || "",
            dd_Payable: updateForm?.ddPayableAt || "",
            pf_num: updateForm?.pfNumber || "",
            // company2: updateForm?.emp_id || "",
            company1: updateForm?.company || "",
            nameasperaadhar: updateForm?.nameAsPerAadhar || "",
            aadhaarenrolment: updateForm?.aadharEnrolmentNo || "",
            permanentaccountnumber: updateForm?.permanentAccountNumber || "",
            nameasperpan: updateForm?.nameAsPerPan || "",
            qualification: updateForm?.eduQualification || "",
            institutename: updateForm?.eduInstituteName || "",
            grade: updateForm?.eduGrade || "",
            nameemergency: updateForm?.emerContactName || "",
            relationship: updateForm?.emerRelation || "",
            emeraddress1: updateForm?.emeraddress1 || "",
            emeraddress2: updateForm?.emerAddress2 || "",
            emeraddress3: updateForm?.emerAddress3 || "",
            emercity: updateForm?.emerCity || "",
            emerpincode: updateForm?.emerPincode || "",
            emerstate: updateForm?.emerState || "",
            emerfax: updateForm?.emerFax || "",
            emercountry: updateForm?.emerCountry || "",
            emeremail: updateForm?.emerEmail || "",
            emermobile: updateForm?.emerMobile || "",
            emertext: updateForm?.emerExt || "",
            emerphone2: updateForm?.emerPhone2 || "",
            emerphone1: updateForm?.emerPhone1 || "",
            accountnumber: updateForm?.emerMobile || "",
            empolyeedocumenttype: updateForm?.pfDocumentType || "",
            empolyeebankname: updateForm?.empIdentBankName || "",
            nameinbankaccount: updateForm?.empIdenNameInBank || "",
            bankifsc: updateForm?.empIdentIfsc || null,
            image: updateForm?.profileImage || null,
            aadharCard: updateForm?.aadharCardDocument || null,
            panCard: updateForm?.panCardDocument || null,
            passBook: updateForm?.passBookOrCancelledCheque || null,
            relievingLetter: updateForm?.prevOrgRelivingLetter || null,
            paySlip: updateForm?.prevOrgPayslip || null,
            adminId: updateForm?.adminId || null,
            password: null,
            confirmPassword: null,
          }}
          validateOnBlur={true}
          validateOnChange={false}
          validate={(values) => validate(values, activeTab)}
          validateOnMount={true}
          onSubmit={(values, { setSubmitting }) => {
            setLoader(true);
            setNewErrors(true);

            const userData = new FormData();

            if (Object.keys(errors).length == 0) {
              if (values.image) {
                userData.append("profileImage", values.image);
              }
              userData.append("name", values.empl_name);
              userData.append("designation", values.designation);
              userData.append("user_type", values.roles);
              userData.append("title", values.title);
              userData.append("gender", values.gender);
              userData.append("maritalStatus", values.marital);
              userData.append("nationality", values.nationality);
              userData.append("residentialStatus", values.residentialstatus);
              userData.append("placeOfBirth", values.Place_birth);
              userData.append("religion", values.religion);
              userData.append("attendanceScheme", values.attendanceScheme);
              userData.append("reportingTo", values.reportingto);
              userData.append("phone", values.mobilenumber);
              userData.append("nickName", values.nick_name);
              userData.append("dob", values.birthday);
              userData.append("marriageDate", values.marriage_date);
              userData.append("spouseName", values.spouse_name);
              userData.append("presentCity", values.presentcity);
              userData.append("presentState", values.presentstate);
              userData.append("presentCountry", values.presentcountry);
              userData.append("presentPincode", values.presentpincode);
              userData.append("aadharNumber", values.aadharnumber);
              userData.append("nameAsPerAadhar", values.nameasperaadhar);
              userData.append("bankBranch", values.bankbranch);
              userData.append("accountNumber", values.bankaccountnumber);
              userData.append("ifscCode", values.iFSC);
              userData.append("password", values.password);
              userData.append("bankName", values.bankname);
              userData.append(
                "verifcationCompletedOn",
                values.verificationcompleted
              );
              userData.append("esiNumber", values.esinumber);
              userData.append("uanNumber", values.uan);
              userData.append("familyPFNumber", values.family_pf_number);
              userData.append("documentType", values.documenttype);
              userData.append("pfJoinDate", values.pf_join);
              userData.append("assignedTo", values.assignedto);
              userData.append("loginUserName", values.loginusername);
              userData.append("email", values.employee_email);
              userData.append("bloodGroup", values.bloodgroup);
              userData.append("fatherName", values.empl_father);
              userData.append("joiningOn", values.joining_on);
              userData.append("confirmationDate", values.confirmation_date);
              userData.append("confirmed", values.confirmed);
              userData.append("probationPeriod", values.probation_period);
              userData.append("noticePeriod", values.notice_period);
              userData.append("currentCompanyExperience", values.experience);
              userData.append("previousExperience", values.previous_experience);
              userData.append("referredBy", values.referredBy);
              userData.append("costCenter", values.costcenter);
              userData.append("location", values.location);
              userData.append("company", values.company1);
              // userData.append("company2", values.company2);
              userData.append("totalExperience", values.totalExperience);
              userData.append("shift", values.shift);
              userData.append("staff", values.staff);
              userData.append("storeName", values.storename);
              userData.append("faceRecognition", values.facerecognition);
              userData.append("presentAddress1", values.presentaddress1);
              userData.append("presentAddress2", values.presentaddress2);
              userData.append("presentAddress3", values.presentaddress3);
              userData.append("presentPhone1", values.Phone1);
              userData.append("presentPhone2", values.phone2);
              userData.append("presentMobile", values.presentmobile);
              userData.append("presentExt", values.presentext);
              userData.append("presentEmail", values.presentemail);
              userData.append("presentFax", values.presentfax);
              userData.append("permanentAddress1", values.permanentaddress1);
              userData.append("permanentAddress2", values.permanentaddress2);
              userData.append("permanentAddress3", values.permanentaddress3);
              userData.append("permanentCity", values.permanentcity);
              userData.append("permanentState", values.permanentstate);
              userData.append("permanentCountry", values.permanentcountry);
              userData.append("permanentPincode", values.permanentpincode);
              userData.append("permanentEmail", values.parmanentemail);
              userData.append("permanentPhone1", values.permanentphone1);
              userData.append("permanentPhone2", values.parmanentphone2);
              userData.append("permanentExt", values.parmanentext);
              userData.append("parmanentFax", values.parmanentfax);
              userData.append("permanentMobile", values.parmanentmobile);
              userData.append("verificationStatus", values.vrificationstatus);
              userData.append("status", values.status);
              userData.append("agencyName", values.agency_name);
              userData.append("iban_swift_bic_code", values.iban);
              userData.append("accountType", values.accounttype);
              userData.append("paymentType", values.paymenttype);
              userData.append("nameAsPerBank", values.bank_records);
              userData.append("pfNumber", values.pf_num);
              userData.append("ddPayableAt", values.dd_Payable);
              userData.append("employeeCoveredUnderEsi", checkedItems.underESI);
              userData.append(
                "isExistingMemberOfEPS",
                checkedItems.memberofeps
              );
              userData.append(
                "allowEpfExcessContribution",
                checkedItems.allowEpf
              );

              userData.append("employeeCoveredUnderPf", checkedItems.underpf);
              userData.append("empIdenNameInBank", values.nameinbankaccount);
              userData.append("aadharEnrolmentNo", values.aadhaarenrolment);
              userData.append("empIdentAccountNo", values.accountnumber);
              userData.append("empIdentBankName", values.empolyeebankname);
              userData.append(
                "permanentAccountNumber",
                values.permanentaccountnumber
              );
              userData.append("empIdentIfsc", values.bankifsc);
              userData.append("pfDocumentType", values.empolyeedocumenttype);
              userData.append("nameAsPerPan", values.nameasperpan);
              userData.append("eduQualification", values.qualification);
              userData.append("eduInstituteName", values.institutename);
              userData.append("eduGrade", values.grade);
              userData.append("emerContactName", values.nameemergency);
              userData.append("emerRelation", values.relationship);
              userData.append("emerAddress1", values.emeraddress1);
              userData.append("emerAddress2", values.emeraddress2);
              userData.append("emerAddress3", values.emeraddress3);
              userData.append("emerCity", values.emercity);
              userData.append("emerState", values.emerstate);
              userData.append("emerCountry", values.emercountry);
              userData.append("emerPincode", values.emerpincode);
              userData.append("emerPhone1", values.emerphone1);
              userData.append("emerPhone2", values.emerphone2);
              userData.append("emerExt", values.emertext);
              userData.append("emerFax", values.emerfax);
              userData.append("emerEmail", values.emeremail);
              userData.append("emerMobile", values.emermobile);
              userData.append("aadharCardDocument", values.aadharCard);
              userData.append("panCardDocument", values.panCard);
              userData.append("passBookOrCancelledCheque", values.passBook);
              userData.append("prevOrgRelivingLetter", values.relievingLetter);
              userData.append("prevOrgPayslip", values.paySlip);
              userData.append("userActive", checkedItems.userActive);
              if (userId) {
                setTimeout(() => {
                  dispatch(updateUserData(userId, userData, navigate));
                }, 600);
                setSubmitting(false);
              }
            }
          }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            setFieldTouched,

            /* and other goodies */
          }) => {
            const handleImage = (e) => {
              const { name, files } = e.target;

              if (files && files[0]) {
                if (!files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
                  toast.error("file type must be  .png, jpg, jpeg,");
                } else {
                  setFieldValue(name, files[0]);
                  setImagePreview(URL.createObjectURL(files[0]));
                }
              }
            };

            const confirmedDateChangeHandler2 = (
              setFieldValue,
              dates,
              name
            ) => {
              const originalDate = new Date(dates);

              // Add 179 days
              originalDate.setDate(originalDate.getDate() + 179);

              // Format the new date to YYYY-MM-DD
              const newFormattedDate = originalDate.toLocaleDateString("en-CA");

              setFieldValue(name, newFormattedDate);
            };
            const probationDateChangeHandler2 = (
              setFieldValue,
              dates,
              name
            ) => {
              const originalDate = new Date(dates);

              // Add 179 days
              originalDate.setDate(originalDate.getDate() + 180);

              // Format the new date to YYYY-MM-DD
              const newFormattedDate = originalDate.toLocaleDateString("en-CA");

              setFieldValue(name, newFormattedDate);
            };

            const changesforTotalExpiriance = (
              item,
              setFieldValue,
              name,
              name2,
              itemss,
              key
            ) => {
              setFieldValue(name2, item?.target.value);

              const newData = `${String(
                key
                  ? item?.target.value
                  : values?.previous_experience + values?.experience
              )} ${itemss ? itemss : values?.previous_experience_type}`;

              console.log(newData);

              setFieldValue(name, newData);
            };

            console.log({ values });

            const handleChange2 = (
              e,
              setFieldValue,
              data,
              confirmed,
              probation
            ) => {
              if (data == "joinDate") {
                setFieldValue(e.target.name, e.target.value);
                confirmedDateChangeHandler2(
                  setFieldValue,
                  e.target.value,
                  confirmed
                );
                probationDateChangeHandler2(
                  setFieldValue,
                  e.target.value,
                  probation
                );

                setTimeout(() => {
                  setFieldValue("pf_join", e.target.value);
                }, 200);
              }
            };
            const handleNext = (values, touched, setFieldTouched, tab) => {
              const activeTabErrors = validate(values, tab);

              // If tab is "Documents", handle document upload
              // if (tab === "Documents") {
              //   dispatch(getDocumentUpload(nextid));
              // }

              // Mark fields as touched so errors appear
              Object.keys(activeTabErrors).forEach((field) =>
                setFieldTouched(field, true)
              );

              if (Object.keys(activeTabErrors).length > 0) {
                // Merge the old errors with the current tab's errors
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  [tab]: activeTabErrors, // Keep errors specific to the current tab
                }));
                return;
              }

              // If no errors in the current tab, remove errors for this tab and move to the next tab
              setErrors((prevErrors) => {
                const newErrors2 = { ...prevErrors };
                delete newErrors2[tab]; // Remove errors for the current tab if validated
                return newErrors2;
              });

              // Move to the next tab if no errors
              const currentIndex = tabs.findIndex((t) => t.name === tab);
              setActiveTab(tabs[currentIndex + 1]?.name || tab);
            };

            return (
              <>
                <div className="top_bottomEmployees">
                  <h4> Employee Detail </h4>
                </div>
                <div className="row d-flex  justify-content-start  pt-3  gap-3">
                  <div className="col-12">
                    <div
                      className="card"
                      style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
                    >
                      <div className="card-body ">
                        <div className="card_flex">
                          <div className="flex_img_text">
                            <div class="upload-section">
                              <div class="upload-container">
                                <input type="file" accept="image/*" />
                                <span class="icon">
                                  <img
                                    src={updateForm?.profileImage}
                                    alt="No image available"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="text_name  pt-3">
                              <h6>
                                {updateForm?.title &&
                                  updateForm.title.charAt(0).toUpperCase() +
                                    updateForm.title.slice(1)}{" "}
                                &nbsp;
                                {updateForm?.name}
                              </h6>
                              <p>{updateForm?.name}</p>
                              <p>{updateForm?.designationName?.name}</p>
                            </div>
                          </div>
                          <div className="border_dash_right"></div>
                          <div className="detail_wrap">
                            <div className="pad_wrap">
                              <p>Phone:</p>
                              <p>+91 {updateForm?.phone}</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Email:</p>
                              <p>{updateForm?.email}</p>
                            </div>
                            {/* <div className="pad_wrap">
                              <p>Employee Nick Name:</p>
                              <p> {updateForm?.nickName}</p>
                            </div> */}
                            <div className="pad_wrap">
                              <p>Gender:</p>
                              <p>{updateForm?.gender}</p>
                            </div>
                            <div className="pad_wrap">
                              <p>Report to:</p>
                              <p>{updateForm?.reportToName?.name}</p>
                            </div>
                          </div>
                          <div className="border_dash_right"></div>
                          <div className="detail_wrap">
                            <div className="pad_wrap">
                              <p>Check In:</p>
                              <p>
                                {" "}
                                {convertTo12HourIST(
                                  updateForm?.todayAttendance?.check_in_time
                                )}
                              </p>
                            </div>
                            <div className="pad_wrap">
                              <p>Check Out:</p>
                              <p>
                                {convertTo12HourIST(
                                  updateForm?.todayAttendance?.check_out_time
                                )}{" "}
                              </p>
                            </div>
                            <div className="pad_wrap">
                              <p>Total Working Hours:</p>
                              <p>
                                {updateForm?.todayAttendance?.working_hour
                                  ? updateForm?.todayAttendance?.working_hour
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div
                      className="card"
                      style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
                    >
                      <div className="card-body">
                        <div className="d-flex justify-content-start  align-item-start">
                          {" "}
                          <div
                            className={`tab d-flex gap-3 ${
                              isMobile == "true"
                                ? "flex-column w-100 overflow-scroll justify-content-start"
                                : "flex"
                            } `}
                            style={{ height: "110px" }}
                          >
                            {/* {tabs.map((tab) => (
                              <button
                                key={tab.name}
                                className={`tab_button ${
                                  activeTab === tab.name ? "active" : ""
                                }`}
                                onClick={() => handleTabClick(tab.name)}
                              >
                                {tab.name}
                              </button>
                            ))} */}

                            {tabs.map((tab) => {
                              let hasErrors = false;

                              if (newErrors) {
                                const tabErrors = validate(values, tab.name);

                                hasErrors = Object.keys(tabErrors).length > 0;
                              }

                              return (
                                <button
                                  key={tab.name}
                                  className={`tab_button ${
                                    activeTab === tab.name
                                      ? "active"
                                      : hasErrors
                                      ? "activeness"
                                      : ""
                                  } ${isMobile && "p-0"}`}
                                  onClick={() =>
                                    handleTabClick(
                                      values,
                                      setFieldTouched,
                                      tab.name
                                    )
                                  }
                                  // style={hasErrors ? { color: "red" } : {}}
                                >
                                  {tab.name}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
                  {activeTab === "Employee Information" && (
                    <div className="info_btm">
                      <p>Employee Information</p>
                    </div>
                  )}
                  {activeTab === "Employee Information" && (
                    <div className="upload-section">
                      <div className="upload-container">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          onChange={(e) => handleImage(e, setFieldValue)}
                        />
                        <span className="icon">
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              alt="Uploaded Preview"
                              style={{ width: "100%", height: "100%" }}
                            />
                          ) : updateForm?.profileImage ? (
                            <img
                              src={updateForm?.profileImage}
                              alt="Uploaded Preview"
                              style={{ width: "100%", height: "100%" }}
                            />
                          ) : (
                            <p>No image available</p>
                          )}
                        </span>
                      </div>
                      {errors.image && touched.image && (
                        <div className="error-message">{errors.image}</div>
                      )}
                    </div>
                  )}
                  {activeTab === "Employee Information" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col " key={field.name}>
                              <InputField
                                label="Employee Number"
                                inputType="tel"
                                inputPlaceholder="Enter Employee Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emp_id"
                                value={values.emp_id}
                                onChange={handleChange}
                                disabled={values.emp_id !== "emp_id"}
                              />
                              {/* {errors.emp_id &&
                                touched.emp_id && (
                                  <div className="error-message">
                                    {errors.emp_id}
                                  </div>
                                )} */}
                            </div>
                            <div className="row_col" key={field.name}>
                              <Selector
                                label="Title"
                                options={titleoptions}
                                name="title"
                                value={values.title}
                                onChange={(e) =>
                                  setFieldValue("title", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                                disabled={values.title !== "title"}
                              />

                              {errors.title && touched.title && (
                                <div className="error-message">
                                  {errors.title}
                                </div>
                              )}
                            </div>
                            <div className="row_col" key={field.name}>
                              <InputField
                                label=" Employee Name"
                                inputType="text"
                                inputPlaceholder="Enter Employee Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empl_name"
                                value={values?.empl_name}
                                onChange={handleChange}
                              />
                              {errors.empl_name && touched.empl_name && (
                                <div className="error-message">
                                  {errors.empl_name}
                                </div>
                              )}
                            </div>
                            {/* <div className="row_col" key={field.name}>
                              <InputField
                                label="Employee Nick Name"
                                inputType="text"
                                inputPlaceholder="Enter Employee Nick Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nick_name"
                                value={values.nick_name}
                                onChange={handleChange}
                              />
                              {errors.nick_name && touched.nick_name && (
                                <div className="error-message">
                                  {errors.nick_name}
                                </div>
                              )}
                            </div> */}
                            <div className="row_col">
                              <Selector
                                label="Gender"
                                options={genderoptions}
                                name="gender"
                                value={values.gender}
                                onChange={(e) =>
                                  setFieldValue("gender", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />

                              {errors.gender && touched.gender && (
                                <div className="error-message">
                                  {errors.gender}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            {/* <div className="row_col">
                              <InputField
                                label="Employee Login Username"
                                inputType="text"
                                inputPlaceholder="Enter Employee Login Username"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="loginusername"
                                value={values.loginusername}
                                onChange={handleChange}
                              />
                              {errors.loginusername &&
                                touched.loginusername && (
                                  <div className="error-message">
                                    {errors.loginusername}
                                  </div>
                                )}
                            </div> */}
                            <div className="row_col">
                              <InputField
                                label="Mobile Number"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="mobilenumber"
                                value={values.mobilenumber}
                                onChange={handleChange}
                                max={10}
                              />
                              {errors.mobilenumber && touched.mobilenumber && (
                                <div className="error-message">
                                  {errors.mobilenumber}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Employee Email"
                                inputType="email"
                                inputPlaceholder="Enter Employee Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="employee_email"
                                value={values.employee_email}
                                onChange={handleChange}
                              />
                              {errors.employee_email &&
                                touched.employee_email && (
                                  <div className="error-message">
                                    {errors.employee_email}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Date of Birth"
                                inputType="date"
                                inputPlaceholder="DD/MM/YYYY"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="birthday"
                                value={moment(
                                  values?.birthday,
                                  "YYYY/MM/DD"
                                ).format("YYYY-MM-DD")}
                                onChange={handleChange}
                              />
                              {errors.birthday && touched.birthday && (
                                <div className="error-message">
                                  {errors.birthday}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Blood Group"
                                options={options}
                                name="bloodgroup"
                                value={values.bloodgroup}
                                onChange={(e) =>
                                  setFieldValue("bloodgroup", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />
                              {errors.bloodgroup && touched.bloodgroup && (
                                <div className="error-message">
                                  {errors.bloodgroup}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Employee Information" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Father’s Name"
                                inputType="text"
                                inputPlaceholder="Enter Father’s Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="empl_father"
                                value={values?.empl_father}
                                onChange={handleChange}
                              />
                              {errors.empl_father && touched.empl_father && (
                                <div className="error-message">
                                  {errors.empl_father}
                                </div>
                              )}
                            </div>
                            <div className="row_col ">
                              <Selector
                                label="Nationality"
                                options={nationalityOption}
                                name="nationality"
                                value={values.nationality}
                                onChange={(e) =>
                                  setFieldValue("nationality", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.nationality && touched.nationality && (
                                <div className="error-message">
                                  {errors.nationality}
                                </div>
                              )}
                            </div>

                            <div className="row_col ">
                              <Selector
                                label="Residential Status"
                                options={residentialstatusoptions}
                                name="residentialstatus"
                                value={values.residentialstatus}
                                onChange={(e) =>
                                  setFieldValue(
                                    "residentialstatus",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.residentialstatus &&
                                touched.residentialstatus && (
                                  <div className="error-message">
                                    {errors.residentialstatus}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Place of Birth"
                                options={placeofoptions}
                                name="Place_birth"
                                value={values.Place_birth}
                                onChange={(e) =>
                                  setFieldValue("Place_birth", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.Place_birth && touched.Place_birth && (
                                <div className="error-message">
                                  {errors.Place_birth}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex_input">
                            <div className="row_col  custom-width-50 ">
                              <Selector
                                custom-width-50
                                label="Religion"
                                options={religionoptions}
                                name="religion"
                                value={values.religion}
                                onChange={(e) =>
                                  setFieldValue("religion", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.religion && touched.religion && (
                                <div className="error-message">
                                  {errors.religion}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Marital Status"
                                options={maritalStatus}
                                name="marital"
                                value={values.marital}
                                onChange={(e) =>
                                  setFieldValue("marital", e.target.value)
                                }
                                LabelFontSize="14px"
                                SelectorFontSize="16"
                              />
                              {errors.marital && touched.marital && (
                                <div className="error-message">
                                  {errors.marital}
                                </div>
                              )}
                            </div>
                            {values?.marital !== "single" ? (
                              <>
                                <div className="row_col custom-width-50">
                                  <InputField
                                    label="Marriage Date"
                                    inputType="date"
                                    inputPlaceholder="DD/MM/YYYY"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="marriage_date"
                                    value={moment(
                                      values?.marriage_date,
                                      "YYYY/MM/DD"
                                    ).format("YYYY-MM-DD")}
                                    onChange={handleChange}
                                  />
                                  {errors.marriage_date &&
                                    touched.marriage_date && (
                                      <div className="error-message">
                                        {errors.marriage_date}
                                      </div>
                                    )}
                                </div>
                                <div className="row_col custom-width-50">
                                  <InputField
                                    label="Spouse Name"
                                    inputType="text"
                                    inputPlaceholder="Enter Spouse Name"
                                    InputFontSize="16px"
                                    LabelFontSize="16px"
                                    name="spouse_name"
                                    value={values.spouse_name}
                                    onChange={handleChange}
                                  />
                                  {errors.spouse_name &&
                                    touched.spouse_name && (
                                      <div className="error-message">
                                        {errors.spouse_name}
                                      </div>
                                    )}
                                </div>
                              </>
                            ) : null}
                          </div>
                          {/* <div className="flex_input">
                         
                          </div> */}
                        </>
                      ))}
                  {activeTab === "Joining Details" && (
                    <div className="info_btm">
                      <p>Joining Details</p>
                    </div>
                  )}
                  {activeTab === "Joining Details" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Joining On"
                                inputType="date"
                                inputPlaceholder="Enter Joining On"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                onChange={(e) =>
                                  handleChange2(
                                    e,
                                    setFieldValue,
                                    "joinDate",
                                    "confirmed",
                                    "probation_period"
                                  )
                                }
                                name="joining_on"
                                value={values?.joining_on}
                              />
                              {/* {errors.joining_on && touched.joining_on && (
                                <div className="error-message">
                                  {errors.joining_on}
                                </div>
                              )} */}
                            </div>
                            {/* <div className="row_col">
                              <InputField
                                label="Confirmation Date"
                                inputType="date"
                                inputPlaceholder="Enter Confirmation Date"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmation_date"
                                value={moment(
                                  values?.confirmation_date,
                                  "YYYY/MM/DD"
                                ).format("YYYY-MM-DD")}
                                onChange={handleChange} */}
                            {/* // disabled={ */}
                            {/* //   values.confirmation_date !==
                                //   "confirmation_date"
                                // }
                              // /> */}
                            {/* {errors.confirmation_date &&
                                touched.confirmation_date && (
                                  <div className="error-message">
                                    {errors.confirmation_date}
                                  </div>
                                )} */}
                            {/* </div> */}
                            <div className="row_col">
                              <Selector
                                label="Status"
                                options={statusoptions}
                                name="status"
                                value={values.status}
                                onChange={(e) =>
                                  setFieldValue("status", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                // disabled={values.status !== "status"}
                              />

                              {/* {errors.status && touched.status && (
                                <div className="error-message">
                                  {errors.status}
                                </div>
                              )} */}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Confirmed"
                                inputType="date"
                                inputPlaceholder="Confirmed"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmed"
                                value={values.confirmed}
                                onChange={handleChange}
                                // disabled={values.confirmed !== "confirmed"}
                              />
                              {/* {errors.confirmed && touched.confirmed && (
                                <div className="error-message">
                                  {errors.confirmed}
                                </div>
                              )} */}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Probation Period"
                                inputType="date"
                                inputPlaceholder="Enter Probation Period"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="probation_period"
                                value={values.probation_period}
                                onChange={handleChange}
                              />
                              {/* {errors.probation_period &&
                                touched.probation_period && (
                                  <div className="error-message">
                                    {errors.probation_period}
                                  </div>
                                )} */}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Notice Period"
                                inputType="number"
                                inputPlaceholder="Enter Notice Period"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="notice_period"
                                value={values.notice_period}
                                onChange={handleChange}
                                disabled={
                                  values.notice_period !== "notice_period"
                                }
                              />
                              {/* {errors.notice_period &&
                                touched.notice_period && (
                                  <div className="error-message">
                                    {errors.notice_period}
                                  </div>
                                )} */}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Current Company Experience"
                                inputType="number"
                                inputPlaceholder="Enter Current Company Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="experience"
                                value={values.experience || 0}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.experience && touched.experience && (
                                <div className="error-message">
                                  {errors.experience}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Previous Experience"
                                options={experience}
                                name="previous_experience"
                                value={values.previous_experience}
                                onChange={(selectedOption) => {
                                  changesforTotalExpiriance(
                                    selectedOption,
                                    setFieldValue,
                                    "totalExperience",
                                    "previous_experience",
                                    false,
                                    true
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="48%"
                              />
                              {errors.previous_experience &&
                                touched.previous_experience && (
                                  <div className="error-message">
                                    {errors.previous_experience}
                                  </div>
                                )}
                              <Selector
                                label="Experience Type"
                                options={[
                                  { label: "Month", value: "month" },
                                  { label: "Year", value: "year" },
                                ]}
                                width="50%"
                                name="previous_experience_type"
                                value={values.previous_experience_type}
                                onChange={(selectedOption) =>
                                  changesforTotalExpiriance(
                                    selectedOption,
                                    setFieldValue,
                                    "totalExperience",
                                    "previous_experience_type",
                                    selectedOption?.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.previous_experience &&
                                touched.previous_experience && (
                                  <div className="error-message">
                                    {errors.previous_experience}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Total Experience"
                                inputType="tel"
                                inputPlaceholder="Enter Total Experience"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="totalExperience"
                                value={values.totalExperience}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.totalExperience &&
                                touched.totalExperience && (
                                  <div className="error-message">
                                    {errors.totalExperience}
                                  </div>
                                )}
                            </div>
                          </div>
                          {userRole !== "management" && (
                            <div className="flex_input">
                              <div className="row_col  custom-width-50 ">
                                <Selector
                                  label="Referred By"
                                  options={referredByUsers}
                                  name="referredBy"
                                  value={values.referredBy}
                                  onChange={(selectedOption) => {
                                    setFieldValue(
                                      "referredBy",
                                      selectedOption?.target.value
                                    );
                                    setFieldValue(
                                      "referred_id",
                                      selectedOption?.target.value
                                    );
                                  }}
                                  LabelFontSize="16px"
                                  SelectorFontSize="16"
                                />
                                {errors.referredBy && (
                                  <div className="error-message">
                                    {errors.referredBy}
                                  </div>
                                )}
                              </div>
                              <div className="row_col  custom-width-50 ">
                                <Selector
                                  label="Assigned To"
                                  options={userOptions}
                                  name="assignedto"
                                  showStar={true}
                                  value={values.assignedto}
                                  onChange={(selectedOption) => {
                                    setFieldValue(
                                      "assignedto",
                                      selectedOption?.target.value
                                    );
                                    setFieldValue(
                                      "managerId",
                                      selectedOption?.target.value
                                    );
                                  }}
                                  LabelFontSize="16px"
                                  SelectorFontSize="16"
                                />
                                {errors.assignedto && (
                                  <div className="error-message">
                                    {errors.assignedto}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  {activeTab === "Current Position" && (
                    <div className="info_btm">
                      <p>Current Position</p>
                    </div>
                  )}
                  {activeTab === "Current Position" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            {/* <div className="row_col">
                              <Selector
                                label="Cost Center"
                                options={companybyList}
                                name="costcenter"
                                value={values.costcenter}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "costcenter",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "costcenter",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.costcenter && (
                                <div className="error-message">
                                  {errors.costcenter}
                                </div>
                              )}
                            </div> */}
                            <div className="row_col">
                              <Selector
                                label="Designation"
                                options={rolebyList}
                                showStar={true}
                                name="designation"
                                value={values.designation}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "designation",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "designation",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.designation && (
                                <div className="error-message">
                                  {errors.designation}
                                </div>
                              )}
                            </div>
                            {/* <div className="row_col">
                              <Selector
                                label="Location"
                                options={companyUsers}
                                name="location"
                                value={values.location}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "location",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "location",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.location && (
                                <div className="error-message">
                                  {errors.location}
                                </div>
                              )}
                            </div> */}

                            <div className="row_col">
                              <Selector
                                label="Roles"
                                options={rolesbyusers}
                                showStar={true}
                                name="roles"
                                value={values?.roles}
                                onChange={(e) => {
                                  setFieldValue("roles", e?.target.value);
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.roles && (
                                <div className="error-message">
                                  {errors.roles}
                                </div>
                              )}
                            </div>

                            {userRole == "management" && (
                              <div className="row_col">
                                <Selector
                                  label="Assign Admin"
                                  options={allAdminsList}
                                  showStar={true}
                                  name="roles"
                                  value={values?.adminId}
                                  onChange={(e) => {
                                    const selectedValue = e?.target.value;

                                    // Set "adminId" field value
                                    setFieldValue("adminId", selectedValue);

                                    // Conditionally set "assignedto" field value
                                    if (values?.roles !== "user") {
                                      setFieldValue(
                                        "assignedto",
                                        selectedValue
                                      );
                                    }

                                    // Dispatch action to get users
                                    dispatch(
                                      getUsersAll(false, false, selectedValue)
                                    );
                                  }}
                                  LabelFontSize="16px"
                                  SelectorFontSize="16"
                                />
                                {errors.adminId && (
                                  <div className="error-message">
                                    {errors.adminId}
                                  </div>
                                )}
                              </div>
                            )}

                            {userRole === "management" && (
                              <>
                                <div className="row_col  ">
                                  <Selector
                                    label="Referred By"
                                    options={referredByUsers}
                                    name="referredBy"
                                    value={values.referredBy}
                                    onChange={(selectedOption) => {
                                      console.log(selectedOption?.target.value);

                                      setFieldValue(
                                        "referredBy",
                                        selectedOption?.target.value
                                      );
                                      // setFieldValue(
                                      //   "referred_id",
                                      //   selectedOption?.target.value
                                      // );
                                    }}
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                  />
                                  {errors.referredBy && (
                                    <div className="error-message">
                                      {errors.referredBy}
                                    </div>
                                  )}
                                </div>

                                {values?.roles === "user" && (
                                  <div className="row_col  ">
                                    <Selector
                                      label="Assigned To"
                                      options={userOptions}
                                      name="assignedto"
                                      showStar={true}
                                      value={values.assignedto}
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "assignedto",
                                          selectedOption?.target.value
                                        );
                                        setFieldValue(
                                          "managerId",
                                          selectedOption?.target.value
                                        );
                                      }}
                                      LabelFontSize="16px"
                                      SelectorFontSize="16"
                                    />
                                    {errors.assignedto && (
                                      <div className="error-message">
                                        {errors.assignedto}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}

                            {userRole !== "management" && (
                              <>
                                <div className="row_col">
                                  <Selector
                                    label="Company"
                                    options={companybyList}
                                    name="company1"
                                    showStar={true}
                                    value={values.company1}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "company1",
                                        selectedOption?.target.value
                                      );
                                      dispatch(
                                        getStoreByCompany(
                                          selectedOption?.target.value
                                        )
                                      );
                                    }}
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                  />
                                  {errors.company1 && (
                                    <div className="error-message">
                                      {errors.company1}
                                    </div>
                                  )}
                                </div>
                                <div className="row_col">
                                  <Selector
                                    label="Store Name "
                                    options={storeUsers}
                                    name="storename"
                                    showStar={true}
                                    value={values.storename}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "storename",
                                        selectedOption?.target.value
                                      );
                                      // setFieldValue(
                                      //   "store_id",
                                      //   selectedOption?.target.value
                                      // );
                                    }}
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                  />
                                  {errors.storename && (
                                    <div className="error-message">
                                      {errors.storename}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="flex_input">
                            {userRole === "management" && (
                              <>
                                <div className="row_col">
                                  <Selector
                                    label="Company"
                                    options={companybyList}
                                    name="company1"
                                    showStar={true}
                                    value={values.company1}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "company1",
                                        selectedOption?.target.value
                                      );
                                      dispatch(
                                        getStoreByCompany(
                                          selectedOption?.target.value
                                        )
                                      );
                                    }}
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                  />
                                  {errors.company1 && (
                                    <div className="error-message">
                                      {errors.company1}
                                    </div>
                                  )}
                                </div>
                                <div className="row_col">
                                  <Selector
                                    label="Store Name "
                                    options={storeUsers}
                                    name="storename"
                                    showStar={true}
                                    value={values.storename}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "storename",
                                        selectedOption?.target.value
                                      );
                                      // setFieldValue(
                                      //   "store_id",
                                      //   selectedOption?.target.value
                                      // );
                                    }}
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                  />
                                  {errors.storename && (
                                    <div className="error-message">
                                      {errors.storename}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                            {/* <div className="row_col">
                              <Selector
                                label="Company"
                                options={companybyList}
                                name="company1"
                                showStar={true}
                                value={values.company1}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "company1",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "company1",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.company1 && (
                                <div className="error-message">
                                  {errors.company1}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Store Name "
                                options={storeUsers}
                                name="storename"
                                showStar={true}
                                value={values.storename}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "storename",
                                    selectedOption?.target.value
                                  );
                                  // setFieldValue(
                                  //   "store_id",
                                  //   selectedOption?.target.value
                                  // );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.storename && (
                                <div className="error-message">
                                  {errors.storename}
                                </div>
                              )}
                            </div> */}
                            <div className="row_col">
                              <Selector
                                label="Attendance Scheme"
                                showStar={true}
                                options={attendanceSchemeoptions}
                                name="attendanceScheme"
                                value={values.attendanceScheme}
                                onChange={(e) =>
                                  setFieldValue(
                                    "attendanceScheme",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.attendanceScheme &&
                                touched.attendanceScheme && (
                                  <div className="error-message">
                                    {errors.attendanceScheme}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="Reporting To"
                                options={referredByUsers}
                                showStar={true}
                                name="reportingto"
                                value={values.reportingto}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "reportingto",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "managerId",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.reportingto && (
                                <div className="error-message">
                                  {errors.reportingto}
                                </div>
                              )}
                            </div>

                            {userRole !== "management" && (
                              <>
                                <div className="row_col">
                                  <Selector
                                    label="Shift"
                                    options={shifbytList}
                                    name="shift"
                                    value={
                                      values.shift[0]?._id || values.shift[0]
                                    }
                                    showStar={true}
                                    onChange={(e) =>
                                      setFieldValue("shift", [e.target.value])
                                    }
                                    LabelFontSize="16px"
                                    SelectorFontSize="16px"
                                    width="100%"
                                    // multi={true}
                                  />
                                  {errors.shift && (
                                    <div className="error-message">
                                      {errors.shift}
                                    </div>
                                  )}
                                </div>
                                <div className="row_col">
                                  <Selector
                                    label="Face Recognition*"
                                    options={facerecognitionoptions}
                                    showStar={true}
                                    name="facerecognition"
                                    value={values.facerecognition}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "facerecognition",
                                        e.target.value
                                      )
                                    }
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                    width="100%"
                                  />

                                  {errors.facerecognition &&
                                    touched.facerecognition && (
                                      <div className="error-message">
                                        {errors.facerecognition}
                                      </div>
                                    )}
                                </div>
                              </>
                            )}

                            {/* <div className="row_col custom-width-50">
                              <Selector
                                label="Staff"
                                options={referredByUsers}
                                name="staff"
                                value={values.staff}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "staff",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "managerId",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.staff && (
                                <div className="error-message">
                                  {errors.staff}
                                </div>
                              )}
                            </div> */}
                          </div>
                          {/* 
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <CustomSelector
                                label="Shift"
                                options={shifbytList}
                                name="shift"
                                value={values.shift}
                                showStar={true}
                                onChange={(e) =>
                                  setFieldValue("shift", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16px"
                                width="100%"
                                multi={true}
                              />
                              {errors.shift && (
                                <div className="error-message">
                                  {errors.shift}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Staff"
                                options={referredByUsers}
                                name="staff"
                                value={values.staff}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "staff",
                                    selectedOption?.target.value
                                  );
                                  setFieldValue(
                                    "managerId",
                                    selectedOption?.target.value
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />
                              {errors.staff && (
                                <div className="error-message">
                                  {errors.staff}
                                </div>
                              )}
                            </div>
                            
                            {console.log(values)}
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Face Recognition*"
                                options={facerecognitionoptions}
                                showStar={true}
                                name="facerecognition"
                                value={values.facerecognition}
                                onChange={(e) =>
                                  setFieldValue(
                                    "facerecognition",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                                width="100%"
                              />

                              {errors.facerecognition &&
                                touched.facerecognition && (
                                  <div className="error-message">
                                    {errors.facerecognition}
                                  </div>
                                )}
                            </div>
                          </div> */}

                          <div className="flex_input">
                            {userRole === "management" && (
                              <>
                                <div className="row_col custom-width-50">
                                  <Selector
                                    label="Shift"
                                    options={shifbytList}
                                    name="shift"
                                    value={
                                      values.shift[0]?._id || values.shift[0]
                                    }
                                    showStar={true}
                                    onChange={(e) =>
                                      setFieldValue("shift", [e.target.value])
                                    }
                                    LabelFontSize="16px"
                                    SelectorFontSize="16px"
                                    width="100%"
                                    // multi={true}
                                  />
                                  {errors.shift && (
                                    <div className="error-message">
                                      {errors.shift}
                                    </div>
                                  )}
                                </div>
                                <div className="row_col custom-width-50">
                                  <Selector
                                    label="Face Recognition*"
                                    options={facerecognitionoptions}
                                    showStar={true}
                                    name="facerecognition"
                                    value={values.facerecognition}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "facerecognition",
                                        e.target.value
                                      )
                                    }
                                    LabelFontSize="16px"
                                    SelectorFontSize="16"
                                    width="100%"
                                  />

                                  {errors.facerecognition &&
                                    touched.facerecognition && (
                                      <div className="error-message">
                                        {errors.facerecognition}
                                      </div>
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ))}
                  {activeTab === "Documents" && (
                    <div className="info_btm">
                      <p>Documents</p>{" "}
                    </div>
                  )}
                  {activeTab === "Documents" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="upload_input">
                            {documentItems.map((doc) => (
                              <div key={doc.name} className="document-item">
                                <label className="doc-title">{doc.label}</label>
                                <InputLogo
                                  name={doc.name}
                                  value={values[doc.name]?.name || ""}
                                  onChangeData={(file) =>
                                    setFieldValue(doc.name, file)
                                  }
                                  error={errors[doc.name]}
                                  touched={touched[doc.name]}
                                  accept=".jpeg, .jpg, .png, .pdf, .doc, .docx"
                                  docsData={doc}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      ))}
                  {activeTab === "Employee Identity" && (
                    <div className="info_btm">
                      <p>Employee Identity</p>
                    </div>
                  )}
                  {activeTab === "Employee Identity" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Aadhar Number"
                                inputType="tel"
                                inputPlaceholder="Enter Aadhar Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="aadharnumber"
                                value={values.aadharnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.aadharnumber && touched.aadharnumber && (
                                <div className="error-message">
                                  {errors.aadharnumber}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Name As Per Aadhar"
                                inputType="tel"
                                inputPlaceholder="Enter Name As Per Aadhar"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperaadhar"
                                value={values.nameasperaadhar}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.nameasperaadhar &&
                                touched.nameasperaadhar && (
                                  <div className="error-message">
                                    {errors.nameasperaadhar}
                                  </div>
                                )}
                            </div>
                            {/* <div className="row_col">
                              <InputField
                                label="Aadhaar Enrolment No"
                                inputType="tel"
                                inputPlaceholder="Enter Aadhaar Enrolment No"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="aadhaarenrolment"
                                value={values.aadhaarenrolment}
                                onChange={handleChange}
                                max2={28}
                              />
                              {errors.aadhaarenrolment &&
                                touched.aadhaarenrolment && (
                                  <div className="error-message">
                                    {errors.aadhaarenrolment}
                                  </div>
                                )}
                            </div> */}
                            <div className="row_col">
                              <InputField
                                label="Permanent Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Permanent Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="permanentaccountnumber"
                                value={values.permanentaccountnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.permanentaccountnumber &&
                                touched.permanentaccountnumber && (
                                  <div className="error-message">
                                    {errors.permanentaccountnumber}
                                  </div>
                                )}
                            </div>
                            <div className="row_col ">
                              <InputField
                                label="Name As Per PAN"
                                inputType="text"
                                inputPlaceholder="Enter Name As Per PAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperpan"
                                value={values.nameasperpan}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.nameasperpan && touched.nameasperpan && (
                                <div className="error-message">
                                  {errors.nameasperpan}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            {/* <div className="row_col custom-width-50 ">
                              <InputField
                                label="Name As Per PAN"
                                inputType="text"
                                inputPlaceholder="Enter Name As Per PAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameasperpan"
                                value={values.nameasperpan}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.nameasperpan && touched.nameasperpan && (
                                <div className="error-message">
                                  {errors.nameasperpan}
                                </div>
                              )}
                            </div> */}
                            <div className="row_col custom-width-50 ">
                              <Selector
                                label="Document Type"
                                options={documentType}
                                name="empolyeedocumenttype"
                                value={values.empolyeedocumenttype}
                                onChange={(e) =>
                                  setFieldValue(
                                    "empolyeedocumenttype",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.empolyeedocumenttype &&
                                touched.empolyeedocumenttype && (
                                  <div className="error-message">
                                    {errors.empolyeedocumenttype}
                                  </div>
                                )}
                            </div>
                            {/* <div className="row_col">
                              <Selector
                                label="Bank Name"
                                options={banknameoptions}
                                name="empolyeebankname"
                                value={values.empolyeebankname}
                                onChange={(e) =>
                                  setFieldValue(
                                    "empolyeebankname",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.empolyeebankname &&
                                touched.empolyeebankname && (
                                  <div className="error-message">
                                    {errors.empolyeebankname}
                                  </div>
                                )}
                            </div> */}
                            {/* <div className="row_col">
                              <InputField
                                label="Account Number"
                                inputType="text"
                                inputPlaceholder="Enter Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="accountnumber"
                                value={values.accountnumber}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.accountnumber &&
                                touched.accountnumber && (
                                  <div className="error-message">
                                    {errors.accountnumber}
                                  </div>
                                )}
                            </div> */}
                          </div>
                          <div className="flex_input">
                            {/* <div className="row_col custom-width-50 ">
                              <InputField
                                label="IFSC"
                                inputType="text"
                                inputPlaceholder="Enter IFSC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankifsc"
                                value={values.bankifsc}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.bankifsc && touched.bankifsc && (
                                <div className="error-message">
                                  {errors.bankifsc}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="Name In Bank Account"
                                inputType="text"
                                inputPlaceholder="Enter Name In Bank Account"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameinbankaccount"
                                value={values.nameinbankaccount}
                                onChange={handleChange}
                                disabled={true}
                              />
                              {errors.nameinbankaccount &&
                                touched.nameinbankaccount && (
                                  <div className="error-message">
                                    {errors.nameinbankaccount}
                                  </div>
                                )}
                            </div> */}
                          </div>
                        </>
                      ))}
                  {activeTab === "Education" && (
                    <div className="info_btm">
                      <p>Education</p>
                    </div>
                  )}{" "}
                  {activeTab === "Education" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Qualification"
                                options={qualificationoptions}
                                name="qualification"
                                value={values.qualification}
                                onChange={(e) =>
                                  setFieldValue("qualification", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.qualification &&
                                touched.qualification && (
                                  <div className="error-message">
                                    {errors.qualification}
                                  </div>
                                )}
                            </div>

                            <div className="row_col custom-width-50">
                              <InputField
                                label="Institute Name"
                                inputType="text"
                                inputPlaceholder="Enter Institute Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="institutename"
                                value={values.institutename}
                                onChange={handleChange}
                              />
                              {errors.institutename &&
                                touched.institutename && (
                                  <div className="error-message">
                                    {errors.institutename}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Grade"
                                inputType="text"
                                inputPlaceholder="Enter Grade"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="grade"
                                value={values.grade}
                                onChange={handleChange}
                              />
                              {errors.grade && touched.grade && (
                                <div className="error-message">
                                  {errors.grade}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "Address" && (
                    <div className="info_btm">
                      <p>Present Address</p>
                    </div>
                  )}{" "}
                  {activeTab === "Address" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress1"
                                value={values.presentaddress1}
                                onChange={handleChange}
                              />
                              {errors.presentaddress1 &&
                                touched.presentaddress1 && (
                                  <div className="error-message">
                                    {errors.presentaddress1}
                                  </div>
                                )}
                            </div>
                          </div>
                          {/* <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress2"
                                value={values.presentaddress2}
                                onChange={handleChange}
                              />
                              {errors.presentaddress2 &&
                                touched.presentaddress2 && (
                                  <div className="error-message">
                                    {errors.presentaddress2}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentaddress3"
                                value={values.presentaddress3}
                                onChange={handleChange}
                              />
                              {errors.presentaddress3 &&
                                touched.presentaddress3 && (
                                  <div className="error-message">
                                    {errors.presentaddress3}
                                  </div>
                                )}
                            </div>
                          </div> */}
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector2
                                label="Country"
                                options={presentcountryoptions}
                                name="presentcountry"
                                value={values.presentcountry}
                                onChange={(e) => {
                                  setFieldValue(
                                    "presentcountry",
                                    e.target.value
                                  );
                                  dispatch(
                                    getAllStateList(e.target.value?.value)
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.presentcountry &&
                                touched.presentcountry && (
                                  <div className="error-message">
                                    {errors.presentcountry}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Selector2
                                label="State"
                                options={presentstateoptions}
                                name="presentstate"
                                value={values.presentstate}
                                onChange={(e) => {
                                  setFieldValue(
                                    "presentstate",
                                    e.target.value?.value
                                  );
                                  dispatch(
                                    getAllCityList(e.target.value?.value)
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.presentstate && touched.presentstate && (
                                <div className="error-message">
                                  {errors.presentstate}
                                </div>
                              )}
                            </div>{" "}
                            <div className="row_col">
                              <Selector2
                                label="City"
                                options={presentcityoptions}
                                name="presentcity"
                                value={values.presentcity}
                                onChange={(e) =>
                                  setFieldValue("presentcity", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.presentcity && touched.presentcity && (
                                <div className="error-message">
                                  {errors.presentcity}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentpincode"
                                value={values.presentpincode}
                                onChange={handleChange}
                              />
                              {errors.presentpincode &&
                                touched.presentpincode && (
                                  <div className="error-message">
                                    {errors.presentpincode}
                                  </div>
                                )}
                            </div>
                          </div>
                          {/* <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="Phone1"
                                value={values.Phone1}
                                onChange={handleChange}
                              />
                              {errors.Phone1 && touched.Phone1 && (
                                <div className="error-message">
                                  {errors.Phone1}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="phone2"
                                value={values.phone2}
                                onChange={handleChange}
                              />
                              {errors.phone2 && touched.phone2 && (
                                <div className="error-message">
                                  {errors.phone2}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="text"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentext"
                                value={values.presentext}
                                onChange={handleChange}
                              />
                              {errors.presentext && touched.presentext && (
                                <div className="error-message">
                                  {errors.presentext}
                                </div>
                              )}
                            </div>
                            
                          </div> */}
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Fax"
                                inputType="text"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentfax"
                                value={values.presentfax}
                                onChange={handleChange}
                              />
                              {errors.presentfax && touched.presentfax && (
                                <div className="error-message">
                                  {errors.presentfax}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentmobile"
                                value={values.presentmobile}
                                onChange={handleChange}
                                max={10}
                              />
                              {errors.presentmobile &&
                                touched.presentmobile && (
                                  <div className="error-message">
                                    {errors.presentmobile}
                                  </div>
                                )}
                            </div>
                            {/* <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="text"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="presentemail"
                                value={values.presentemail}
                                onChange={handleChange}
                              />
                              {errors.presentemail && touched.presentemail && (
                                <div className="error-message">
                                  {errors.presentemail}
                                </div>
                              )}
                            </div> */}
                          </div>
                        </>
                      ))}
                  {activeTab === "Emergency Contact" && (
                    <div className="info_btm">
                      <p>Emergency Contact</p>
                    </div>
                  )}
                  {activeTab === "Emergency Contact" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Name"
                                inputType="text"
                                inputPlaceholder="Enter Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="nameemergency"
                                value={values.nameemergency}
                                onChange={handleChange}
                              />
                              {errors.nameemergency &&
                                touched.nameemergency && (
                                  <div className="error-message">
                                    {errors.nameemergency}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Relationship"
                                options={relationshipoptions}
                                name="relationship"
                                value={values.relationship}
                                onChange={(e) =>
                                  setFieldValue("relationship", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.relationship && touched.relationship && (
                                <div className="error-message">
                                  {errors.relationship}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 1"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeraddress1"
                                value={values.emeraddress1}
                                onChange={handleChange}
                              />
                              {errors.emeraddress1 && touched.emeraddress1 && (
                                <div className="error-message">
                                  {errors.emeraddress1}
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 2"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeraddress2"
                                value={values.emeraddress2}
                                onChange={handleChange}
                              />
                              {errors.emeraddress2 && touched.emeraddress2 && (
                                <div className="error-message">
                                  {errors.emeraddress2}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Address 3"
                                inputType="text"
                                inputPlaceholder="Enter Address"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeraddress3"
                                value={values.emeraddress3}
                                onChange={handleChange}
                              />
                              {errors.emeraddress3 && touched.emeraddress3 && (
                                <div className="error-message">
                                  {errors.emeraddress3}
                                </div>
                              )}
                            </div>
                          </div> */}
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector2
                                label="Country"
                                options={presentcountryoptions}
                                name="permanentcountry"
                                value={values.emercountry}
                                onChange={(e) => {
                                  setFieldValue("emercountry", e.target.value);
                                  dispatch(
                                    getAllStateListEmer(e.target.value?.value)
                                  );
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.emercountry && touched.emercountry && (
                                <div className="error-message">
                                  {errors.emercountry}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="State"
                                options={presentstateoptionsEmer}
                                name="emerstate"
                                value={values.emerstate}
                                onChange={(e) => {
                                  setFieldValue("emerstate", e.target.value);
                                  dispatch(getAllCityListEmer(e.target.value));
                                }}
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.emerstate && touched.emerstate && (
                                <div className="error-message">
                                  {errors.emerstate}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <Selector
                                label="City"
                                options={presentcityoptionsEmer}
                                name="emercity"
                                value={values.emercity}
                                onChange={(e) =>
                                  setFieldValue("emercity", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.emercity && touched.emercity && (
                                <div className="error-message">
                                  {errors.emercity}
                                </div>
                              )}
                            </div>

                            <div className="row_col">
                              <InputField
                                label=" Pincode"
                                inputType="tel"
                                inputPlaceholder="Enter Pincode"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerpincode"
                                value={values.emerpincode}
                                onChange={handleChange}
                              />
                              {errors.emerpincode && touched.emerpincode && (
                                <div className="error-message">
                                  {errors.emerpincode}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            {/* <div className="row_col">
                              <InputField
                                label=" Phone 1"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 1"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerphone1"
                                value={values.emerphone1}
                                onChange={handleChange}
                              />
                              {errors.emerphone1 && touched.emerphone1 && (
                                <div className="error-message">
                                  {errors.emerphone1}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Phone 2"
                                inputType="tel"
                                inputPlaceholder="Enter Phone 2"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerphone2"
                                value={values.emerphone2}
                                onChange={handleChange}
                              />
                              {errors.emerphone2 && touched.emerphone2 && (
                                <div className="error-message">
                                  {errors.emerphone2}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Ext"
                                inputType="tel"
                                inputPlaceholder="Enter Ext"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emertext"
                                value={values.emertext}
                                onChange={handleChange}
                              />
                              {errors.emertext && touched.emertext && (
                                <div className="error-message">
                                  {errors.emertext}
                                </div>
                              )}
                            </div> */}
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Fax"
                                inputType="tel"
                                inputPlaceholder=" Fax"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emerfax"
                                value={values.emerfax}
                                onChange={handleChange}
                              />
                              {errors.emerfax && touched.emerfax && (
                                <div className="error-message">
                                  {errors.emerfax}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emermobile"
                                value={values.emermobile}
                                onChange={handleChange}
                                max={10}
                              />
                              {errors.emermobile && touched.emermobile && (
                                <div className="error-message">
                                  {errors.emermobile}
                                </div>
                              )}
                            </div>
                            {/* <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="email"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeremail"
                                value={values.emeremail}
                                onChange={handleChange}
                              />
                              {errors.emeremail && touched.emeremail && (
                                <div className="error-message">
                                  {errors.emeremail}
                                </div>
                              )}
                            </div> */}
                          </div>
                          {/* <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Mobile"
                                inputType="tel"
                                inputPlaceholder="Enter Mobile"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emermobile"
                                value={values.emermobile}
                                onChange={handleChange}
                                max={10}
                              />
                              {errors.emermobile && touched.emermobile && (
                                <div className="error-message">
                                  {errors.emermobile}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Email"
                                inputType="email"
                                inputPlaceholder="Enter Email"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="emeremail"
                                value={values.emeremail}
                                onChange={handleChange}
                              />
                              {errors.emeremail && touched.emeremail && (
                                <div className="error-message">
                                  {errors.emeremail}
                                </div>
                              )}
                            </div>
                          </div> */}
                        </>
                      ))}
                  {activeTab === "Emergency Contact" && (
                    <div className="info_btm">
                      <p>Background Check</p>
                    </div>
                  )}
                  {activeTab === "Emergency Contact" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Verification Status"
                                options={vrificationstatusoptions}
                                name="vrificationstatus"
                                value={values.vrificationstatus}
                                onChange={(e) =>
                                  setFieldValue(
                                    "vrificationstatus",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.vrificationstatus &&
                                touched.vrificationstatus && (
                                  <div className="error-message">
                                    {errors.vrificationstatus}
                                  </div>
                                )}
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Verification Completed On"
                                inputType="date"
                                inputPlaceholder="Enter Verification Completed On"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="verificationcompleted"
                                value={values.verificationcompleted}
                                onChange={handleChange}
                              />
                              {errors.verificationcompleted &&
                                touched.verificationcompleted && (
                                  <div className="error-message">
                                    {errors.verificationcompleted}
                                  </div>
                                )}
                            </div>
                            {/* <div className="row_col custom-width-50">
                              <InputField
                                label="Agency Name"
                                inputType="text"
                                inputPlaceholder="Enter Agency Name"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="agency_name"
                                value={values.agency_name}
                                onChange={handleChange}
                              />
                              {errors.agency_name && touched.agency_name && (
                                <div className="error-message">
                                  {errors.agency_name}
                                </div>
                              )}
                            </div> */}
                          </div>
                        </>
                      ))}
                  {activeTab === "Bank Account" && (
                    <div className="info_btm">
                      <p>Bank Account</p>
                    </div>
                  )}
                  {activeTab === "Bank Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <Selector
                                label="Bank Name"
                                options={banknameoptions}
                                name="bankname"
                                value={values.bankname}
                                onChange={(e) =>
                                  setFieldValue("bankname", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.bankname && touched.bankname && (
                                <div className="error-message">
                                  {errors.bankname}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Branch"
                                inputType="text"
                                inputPlaceholder="Enter Title"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankbranch"
                                value={values.bankbranch}
                                onChange={handleChange}
                                // disabled={true}
                              />
                              {errors.bankbranch && touched.bankbranch && (
                                <div className="error-message">
                                  {errors.bankbranch}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="Bank Account Number"
                                inputType="tel"
                                inputPlaceholder="Enter Bank Account Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bankaccountnumber"
                                value={values?.bankaccountnumber}
                                onChange={handleChange}
                              />
                              {errors.bankaccountnumber &&
                                touched.bankaccountnumber && (
                                  <div className="error-message">
                                    {errors.bankaccountnumber}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="IFSC"
                                inputType="text"
                                inputPlaceholder="Enter IFSC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="iFSC"
                                value={values.iFSC}
                                onChange={handleChange}
                              />
                              {errors.iFSC && touched.iFSC && (
                                <div className="error-message">
                                  {errors.iFSC}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col custom-width-50 ">
                              <InputField
                                label="IBAN/ Swift Code / BIC"
                                inputType="text"
                                inputPlaceholder="Enter IBAN/ Swift Code / BIC"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="iban"
                                value={values.iban}
                                onChange={handleChange}
                              />
                              {errors.iban && touched.iban && (
                                <div className="error-message ">
                                  {errors.iban}
                                </div>
                              )}
                            </div>
                            <div className="row_col custom-width-50">
                              <Selector
                                label="Account Type"
                                options={accounttypeoptions}
                                name="accounttype"
                                value={values.accounttype}
                                onChange={(e) =>
                                  setFieldValue("accounttype", e.target.value)
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.accounttype && touched.accounttype && (
                                <div className="error-message">
                                  {errors.accounttype}
                                </div>
                              )}
                            </div>
                            {/* <div className="row_col">
                              <Selector
                                label="Payment Type"
                                options={paymenttypeoptions}
                                name="paymenttype"
                                value={values.paymenttype}
                                onChange={(e) =>
                                  setFieldValue(
                                    "paymenttype",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.paymenttype && touched.paymenttype && (
                                <div className="error-message">
                                  {errors.paymenttype}
                                </div>
                              )}
                            </div> */}
                            {/* <div className="row_col">
                              <InputField
                                label="DD Payable At"
                                inputType="text"
                                inputPlaceholder="Enter DD Payable At"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="dd_Payable"
                                value={values.dd_Payable}
                                onChange={handleChange}
                              />
                              {errors.dd_Payable && touched.dd_Payable && (
                                <div className="error-message">
                                  {errors.dd_Payable}
                                </div>
                              )}
                            </div> */}
                            <div className="row_col custom-width-50">
                              <InputField
                                label="Name as Per Bank Records"
                                inputType="text"
                                inputPlaceholder="Enter Name as Per Bank Records"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="bank_records"
                                value={values.bank_records}
                                onChange={handleChange}
                              />
                              {errors.bank_records && touched.bank_records && (
                                <div className="error-message">
                                  {errors.bank_records}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input"></div>
                        </>
                      ))}
                  {activeTab === "ESI Account" && (
                    <div className="info_btm">
                      <p>ESI Account</p>
                    </div>
                  )}
                  {activeTab === "ESI Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input ">
                            <div className="row_col custom-width-50">
                              <Checkbox
                                id="underESI"
                                label="Employee Is Covered Under PF"
                                checked={checkedItems?.underESI}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col custom-width-50">
                              <InputField
                                label="ESI Number"
                                inputType="tel"
                                inputPlaceholder="Enter ESI Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="esinumber"
                                value={values.esinumber}
                                onChange={handleChange}
                                max2={17}
                              />
                              {errors.esinumber && touched.esinumber && (
                                <div className="error-message">
                                  {errors.esinumber}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  {activeTab === "PF Account" && (
                    <div className="info_btm">
                      <p>PF Account</p>
                    </div>
                  )}
                  {activeTab === "PF Account" &&
                    tabs
                      .filter((tab) => tab.name === activeTab)
                      .map((field) => (
                        <>
                          <div className="flex_input">
                            <div className="row_col">
                              <Checkbox
                                id="underpf"
                                label="Employee Is Covered Under PF"
                                checked={checkedItems?.underpf}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <InputField
                                label="UAN"
                                inputType="tel"
                                inputPlaceholder="Enter UAN"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="uan"
                                value={values.uan}
                                onChange={handleChange}
                                max2={12}
                              />
                              {errors.uan && touched.uan && (
                                <div className="error-message">
                                  {errors.uan}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="PF Number"
                                inputType="tel"
                                inputPlaceholder="Enter PF Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="pf_num"
                                value={values?.pf_num}
                                onChange={handleChange}
                                max2={26}
                              />
                              {errors.pf_num && touched.pf_num && (
                                <div className="error-message">
                                  {errors.pf_num}
                                </div>
                              )}
                            </div>
                            <div className="row_col">
                              <InputField
                                label="PF Join Date"
                                inputType="date"
                                inputPlaceholder="Enter PF Join Date"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="pf_join"
                                value={values.pf_join}
                                onChange={handleChange}
                              />
                              {errors.pf_join && touched.pf_join && (
                                <div className="error-message">
                                  {errors.pf_join}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex_input">
                            <div className="row_col">
                              <InputField
                                label="Family PF Number"
                                inputType="tel"
                                inputPlaceholder="Enter PF Number"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="family_pf_number"
                                value={values.family_pf_number}
                                onChange={handleChange}
                                max2={26}
                              />
                              {errors.family_pf_number &&
                                touched.family_pf_number && (
                                  <div className="error-message">
                                    {errors.family_pf_number}
                                  </div>
                                )}
                            </div>
                            <div className="row_col">
                              <Checkbox
                                id="memberofeps"
                                label="Is Existing Member Of EPS"
                                checked={checkedItems?.memberofeps}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            <div className="row_col">
                              <Checkbox
                                id="allowEpf"
                                label="Allow EPF Excess Contribution"
                                checked={checkedItems?.allowEpf}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                            {/* <div className="row_col">
                              <Selector
                                label="Document Type"
                                options={documenttypeoptions}
                                name="documenttype"
                                value={values.documenttype}
                                onChange={(e) =>
                                  setFieldValue(
                                    "documenttype",
                                    e.target.value
                                  )
                                }
                                LabelFontSize="16px"
                                SelectorFontSize="16"
                              />
                              {errors.documenttype && touched.documenttype && (
                                <div className="error-message">
                                  {errors.documenttype}
                                </div>
                              )}
                            </div> */}
                          </div>
                          <div className="flex_input mt-3">
                            <div className="row_col row_col2">
                              <Checkbox
                                id="userActive"
                                label="Employee Active"
                                checked={checkedItems?.userActive}
                                onChange={handleCheckboxChange}
                              />
                            </div>

                            <div
                              className="row_col row_col2"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                label="Password"
                                inputType={togglePassword ? "text" : "password"}
                                inputPlaceholder="Enter password"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                              />
                              {errors.password && touched.password && (
                                <div className="error-message">
                                  {errors.password}
                                </div>
                              )}

                              <span
                                className={styles.focus_eye2}
                                onClick={toggleVisibility}
                              >
                                <img
                                  src={togglePassword ? eye_off : eye}
                                  alt="Toggle visibility"
                                />
                              </span>
                            </div>
                            <div
                              className="row_col "
                              style={{ position: "relative" }}
                            >
                              <InputField
                                label="ConfirmPassword"
                                inputType={
                                  togglePassword2 ? "text" : "password"
                                }
                                inputPlaceholder="Enter Confirm Password"
                                InputFontSize="16px"
                                LabelFontSize="16px"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                              />
                              {errors.confirmPassword &&
                                touched.confirmPassword && (
                                  <div className="error-message">
                                    {errors.confirmPassword}
                                  </div>
                                )}
                              <span
                                className={styles.focus_eye2}
                                onClick={toggleVisibility2}
                              >
                                <img
                                  src={togglePassword2 ? eye_off : eye}
                                  alt="Toggle visibility"
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                  <div className="flex_btn_next">
                    {activeTab !== tabs[0].name && (
                      <div className="btn_pre">
                        <button
                          type="button"
                          onClick={() =>
                            setActiveTab(
                              tabs[
                                tabs.findIndex(
                                  (tab) => tab.name === activeTab
                                ) - 1
                              ].name
                            )
                          }
                        >
                          Previous
                        </button>
                      </div>
                    )}

                    {/* {activeTab !== tabs[tabs.length - 1].name && (
                      <div className="btn_left">
                        <button
                          type="button"
                          onClick={() =>
                            handleNext(values, errors, touched, setFieldTouched)
                          }
                        >
                          Next
                        </button>
                      </div>
                    )} */}

                    {activeTab !== tabs[tabs.length - 1].name && (
                      <div className="btn_left">
                        <button
                          type="button"
                          onClick={() =>
                            handleNext(
                              values,
                              touched,
                              setFieldTouched,
                              activeTab
                            )
                          }
                        >
                          Next
                        </button>
                      </div>
                    )}

                    {activeTab === "PF Account" && (
                      <div className="btn_save">
                        <button
                          type="submit"
                          className=""
                          disabled={isSubmitting}
                        >
                          {loader ? (
                            <>
                              <div className="spinner-border2" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditUser;
