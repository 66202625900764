// import axios from "axios";

// const Axios = axios.create({
//   baseURL: process.env.REACT_APP_SECRET_KEY,

//   // withCredentials: true,
// });

// Axios.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("accessToken");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// export default Axios;

import axios from "axios";

// Create an Axios instance
const Axios = axios.create({
  baseURL: process.env.REACT_APP_SECRET_KEY,
  // withCredentials: true, // Uncomment if cross-origin cookies are needed
});

// Request interceptor
Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
Axios.interceptors.response.use(
  (response) => response, // Return the response as is for successful calls
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      // Handle token expiration
      if (status === 401 && data.login === false) {
        // Clear session and redirect to login
        localStorage.removeItem("accessToken");
        window.location.href = "/#/login"; // Adjust the login path if needed
      }

      // Handle unauthorized access
      // if (status === 403 && data.message === "Unauthorized") {
      //   alert("You are not authorized. Please log in again.");
      //   window.location.href = "/login"; // Adjust the login path if needed
      // }
    }

    // Reject the error to let the application handle it further if needed
    return Promise.reject(error);
  }
);

export default Axios;
