import React from "react";

const DocumentSvg = ({ items }) => {
  const clickHandler = (data) => {
    window.open(data.image, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => clickHandler(items)}
      >
        <path
          d="M1.37859 25.5294H17.7794C18.5334 25.5294 19.1471 24.9158 19.1471 24.1618V6.83824C19.1471 6.81727 19.1379 6.79903 19.1352 6.77897C19.1325 6.75891 19.1288 6.74159 19.1234 6.72244C19.1024 6.64403 19.0686 6.57109 19.0121 6.51547L12.6307 0.134029C12.5751 0.0784118 12.5021 0.0446765 12.4237 0.0227941C12.4046 0.0173235 12.3872 0.0136765 12.3672 0.0109412C12.348 0.00911765 12.3298 0 12.3088 0H1.37312C0.616353 0 0 0.613618 0 1.36765V24.1618C0 24.9158 0.618176 25.5294 1.37859 25.5294ZM12.7647 1.55638L17.5907 6.38235H13.2206C12.9699 6.38235 12.7647 6.17812 12.7647 5.92647V1.55638ZM0.911765 1.36765C0.911765 1.116 1.11874 0.911765 1.37312 0.911765H11.8529V5.92647C11.8529 6.6805 12.4666 7.29412 13.2206 7.29412H18.2353V24.1618C18.2353 24.4134 18.0301 24.6176 17.7794 24.6176H1.37859C1.12056 24.6176 0.911765 24.4134 0.911765 24.1618V1.36765Z"
          fill="#828282"
        />
        <path
          d="M4.10432 10.9412H15.0455C15.2971 10.9412 15.5014 10.7369 15.5014 10.4853C15.5014 10.2337 15.2971 10.0294 15.0455 10.0294H4.10432C3.85267 10.0294 3.64844 10.2337 3.64844 10.4853C3.64844 10.7369 3.85267 10.9412 4.10432 10.9412Z"
          fill="#828282"
        />
        <path
          d="M4.10432 14.5883H15.0455C15.2971 14.5883 15.5014 14.384 15.5014 14.1324C15.5014 13.8807 15.2971 13.6765 15.0455 13.6765H4.10432C3.85267 13.6765 3.64844 13.8807 3.64844 14.1324C3.64844 14.384 3.85267 14.5883 4.10432 14.5883Z"
          fill="#828282"
        />
        <path
          d="M4.10432 7.29409H8.66314C8.91479 7.29409 9.11903 7.08985 9.11903 6.83821C9.11903 6.58656 8.91479 6.38232 8.66314 6.38232H4.10432C3.85267 6.38232 3.64844 6.58656 3.64844 6.83821C3.64844 7.08985 3.85267 7.29409 4.10432 7.29409Z"
          fill="#828282"
        />
        <path
          d="M4.10432 18.2353H15.0455C15.2971 18.2353 15.5014 18.031 15.5014 17.7794C15.5014 17.5277 15.2971 17.3235 15.0455 17.3235H4.10432C3.85267 17.3235 3.64844 17.5277 3.64844 17.7794C3.64844 18.031 3.85267 18.2353 4.10432 18.2353Z"
          fill="#828282"
        />
        <path
          d="M4.10432 21.8823H15.0455C15.2971 21.8823 15.5014 21.6781 15.5014 21.4265C15.5014 21.1748 15.2971 20.9706 15.0455 20.9706H4.10432C3.85267 20.9706 3.64844 21.1748 3.64844 21.4265C3.64844 21.6781 3.85267 21.8823 4.10432 21.8823Z"
          fill="#828282"
        />
        <path
          d="M19.6016 4.55886H23.7045V9.57357C23.7045 10.3276 24.3181 10.9412 25.0722 10.9412H30.0869V27.8089C30.0869 28.0605 29.8817 28.2647 29.631 28.2647H13.2302C12.9721 28.2647 12.7633 28.0605 12.7633 27.8089V26.8971C12.7633 26.6454 12.5591 26.4412 12.3074 26.4412C12.0558 26.4412 11.8516 26.6454 11.8516 26.8971V27.8089C11.8516 28.5629 12.4697 29.1765 13.2302 29.1765H29.631C30.385 29.1765 30.9986 28.5629 30.9986 27.8089V10.4853C30.9986 10.4644 30.9895 10.4461 30.9868 10.4261C30.984 10.406 30.9804 10.3887 30.9749 10.3695C30.9539 10.2911 30.9202 10.2182 30.8637 10.1626L24.4822 3.78112C24.4266 3.72459 24.3528 3.69086 24.2744 3.66989C24.2552 3.66442 24.2388 3.66168 24.2187 3.65895C24.1996 3.65621 24.1814 3.64709 24.1604 3.64709H19.6016C19.3499 3.64709 19.1457 3.85133 19.1457 4.10298C19.1457 4.35462 19.3499 4.55886 19.6016 4.55886ZM25.0722 10.0294C24.8214 10.0294 24.6163 9.82521 24.6163 9.57357V5.20348L29.4422 10.0294H25.0722Z"
          fill="#828282"
        />
        <path
          d="M21.4246 14.5883H26.8952C27.1469 14.5883 27.3511 14.384 27.3511 14.1324C27.3511 13.8807 27.1469 13.6765 26.8952 13.6765H21.4246C21.173 13.6765 20.9688 13.8807 20.9688 14.1324C20.9688 14.384 21.173 14.5883 21.4246 14.5883Z"
          fill="#828282"
        />
        <path
          d="M21.4246 18.2353H26.8952C27.1469 18.2353 27.3511 18.031 27.3511 17.7794C27.3511 17.5277 27.1469 17.3235 26.8952 17.3235H21.4246C21.173 17.3235 20.9688 17.5277 20.9688 17.7794C20.9688 18.031 21.173 18.2353 21.4246 18.2353Z"
          fill="#828282"
        />
        <path
          d="M21.4246 21.8823H26.8952C27.1469 21.8823 27.3511 21.6781 27.3511 21.4265C27.3511 21.1748 27.1469 20.9706 26.8952 20.9706H21.4246C21.173 20.9706 20.9688 21.1748 20.9688 21.4265C20.9688 21.6781 21.173 21.8823 21.4246 21.8823Z"
          fill="#828282"
        />
        <path
          d="M21.4246 25.5294H26.8952C27.1469 25.5294 27.3511 25.3252 27.3511 25.0736C27.3511 24.8219 27.1469 24.6177 26.8952 24.6177H21.4246C21.173 24.6177 20.9688 24.8219 20.9688 25.0736C20.9688 25.3252 21.173 25.5294 21.4246 25.5294Z"
          fill="#828282"
        />
      </svg>
    </>
  );
};

export default DocumentSvg;
