import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Sidebar from "../components/Sidebar/Sidebar";
import ResponsiveSidebar from "../components/Sidebar/ResponsiveSidebar";

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth <= 999 ? false : true);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 999);

  const toggleSidebar = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Update isMobile based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 999);
      localStorage.setItem("view", window.innerWidth <= 999);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="layout-wrapper layout-content-navbar">
      {!isMobile && (
        <motion.div
          animate={{
            width: isMenuOpen ? "20%" : "0%",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 20,
            },
          }}
          className="sidebar"
          style={{
            borderRight: "1px solid rgba(44, 53, 125, 0.5)",
            boxShadow: "1px 0px 4px rgba(44, 53, 125, 0.2)",
            padding: "10px 0px",
            overflowY: "scroll",
            // height: "100vh",
          }}
        >
          <Sidebar isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </motion.div>
      )}

      {isMobile && (
        <motion.div
          animate={{
            width: isMenuOpen ? "100%" : "0%",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 20,
            },
          }}
          className="sidebar"
          style={{
            position: "absolute", // Position above content
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            zIndex: 100, // Ensure it appears above other elements
            backgroundColor: "white", // Background color to cover content
            borderBottom: "1px solid rgba(44, 53, 125, 0.5)", // Adjust border to bottom
            boxShadow: "0px 1px 4px rgba(44, 53, 125, 0.2)", // Adjust shadow direction
            padding: "10px 0px",
            overflowY: "scroll",
          }}
        >
          <Sidebar
            isOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            isMobile={isMobile}
            funtype={toggleSidebar}
          />
        </motion.div>
      )}
      {/* {isMobile && (
        <ResponsiveSidebar
          setShowNavbar={setShowNavbar}
          showNavbar={showNavbar}
          isOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      )} */}
      <div className="layout-container">
        <div className="layout-page">
          <Navbar funtype={toggleSidebar} />
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
