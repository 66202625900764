import React, { useEffect, useState } from "react";
import "./tableList.css";
import RightCustomModal from "../../Common/RightModal";
import AddCompany from "../AddNewCompany/AddCompany";
import pink_logo from "../../assets/img/pink_logo.png";
import black_logo from "../../assets/img/black_logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  companydeleteData,
  getAllCountryList,
  getSettingComapnay,
} from "../../redux/Action";
import Delete from "../../Common/Delete/Delete";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";
import { truncate } from "lodash";
import Pagination from "../../Common/Pagination/Pagination";

const Company = () => {
  const dispatch = useDispatch();
  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [updatedData, setUpdatedData] = useState({});
  const [status, setstatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    allCompanylist,
    deleteCompanylist,
    allCountryList,
    getAllStateData,
    getAllCityData,
    addCompanyUser,
    editCompanyUser,
  } = useSelector((state) => state.main);

  const pageLoading = useSelector((state) => state.main.loading);

  console.log(editCompanyUser, "jjj");

  useEffect(() => {
    dispatch(getSettingComapnay(currentPage));
  }, [currentPage, addCompanyUser, deleteCompanylist, editCompanyUser]);

  const handleClose = () => {
    setOpenInviteMember(false);
    setUpdatedData({});
  };

  const onCloseModal = () => setOpen(false);

  const onOpenModal = (data) => {
    setSelectedUserId(data);
    setOpen(true);
  };
  const handleDelete = () => {
    dispatch(companydeleteData(selectedUserId?.id));
    onCloseModal();
  };

  const OnEditClickHandler = (data, key) => {
    setSelectedUserId(data?.id);
    setOpenInviteMember(true);
    setUpdatedData(data);
    setstatus(key);
  };
  const fields = [
    { label: "Company Name", name: "company_name" },
    { label: "Company Address", name: "address" },
    // { label: "Country", name: "countryName" },
    // { label: "State", name: "stateName" },
    // { label: "Time Zone", name: "timezone" },
    // { label: "Currency", name: "currency" },
    { label: "PF No.", name: "pf_no" },
    { label: "TAN No.", name: "tan_no" },
    { label: "PAN No.", name: "pan_no" },
    { label: "ESI No.", name: "esi_no" },
    { label: "LIN No.", name: "lin_no" },
    { label: "GST No.", name: "gst_no" },
    {
      label: "Registration Certification No.",
      name: "registration_certification_no",
    },
    // { label: "Company Logo", name: "company_logo" },
    { label: "Status", name: "status" },
    // { label: "Signature", name: "signature" }, 
  ];

  const openAddCompany = () => {
    setOpenInviteMember(true);
    setstatus("add");
  };

  console.log(currentPage, "currentPage");

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 pt-4">
          <div className="text_btn">
            <div className="company_name">
              <h1>Company</h1>
            </div>
            <div className="btn_add">
              <button onClick={() => openAddCompany()}>Add Company</button>
            </div>
          </div>

          <div className="comp-table">
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {fields.map((field) => (
                        // <th
                        //   key={field.id}
                        //   style={
                        //     field.id === "companyName"
                        //       ? { borderLeft: "none" }
                        //       : { borderLeft: "none" }
                        //   }
                        // >
                        <th
                          key={field.id}
                          style={{
                            borderLeft: "none",
                          }}
                        >
                          {field.label}
                        </th>
                      ))}
                      <th style={{ borderRight: "none" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCompanylist?.data?.map((company, index) => (
                      <tr key={index}>
                        {fields.map((field) => (
                          <td key={field.name}>
                            {field.name === "company_logo" ? (
                              <img
                                src={company[field.name]}
                                alt={field.label}
                                className="company-logo"
                              />
                            ) : field.name === "status" ? (
                              <span
                                style={{
                                  backgroundColor:
                                    company[field.name] === true
                                      ? "#a2eddb40"
                                      : company[field.name] === false
                                      ? "#ff323240"
                                      : "transparent",
                                  color:
                                    company[field.name] === true
                                      ? "#24e502"
                                      : company[field.name] === false
                                      ? "#ff3232"
                                      : "inherit",
                                  padding: "5px 15px",
                                  borderRadius: "8px",
                                  fontWeight: "300",
                                  display: "inline-block",
                                }}
                              >
                                {company[field.name] === true
                                  ? "Active"
                                  : company[field.name] === false
                                  ? "Inactive"
                                  : "-"}
                              </span>
                            ) : (
                              company[field.name]
                            )}
                          </td>
                        ))}
                        <td>
                          <span className="d-flex align-item-center justify-content-center gap-3">
                            <button
                              onClick={() =>
                                OnEditClickHandler(company, "edit")
                              }
                            >
                              Edit
                            </button>
                            {/* <button onClick={() => onOpenModal(company)}>
            Delete
          </button> */}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <Pagination
        listData={allCompanylist?.meta}
        setPageData={setCurrentPage}
      />
      {openInviteMember && (
        <RightCustomModal
          isOpen={openInviteMember}
          onClose={handleClose}
          shouldCloseOnOutsideClick={true}
        >
          <AddCompany
            onClose={handleClose}
            updatedData={updatedData}
            status={status}
          />
        </RightCustomModal>
      )}
      <>
        {open && (
          <Delete open={open} onClose={onCloseModal} onConfirm={handleDelete} />
        )}
      </>
      {console.log(allCompanylist?.meta, "allCompanylist")}
    </>
  );
};

export default Company;
